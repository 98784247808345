import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  CardActions,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  Popover,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore } from '../../../Interface/app-store.interface';
import { passwordChangeForm } from '../../../Utils/forms/login-form';
import { fetchPasswordRecovery, fetchPasswordChange } from '../../../Redux/actions/auth.actions';
import i18n from '../../../Utils/i18n';

import HelpIcon from '@material-ui/icons/Help';

import '../../../assets/styles/objects/inputs.styles.scss';
import '../../../assets/styles/objects/container.styles.scss';
import { Alert } from "@material-ui/lab";

const DEFAULT_LOCK_TIME = 30;

interface PasswordChangePageState {
  passwordChangeForm: {[key: string]: any};
  submitting: boolean;
  lockTime: number;
  anchorEl: HTMLElement | null
}

class PasswordChangePage extends Component<any, PasswordChangePageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      passwordChangeForm: {...passwordChangeForm(i18n.PT)},
      submitting: false,
      lockTime: DEFAULT_LOCK_TIME,
      anchorEl: null
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  componentDidMount = () => {
    this._countdown();
  }

  _handleChange = (element: string, value: any) => {
    this.setState((getState: PasswordChangePageState) => ({
      passwordChangeForm: {
        ...getState.passwordChangeForm,
        formError: '',
        [element]: { ...getState.passwordChangeForm[element], value }
      }
    }));
  }

  _handleSubmit = (event: any) => {
    event.preventDefault();
    this.setState({ submitting: true });
    const data = {
      email: this.props.location.state.email,
      type: 'cms',
      password1: this.state.passwordChangeForm.Password.value,
      password2: this.state.passwordChangeForm.PasswordConfirmation.value,
      code: this.state.passwordChangeForm.Code.value
    };
    this.props.onFetchPasswordChange(data, 'user')
      .catch((error) => {
        let msg = '';
        if (error.status >= 400) {
          msg = this.props.appText[error.msg];
        }
        this.setState((state) => ({
            passwordChangeForm: {
                ...state.passwordChangeForm,
                formError: msg,
            },
            submitting: false
        }))
      });
  }

  _countdown = () => {
    const { lockTime } = this.state;
    if (lockTime >= -1) {
      setTimeout(() => {
        this.setState({ lockTime: lockTime - 1 }, this._countdown)
      }, 1000);
    }
  }

  _resendCode = () => {
    this.props.onFetchPasswordRecovery({ email: this.props.location.state.email, type: 'cms' }).then(() => {
      this.setState({ lockTime: DEFAULT_LOCK_TIME });
      setTimeout(this._countdown, 1);
    });
  }

  _renderResendLink = () => {
    const { lockTime } = this.state;
    return (lockTime > 0)
      ? `Enviar novamente em ${lockTime} segundo(s).`
      : <Link onClick={this._resendCode}>Reenviar código?</Link>
  }

  handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>) {
      this.setState({
          anchorEl: event.currentTarget
      })
  };

  handlePopoverClose() {
      this.setState({
          anchorEl: null
      })
  };

  render() {
    const { submitting, passwordChangeForm } = this.state;

    return (
      <div className={'login-page'}>
      <Grid container justify={'center'} alignItems={'center'} className={'full-height'}>
        <Grid item xs={12} sm={3} className={'container'}>
          <Card variant="outlined" className={'login-card'}>
            <CardContent>
              <Typography variant="h5" component="h2" style={{color: '#FFF'}}>
                Altere sua senha
              </Typography>
              <Typography component="p" color="textSecondary" style={{color: '#FFF', marginBottom: 16}}>
                Use o código de autenticação enviado por SMS. {this._renderResendLink()}
              </Typography>
              {passwordChangeForm.formError !== '' ? <Alert severity="error">{passwordChangeForm.formError}</Alert> : null}
              <form noValidate autoComplete="off">
                <TextField
                  id={passwordChangeForm.Password.name}
                  label={passwordChangeForm.Password.label}
                  required={passwordChangeForm.Password.required}
                  type={passwordChangeForm.Password.type}
                  placeholder={passwordChangeForm.Password.placeholder}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                      endAdornment: 
                          <InputAdornment position="end">
                              <IconButton
                                  aria-owns={'mouse-over-popover'}
                                  aria-label="dicas para senha"
                                  edge="end"
                                  onClick={this.handlePopoverOpen}
                              >
                                  <HelpIcon fontSize="small"/>
                              </IconButton>
                              <Popover
                                  id="mouse-over-popover"
                                  open={this.state.anchorEl !== null}
                                  anchorEl={this.state.anchorEl}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                  }}
                                  onClose={this.handlePopoverClose}
                                  disableRestoreFocus
                                  style={{
                                    padding: 8
                                  }}
                              >
                                  <p>- Deve ser compostos por no minimo <strong>8 caracteres</strong></p>
                                  <p>- Deve ter ao menos um <strong>caractere especial</strong></p>
                                  <p>- Deve ser <strong>alfanumerico</strong></p>
                                  <p>- Deve ter ao um caracter <strong>maiusculo</strong></p>
                                  <p>- Deve ter ao um caracter <strong>minusculo</strong></p>
                              </Popover>
                          </InputAdornment>
                  }}
                  className={'hfm-input'}
                  onChange={event => this._handleChange('Password', event.target.value)}
                />
                <TextField
                  id={passwordChangeForm.PasswordConfirmation.name}
                  label={passwordChangeForm.PasswordConfirmation.label}
                  required={passwordChangeForm.PasswordConfirmation.required}
                  type={passwordChangeForm.PasswordConfirmation.type}
                  placeholder={passwordChangeForm.PasswordConfirmation.placeholder}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={'hfm-input'}
                  onChange={event => this._handleChange('PasswordConfirmation', event.target.value)}
                />
                <TextField
                  id={passwordChangeForm.Code.name}
                  label={passwordChangeForm.Code.label}
                  required={passwordChangeForm.Code.required}
                  type={passwordChangeForm.Code.type}
                  placeholder={passwordChangeForm.Code.placeholder}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={'hfm-input'}
                  onChange={event => this._handleChange('Code', event.target.value)}
                />
              </form>
            </CardContent>
            <CardActions className={'margin-8'}>
              <Grid container justify={'center'} alignItems={'center'}>
                <Grid item>
                  <Button disabled={submitting} size="large" onClick={this._handleSubmit} variant="contained" color="primary">
                    { submitting ? i18n.PT.LABEL_UPDATING : i18n.PT.LABEL_UPDATE }
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppStore) => {
    const { auth, root } = state;
    const appText = root.appText;
    return { auth, appText };
};

const mapDispatchToProps = dispatch => ({
  onFetchPasswordRecovery: data => dispatch(fetchPasswordRecovery(data, 'user')),
  onFetchPasswordChange: data => dispatch(fetchPasswordChange(data, 'user')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordChangePage));
