import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";


export default class ProductService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getProducts(data?: any, type: 'CMS' | 'SITE' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].PRODUCT, data).then((responseData) => {
            return responseData.data;
        })
    }
    getProduct(id: number, type: 'CMS' | 'SITE' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].PRODUCT+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }

    setProduct(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';
        let formData = new FormData();
        for (var key in data) {
            if(key === 'image') {
                formData.append(key, data[key]);
            } else {
                formData.set(key, data[key]);
            }
        }
        return this._apiService[method](Configs.ENDPOINTS.PRODUCT + (editing ? `/${data.id}` : ''), formData, true).then((responseData) => {
            return responseData;
        })
    }

    removeProduct(productIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.PRODUCT, {ids: productIdArray}).then((responseData) => {
            return responseData;
        })
    }
}