
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

export default class SponsorService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getSponsors(data?: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].SPONSORS, data).then((responseData) => {
            return responseData.data;
        });
    }
    
    getSponsor(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.SPONSORS+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }

    setSponsor(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';
        let formData = new FormData();
        for (var key in data) {
            if(key === 'image') {
                formData.append(key, data[key]);
            } else {
                formData.set(key, data[key]);
            }
        }
        return this._apiService[method](Configs.ENDPOINTS.SPONSORS + (editing ? `/${data.id}` : ''), formData, true).then((responseData) => {
            return responseData;
        })
    }
    removeSponsor(sponsorIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.SPONSORS, {"ids": sponsorIdArray}).then((responseData) => {
            return responseData;
        })
    }
}