import React from 'react';
import {
    withRouter
} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowDown from '../../../../../assets/images/arrowDown.png';

import Networking from '../../../../../assets/images/platform/network.jpg';
import NetworkingMobile from '../../../../../assets/images/platform/network-mobile.jpg';

import './network.component.scss';
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import NetworkService from "../../../../../Services/network/network.service";
import { showToast } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";

interface NetworkPageProps {
    match: any;
    history: any;
    location: any;
    appText: any;
    onShowToast: any;
}
interface NetworkPageState {
    search: string;
    people: any[];
    count: number;
    offset: number;
    searching: boolean;
    eventName: string;
    orientationAngle: 'portrait' | 'landscape';
}

class NetworkPage extends React.Component<NetworkPageProps, NetworkPageState> {
    networkService: NetworkService;
    _lectures: any[];
    orientationChangeListener;

    searchTimeout: any;
    constructor(props) {
        super(props);
        this.networkService = new NetworkService();
        this._lectures = [];
        this.state = {
            search: '',
            searching: false,
            people: [],
            count: 0,
            offset: 0,
            orientationAngle: window.innerHeight/window.innerWidth > 1 ? 'portrait' : 'landscape',
            eventName: this.props.location.state && this.props.location.state.eventName ? this.props.location.state.eventName : ''
        }
        this.changeSearch = this.changeSearch.bind(this);
        this.fetchNetwork = this.fetchNetwork.bind(this);
    }

    componentDidMount() {
        this.fetchNetwork(true);
        this.orientationChangeListener = window.addEventListener("resize", (event: any) => {
            if(event !== null) {
                this.setState({
                    orientationAngle: event.target.innerHeight/event.target.innerWidth > 1 ? 'portrait' : 'landscape'
                })
            }
        });
    }

    fetchNetwork(resetList?) {
        const data = {
            offset: this.state.offset,
            size: 30,
            search: this.state.search
        }
        this.networkService.getNetwork(this.props.match.params.slug.split('-')[0], data).then((responseData) => {
            this.setState((state) => {
                return {
                    people: resetList ? responseData.rows : state.people.concat(responseData.rows),
                    offset: this.state.offset + 30,
                    count: responseData.count,
                    searching: false
                }
            })
        })
        .catch((error) => {
            this.props.onShowToast({
                show: true,
                type: 'error',
                message: this.props.appText[error.msg]
            });
            this.setState({
                searching: false
            })
        })
    }

    changeSearch(event) {
        this.setState({
            search: event.target.value
        }, () => {
            if(this.state.search !== '') {
                this.setState({
                    searching: true
                })
                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                    this.fetchNetwork(true);
                }, 3000);
            } else {
                this.setState({
                    searching: false
                })
            }
        })
    }
    render() {
        return (
            <div id="network-evento">
                <section className="container-network" 
                    style={{backgroundImage: `url(${this.state.orientationAngle == 'landscape' ? Networking : NetworkingMobile})`}}
                >
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-3 col-sm-12 col-lg-6">
                                <section className="network-header">
                                    <h1>{this.props.appText.LABEL_NETWORK_HEADER}</h1>
                                    <p>{this.props.appText.LABEL_NETWORK_TEXT.replace('//EVENTNAME//',this.state.eventName)}</p>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-lg-3 col-lg-6">
                                <section className="busca-evento">
                                    <div className="box-busca">
                                        <input 
                                            value={this.state.search} 
                                            onChange={this.changeSearch} 
                                            type="text" 
                                            id="txtBusca" 
                                            placeholder={this.props.appText.LABEL_NETWORK_SEARCH_PLACEHOLDER}
                                        />
                                        {this.state.searching ? <div className={'search-spinner'}><CircularProgress size={22}/></div> : null}
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <section id={'lista-people'} className="lista-people">
                                    <ul>
                                        <li className={'people-row header'}>
                                            <p className={'name'}>{this.props.appText.LABEL_FULLNAME}</p>
                                            <p className={'email'}>{this.props.appText.LABEL_EMAIL}</p>
                                            <p className={'social'}>{this.props.appText.LABEL_SOCIAL_MEDIAS}</p>
                                        </li>
                                        <InfiniteScroll
                                            scrollableTarget={'lista-people'}
                                            dataLength={this.state.people.length}
                                            next={this.fetchNetwork}
                                            hasMore={this.state.people.length < this.state.count}
                                            loader={
                                                <div className={'infinite-box'}><CircularProgress /></div>
                                            }
                                        >
                                        {this.state.people.map((person) => {
                                            return (
                                            <li className={'people-row'}>
                                                <p className={'name'}>{person.name}</p>
                                                <p className={'email'}>{person.email}</p>
                                                <div className={'social'}>
                                                    <a href={person.facebook} target="blank" title={this.props.appText.LABEL_NETWORK_FACEBOOK} className={person.facebook === '' ? 'disabled' : ''}><FontAwesomeIcon icon={faFacebookF} className={'fab-brands'} /></a>
                                                    <a href={person.twitter} target="blank" title={this.props.appText.LABEL_NETWORK_TWITTER} className={person.twitter === '' ? 'disabled' : ''}><FontAwesomeIcon icon={faTwitter} className={'fab-brands'} /></a>
                                                    <a href={person.linkedIn} target="blank" title={this.props.appText.LABEL_NETWORK_LINKEDIN} className={person.linkedIn === '' ? 'disabled' : ''}><FontAwesomeIcon icon={faLinkedinIn} className={'fab-brands'} /></a>
                                                </div>
                                            </li>

                                            )
                                        })}
                                        </InfiniteScroll>
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div className={'turn-back'} onClick={() => this.props.history.goBack()}>
                        <img src={ArrowDown} className={'fa'}/>
                        <p>{this.props.appText.LABEL_BACK}</p>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NetworkPage));