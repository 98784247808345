import React from 'react';
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import DeleteIcon from '@material-ui/icons/Delete';
import { useRowStyles } from "./row.styles";

export default function AttendantRow(props: { row: any, removeAttendant: (row: any) => any}) {
    const { row, removeAttendant } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell align="center">{row.phone}</TableCell>
                <TableCell align="center">

                    <IconButton 
                        title={'Remover Atendente'}
                        aria-label="expand row"
                        onClick={() => removeAttendant(row)}
                        className={classes.actionButton}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}