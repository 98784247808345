
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

export default class StandService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getStands(data?, type: 'CMS' | 'SITE' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].STANDS, data).then((responseData) => {
            return responseData.data;
        });
    }

    getStand(id: number, type: 'CMS' | 'SITE' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].STANDS+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }

    setStand(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';
        let formData = new FormData();
        for (let key in data) {
            if (key === 'image') {
                formData.append(key, data[key]);
            } else {
                formData.set(key, data[key]);
            }
        }
        return this._apiService[method](Configs.ENDPOINTS.STANDS + (editing ? `/${data.id}` : ''), formData, true).then((responseData) => {
            return responseData;
        })
    }

    removeStand(standIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.STANDS, {ids: standIdArray}).then((responseData) => {
            return responseData;
        })
    }

    addAttendant(standId, userId) {
        return this._apiService.post(Configs.ENDPOINTS.STANDS+'/'+standId+'/attendants', {userId: userId}).then((responseData) => {
            return responseData;
        })
    }

    getAttendants(standId) {
        return this._apiService.get(Configs.ENDPOINTS.STANDS+'/'+standId+'/attendants').then((responseData) => {
            let formattedObjectData = {...responseData};
            responseData.data.rows.map((item, index) => {
                const User = {...item.User};
                delete User.id;
                delete item.User;
                formattedObjectData.data.rows[index] = {
                    ...item,
                    ...User
                }
            });
            return formattedObjectData.data;
        })
    }

    removeAttendant(standId, userIdArray) {
        return this._apiService.del(Configs.ENDPOINTS.STANDS+'/'+standId+'/attendants', {ids: userIdArray}).then((responseData) => {
            return responseData.data;
        })
    }

}
