import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";
import { TimeUtils } from "../../Utils/time";

export default class LogService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getLogs(data?: any) {
        return this._apiService.get(Configs.ENDPOINTS.LOGS, data).then((responseData) => {
            responseData.data.rows.map((data, index) => {
                responseData.data.rows[index].createdAt = TimeUtils.timestampToMomentFormat(data.createdAt);
                responseData.data.rows[index].updatedAt = TimeUtils.timestampToMomentFormat(data.updatedAt);
            })

            return responseData.data;
        })
    }
    getLog(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.LOGS+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }

}