export default class StringUtils {
    
    static queryStringToObject(string) {
        const object: any = {};
        string.replace('?', '').split('&').map((itemString) => {
            const itemObject = itemString.split('=');
            object[itemObject[0]] = itemObject[1];
        })
        return object;
    }

    static truncate(string: string, size: number) {
        if (string.length > size) {
            return `${string.slice(0, size)}...`;
        }
        return string;
    }
}
