import React from 'react';
import clsx from 'clsx';

import {
  Switch,
  useRouteMatch,
  Link,
  withRouter,
  useLocation,
  Route,
  Redirect
} from "react-router-dom";

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

// import DashboardPage from './dashboard/dashboard.component';
import EventsPage from './events/events-page.component';
import BasicInfoPage from './events/pages/basic-info-page.component';
import ClientsPage from '../Cms/clients/clients-page.component';
// import OrdersPage from '../Cms/orders/orders-page.component';
import UsersPage from '../Cms/users/users-page.component';
import CategoriesPage from '../Cms/categories/categories-page.component';
import StandPage from './stand/stand-page.component';
import LanguagePage from './language/language-page.component';
import LogsPage from './logs/logs-page.component';
import ChatsPage from './chats/chats-page.component';
import LoggedBox from '../../Components/LoggedBox/logged-box.component';
import { IconButton, Divider, ListItem, Toolbar, Hidden } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer/Drawer";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

import MenuIcon from '@material-ui/icons/Menu';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import ChatIcon from '@material-ui/icons/Chat';
// import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import CategoryIcon from '@material-ui/icons/Category';
import LanguageIcon from '@material-ui/icons/Language';

import AppBar from "@material-ui/core/AppBar/AppBar";
import { fetchLogout } from "../../Redux/actions/auth.actions";
import { connect } from "react-redux";

import Hoffmann from '../../assets/images/hoffmann.png';
import { AppStore } from "../../Interface/app-store.interface";


import ProtectedRoute from '../../Components/ControlAccess/protected.component';
const drawerWidth = 240;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerOpen: {
      width: drawerWidth,
      backgroundColor: '#3a0b00'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      minHeight: 100
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    content: {
        width: '100%',
        flexGrow: 1,
        padding: 16
    },
    listItem: {
        color: '#FFFFFF',
        '&:hover': {
            color: '#fd7151'
        }
    },
    listItemIcon: {
        color: '#FFFFFF'
    },
    listItemSelected: {
        backgroundColor: '#3a0b00',
        '&:hover': {
            backgroundColor: '#3a0b00'
        }
    },
  }),
);


const CmsPage = (props) => {
    const { window, currentUser } = props;
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    let location = useLocation()

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const menuItens = {
        managment: {
            // Dashboard: {
            //     label: 'Dashboard',
            //     link: `${url}/dashboard`,
            //     icon: <DashboardIcon />,
            //     roleValidation: ['admin']
            // },
            Events: {
                label: 'Eventos',
                link: `${url}/eventos`,
                icon: <EventIcon />,
                roleValidation: ['admin']
            },
            Categories: {
                label: 'Categorias',
                link: `${url}/categorias`,
                icon: <CategoryIcon />,
                roleValidation: ['admin']
            },
            Clients: {
                label: 'Clientes',
                link: `${url}/clientes`,
                icon: <GroupIcon />,
                roleValidation: ['admin']
            },
            // Orders: {
            //     label: 'Pedidos',
            //     link: `${url}/pedidos`,
            //     icon: <ConfirmationNumberIcon />,
            //     roleValidation: ['admin']
            // },
            Stands: {
                label: 'Estandes',
                link: `${url}/estandes`,
                icon: <ChatIcon />,
                roleValidation: ['attendant']
            }
        },
        admin: {
            Users: {
                label: 'Usuários',
                link: `${url}/usuarios`,
                icon: <SupervisorAccountIcon />,
                roleValidation: ['admin']
            },
            Logs: {
                label: 'Atividades',
                link: `${url}/atividades`,
                icon: <AssignmentIcon />,
                roleValidation: ['admin']
            },
            Language: {
                label: 'Idioma',
                link: `${url}/idiomas`,
                icon: <LanguageIcon />,
                roleValidation: ['admin']
            }
        }
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = <React.Fragment>
            <Hidden mdDown implementation="css">
                <div className={classes.toolbar} >
                    <img src={Hoffmann} width={180}/>
                </div>
            </Hidden>
            <Hidden lgUp implementation="css">
                <div style={{width: '100%', borderBottom: '1px solid #FFF' }}>
                    <LoggedBox />
                </div>
            </Hidden>
            <Divider />
            {Object.keys(menuItens).map((slug, index) => {
                const categoryObject = menuItens[slug];
                let renderList = Object.keys(categoryObject)
                .filter((slug) => categoryObject[slug].roleValidation.includes(currentUser.role))
                .map((slug, index) => {
                    const iconObject = categoryObject[slug];
                    return <ListItem 
                        button
                        selected={location.pathname.match(iconObject.link)}
                        key={slug}
                        component={Link}
                        classes={{
                            root: classes.listItem,
                            selected: classes.listItemSelected
                        }}
                        to={iconObject.link}
                        onClick={iconObject.action ? () => iconObject.action() : null}>
                        <ListItemIcon
                            classes={{
                                root: classes.listItemIcon
                            }}
                        >
                            {iconObject.icon}
                        </ListItemIcon>
                        <ListItemText primary={iconObject.label} />
                    </ListItem>
                });
                return renderList
            })}
        </React.Fragment>;
    return (
        <div className={classes.root}>
            <Hidden lgUp implementation="css">
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',flex: 1,marginLeft: -51}}>
                            <img src={Hoffmann} width={120}/>
                        </div>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden lgUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={open}
                        onClose={handleDrawerClose}
                        classes={{
                            paper: classes.drawerOpen,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer
                        variant="permanent"
                        open={true}
                        classes={{
                            paper: classes.drawerOpen,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <Hidden lgUp implementation="css">
                    <div className={classes.toolbar} />
                </Hidden>
                <Hidden mdDown implementation="css">
                    <div className={classes.header}>
                        <LoggedBox/>
                    </div>
                </Hidden>
                <Switch>
                    <ProtectedRoute path={`${path}/eventos`} exact role={menuItens.managment.Events.roleValidation} isCms={true}>
                        <EventsPage />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/eventos/:eventId/:tab?`} role={menuItens.managment.Events.roleValidation} isCms={true}>
                        <BasicInfoPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/categorias`} exact role={menuItens.managment.Categories.roleValidation} isCms={true}>
                        <CategoriesPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/clientes`} exact role={menuItens.managment.Clients.roleValidation} isCms={true}>
                        <ClientsPage/>
                    </ProtectedRoute>
                    {/* <ProtectedRoute path={`${path}/pedidos`} exact role={menuItens.managment.Orders.roleValidation} isCms={true}>
                        <OrdersPage/>
                    </ProtectedRoute> */}
                    <ProtectedRoute path={`${path}/usuarios`} role={menuItens.admin.Users.roleValidation} isCms={true}>
                        <UsersPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/atividades`} role={menuItens.admin.Logs.roleValidation} isCms={true}>
                        <LogsPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/estandes`} exact role={menuItens.managment.Stands.roleValidation} isCms={true}>
                        <StandPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/idiomas`} exact role={menuItens.admin.Language.roleValidation} isCms={true}>
                        <LanguagePage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/estandes/:standId/chats`} role={menuItens.managment.Stands.roleValidation} isCms={true}>
                        <ChatsPage/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`${path}/estandes/:standId/:tab`} role={menuItens.managment.Stands.roleValidation} isCms={true}>
                        <BasicInfoPage/>
                    </ProtectedRoute>
                    <Route path={`${path}`}
                        render={({ location }) =>
                          <Redirect
                            to={{
                              pathname: currentUser.role == 'admin' ? `${path}/eventos` : `${path}/estandes`,
                              state: { from: location }
                            }}
                          />
                        }
                      />
                    {/* <ProtectedRoute path={`${path}/dashboard`} role={menuItens.managment.Dashboard.roleValidation} isCms={true}>
                        <DashboardPage />
                    </ProtectedRoute> */}
                </Switch>
            </main>
        </div>
    );
};

const mapStateToProps = (state: AppStore) => {
    const { currentUser } = state.auth;
    return { currentUser };
}

const mapDispatchToProps = (dispatch) => {
  return { 
    onLogout: () => dispatch(fetchLogout('USER'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CmsPage));