import React from 'react';
import {
    withRouter
} from "react-router-dom";

// import HallStands from '../../../../../assets/images/platform/hall-stands-prata.jpg';
import './hall-stands-prata.component.scss';
import StandService from "../../../../../Services/stand/stand.service";
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import SponsorService from "../../../../../Services/sponsor/sponsor.service";

interface HallStandsSilverPageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface HallStandsSilverPageState {
    animating: boolean;
}

class HallStandsSilverPage extends React.Component<HallStandsSilverPageProps, HallStandsSilverPageState> {
    standService: StandService;
    sponsorService: SponsorService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.standService = new StandService();
        this.sponsorService = new SponsorService();
        this.state = {
            animating: false
        }
    }

    goToStand(standId) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/estande/${standId}`);
    }

    componentWillUnmount() {
        this.setState((state) => ({
            animating: true
        }));
    }
    

    render() {
        return (
            <div id="hall-stands-prata">
                <section className={`container-hall-stands ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/hall-stands-prata.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className={`stand stand-${1}`}>
                                <a href="estande/5-halex-istar"></a>
                            </div>
                            <div className={`stand stand-${2}`}>
                                <a href="estande/6-aspen-pharma"></a>
                            </div>
                            <div className={`stand stand-${3}`}>
                                <a href="estande/7-uniao-quimica"></a>
                            </div>
                            <div className={`stand stand-${4}`}>
                                <a href="estande/8-medtronic"></a>
                            </div>
                            <div className={`stand stand-${5}`}>
                                <a href="estande/9-alexion"></a>
                            </div>
                            <div className={`stand stand-${6}`}>
                                <a href="estande/10-nestle"></a>
                            </div>
                            <div className={`door left-door`}>
                                <a href="hall-estandes-gold"></a>
                            </div>
                            <div className={`door right-door`}>
                                <a href="hall-estandes-bronze/1"></a>
                            </div>
                            <div className={`painel left-painel`}>
                                <a href="hall-estandes-gold"></a>
                            </div>
                            <div className={`painel right-painel`}>
                                <a href="hall-estandes-bronze/1"></a>
                            </div>
                            <div className={`centro`}>
                                <a href="https://zoom.us/j/98771344984" target="_blank"></a>
                            </div>
                        </div>
                    </div>
		        </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(HallStandsSilverPage));