import React from 'react';
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useRowStyles } from "./row.styles";

export default function MediaRow(props: { row: any, editMedia: (row: any) => any, removeMedia: (row: any) => any }) {
    const { row, editMedia, removeMedia } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row">{row.title}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell align="center">
                    <IconButton
                        title={'Editar Mídia'}
                        aria-label="expand row"
                        onClick={() => editMedia(row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        title={'Remover Mídia'}
                        aria-label="expand row"
                        onClick={() => removeMedia(row)}
                        className={classes.actionButton}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}