import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";
import { Alert } from "@material-ui/lab";

import i18n from '../../../../Utils/i18n';

import './basic-info-page.component.scss'
import '../../../../assets/styles/objects/table.styles.scss'
import '../../../../assets/styles/objects/image-wrapper.styles.scss'
import './../events-page.component.scss'
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormGroup,
    FormControlLabel,
    InputLabel,
    InputAdornment,
    OutlinedInput,
    Select,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Switch,
    Typography
} from "@material-ui/core";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';

import { showToast, showLoader, hideLoader, showDialog, hideDialog } from "../../../../Redux/actions/layout.actions";

import EventsService from "../../../../Services/events/events.service";
import CategoryService from "../../../../Services/category/category.service";
import { EventForm } from "../../../../Utils/forms/event-form";
// import SponsorPage from "./sponsor-page.component";
import RoomPage from "./room-page.component";
// import LecturePage from "./lecture-page.component";
import StandPage from "./stand-page.component";
import MediaPage from "./media-page.component";
// import ProductPage from "./product-page.component";
import RoomService from "../../../../Services/room/room.service";
import StandService from "../../../../Services/stand/stand.service";
import { DialogInterface } from "../../../../Redux/reducers/layout.reducer";
import MouseOverPopover from "../../../../Components/UI/Popover/popover.component";

interface BasicInfoPageProps {
    match: any;
    history: any;
    currentUser: any;
    appText: any;
    onShowToast: (data: any) => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
}

interface BasicInfoPageState {
    eventForm: EventForm;
    roomList: any[];
    standList: any[];
    submiting: boolean;
    expanded: string | false;
    selectedRoom: any;
    removeImageFile: 0 | 1;
    removeBannerFile: 0 | 1;
    removeThumbFile: 0 | 1;
}

enum tabLabel {
    'informacoes-basicas' = 'basic',
    'patrocinadores' = 'sponsors',
    'salas' = 'rooms',
    'estandes' = 'stands',
    'produtos-e-servicos' = 'products',
    'midias' = 'medias'
}

class BasicInfoPage extends Component<BasicInfoPageProps, BasicInfoPageState> {
    private _eventsService: EventsService;
    private _categoryService: CategoryService;
    private _roomService: RoomService;
    private _standService: StandService;

    constructor(props) {
        super(props);
        this._eventsService = new EventsService();
        this._categoryService = new CategoryService();
        this._roomService = new RoomService();
        this._standService = new StandService();
        this.state = {
            eventForm: new EventForm(),
            submiting: false,
            roomList: [],
            standList: [],
            expanded: props.match.params.tab ? tabLabel[props.match.params.tab] : tabLabel['informacoes-basicas'],
            selectedRoom: {id: false},
            removeImageFile: 0,
            removeBannerFile: 0,
            removeThumbFile: 0,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleAccordionChange = this.handleAccordionChange.bind(this);
        this.handleImageFileChange = this.handleImageFileChange.bind(this);
        this.handleBannerFileChange = this.handleBannerFileChange.bind(this);
        this.handleThumbFileChange = this.handleThumbFileChange.bind(this);
        this.onSelectRoom = this.onSelectRoom.bind(this);
        this.setRemoveImageFile = this.setRemoveImageFile.bind(this);
        this.setRemoveBannerFile = this.setRemoveBannerFile.bind(this);
        this.setRemoveThumbFile = this.setRemoveThumbFile.bind(this);
    }

    fetchEvent(eventId) {
        return this._eventsService.getEvent(eventId).then((responseData) => {
            let newForm = { ...this.state.eventForm };
            const rowData = responseData;
            for (var key in newForm) {
                if (newForm.hasOwnProperty(key) && key !== 'formError' && key !== 'editing' && key !== 'featured' && key !== 'status' && key !== 'published') {
                    newForm[key].value = rowData[key];
                }
                if (newForm.hasOwnProperty(key) && key === 'featured') {
                    newForm[key].value = rowData[key];
                    newForm[key].checked = rowData[key] === 1;
                }
                if (newForm.hasOwnProperty(key) && key === 'status') {
                    newForm[key].value = rowData[key];
                    newForm[key].checked = rowData[key] === 'open';
                }
                if (newForm.hasOwnProperty(key) && key === 'published') {
                    newForm[key].value = rowData[key];
                    newForm[key].checked = rowData[key] == 1;
                }
            }
            newForm.formError = false;
            newForm.editing = true;
            this.setState({
                eventForm: newForm
            })
        })
    }

    fetchRooms(eventId) {
        this._roomService.getRooms({eventId}).then((responseData) => {
            this.setState({
                roomList: responseData.rows
            })
        })
    }

    fetchStands(eventId) {
        this._standService.getStands({eventId}).then((responseData) => {
            this.setState({
                standList: responseData.rows
            })
        })
    }

    componentDidMount() {
        this.fetchCategories();
        const eventId = this.props.match.params.eventId;
        if (eventId !== 'new') {
            this.fetchEvent(eventId);
            this.fetchRooms(eventId);
            this.fetchStands(eventId);
        }
    }

    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                eventForm: {
                    ...state.eventForm,
                    formError: false,
                    [element]: {
                        ...state.eventForm[element],
                        value
                    }
                }
            }
        })
    }

    handleCheckboxChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                eventForm: {
                    ...state.eventForm,
                    formError: false,
                    [element]: {
                        ...state.eventForm[element],
                        checked: value == 1 ? true : false,
                        value
                    }
                }
            }
        })
    }
    _handleFileChange(fileField: string, element: string, value: any) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            this.setState((state: any) => {
                return {
                    ...state,
                    [fileField]: 0,
                    eventForm: {
                        ...state.eventForm,
                        formError: false,
                        [element]: {
                            ...state.eventForm[element],
                            renderValue: e.target.result,
                            value: value
                        }
                    }
                }
            })
        }
        reader.readAsDataURL(value);
    }
    handleImageFileChange(element: string, value: any) {
        this._handleFileChange('removeImageFile', element, value)
    }
    handleBannerFileChange(element: string, value: any) {
        this._handleFileChange('removeBannerFile', element, value)
    }
    handleThumbFileChange(element: string, value: any) {
        this._handleFileChange('removeThumbFile', element, value)
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.eventForm.id.value,
            categoryId: this.state.eventForm.categoryId.value,
            name: this.state.eventForm.name.value,
            imageFile: this.state.eventForm.image.value,
            bannerFile: this.state.eventForm.banner.value,
            thumbFile: this.state.eventForm.thumb.value,
            description: this.state.eventForm.description.value,
            value: this.state.eventForm.value.value,
            discount: this.state.eventForm.discount.value,
            // installments: this.state.eventForm.installments.value,
            installments: 1,
            exchange: this.state.eventForm.exchange ? this.state.eventForm.exchange.value : 1,
            startDate: this.state.eventForm.startDate.value,
            endDate: this.state.eventForm.endDate.value,
            startTime: this.state.eventForm.startTime.value,
            endTime: this.state.eventForm.endTime.value,
            facebook: this.state.eventForm.facebook.value,
            twitter: this.state.eventForm.twitter.value,
            linkedIn: this.state.eventForm.linkedIn.value,
            // featured: this.state.eventForm.featured.value,
            featured: 0,
            published: this.state.eventForm.published.value,
            removeImageFile: this.state.removeImageFile,
            removeBannerFile: this.state.removeBannerFile,
            removeThumbFile: this.state.removeThumbFile,
            status: this.state.eventForm.status.value == 1 ? 'open' : 'closed',
        }
        if (!this.state.eventForm.editing) {
            delete data.id;
        }
        if (this.state.eventForm.image.value == undefined || (typeof this.state.eventForm.image.value == 'string')) {
            delete data.imageFile;
        }
        if (this.state.eventForm.banner.value == undefined || (typeof this.state.eventForm.banner.value == 'string')) {
            delete data.bannerFile;
        }
        if (this.state.eventForm.thumb.value == undefined || (typeof this.state.eventForm.thumb.value == 'string')) {
            delete data.thumbFile;
        }
        this.props.onShowLoader();
        this._eventsService.setEvent(data, this.state.eventForm.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.setState((state) => ({
                eventForm: {
                    ...state.eventForm,
                    id: {
                        ...state.eventForm.id,
                        value: response.data.id
                    },
                    formError: false,
                    editing: true
                }
            }))
            this.handleAccordionChange('sponsors');
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState((state) => ({
                    eventForm: {
                        ...state.eventForm,
                        formError: this.props.appText[error.msg]
                    }
                }))
            }
        })

    }
    handleRemove(event: any) {
        event.preventDefault();
        this.props.onShowDialog({
            handlePositive: () => {
                this._eventsService.removeEvent([this.state.eventForm.id.value]).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    this.props.history.goBack();
                })
                .catch((error) => {
                    if (error.status >= 400) {
                        this.props.onShowToast({
                            show: true,
                            type: 'warning',
                            message: this.props.appText[error.msg]
                        });
                    }
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o evento "${this.state.eventForm.name.value}"?`
        })

    }

    fetchCategories() {
        this._categoryService.getCategories().then((responseData) => {
            const newForm = { ...this.state.eventForm };
            newForm.categoryId.data = responseData.rows
            this.setState({
                eventForm: newForm
            })
        })
    }

    handleAccordionChange(panel: string) {
        this.setState((state: any) =>
            ({
                expanded: state.expanded !== panel ? panel : false
            })
        )
    };

    onSelectRoom(room) {
        this.setState({
            selectedRoom: room
        })
    }

    setRemoveImageFile(){
        this.handleChange('image', undefined);
        this.setState({
            removeImageFile: 1
        })
    }
    setRemoveBannerFile(){
        this.handleChange('banner', undefined);
        this.setState({
            removeBannerFile: 1
        })
    }
    setRemoveThumbFile(){
        this.handleChange('thumb', undefined);
        this.setState({
            removeThumbFile: 1
        })
    }
    render() {
        const { eventForm, expanded } = this.state;
        return (
            <div style={{marginTop: 16, padding: 16}}>
                <Button style={{ marginBottom: 16 }} onClick={this.props.history.goBack} variant={'outlined'} color="primary">

                    <p
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <ArrowBackIcon fontSize={'small'} style={{ marginRight: 8 }} />voltar para listagem
                        </p>
                </Button>
                <div className={'header-form'}>
                    <h2>
                        {eventForm.editing ? eventForm.name.value : "Adicionar Evento"}
                    </h2>
                </div>
                <div className={'root'}>
                    <div style={{marginBottom: 32}}>
                {this.props.currentUser.role === 'admin' ? <Accordion
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'basic'} 
                    onChange={() => this.handleAccordionChange('basic')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="basic-content"
                        id="basic-header"
                    >
                        <p className={'heading'}>Informações básicas</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={this.handleSubmit} noValidate style={{width: '100%'}}>
                            {!!eventForm.formError ? <Alert severity="error">{eventForm.formError}</Alert> : null}
                            <Grid container spacing={2}>
                                <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'relative',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #c3c3c3',
                                            padding: eventForm.image.value ? 0 : 24
                                        }}>
                                            {eventForm.image.value ?
                                                <div className={'image-wrapper'}>
                                                    <img className={'form-image'} src={eventForm.image.renderValue ? eventForm.image.renderValue : eventForm.image.value} alt={eventForm.slug.value} />
                                                    <div className={'overlay-image'}>
                                                        <Button
                                                            className={'overlay-button'}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                            onClick={(event: any) => this.setRemoveImageFile()}
                                                        >
                                                            Remover fundo
                                                            </Button>
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 200, height: 150, marginLeft: -20 }} /></div>
                                                    <Button
                                                        className={'overlay-button'}
                                                        variant="contained"
                                                        color="primary"
                                                        component="label"
                                                    >
                                                        Fazer upload de fundo
                                                            <input
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(event: any) => this.handleImageFileChange('image', event.target.files[0])}
                                                            type="file"
                                                        />
                                                    </Button>
                                                    <div className={'info-icon'}>
                                                        <MouseOverPopover
                                                            text={
                                                                <HelpIcon fontSize="small"/>
                                                            }
                                                            content={
                                                                <React.Fragment>
                                                                    <p>- Deve ter uma proporção equivalente a <strong>1920x500</strong></p>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #c3c3c3',
                                            padding: eventForm.banner.value ? 0 : 24
                                        }}>
                                            {eventForm.banner.value ?
                                                <div className={'image-wrapper'}>
                                                    <img className={'form-image'} src={eventForm.banner.renderValue ? eventForm.banner.renderValue : eventForm.banner.value} alt={eventForm.slug.value} />
                                                    <div className={'overlay-image'}>
                                                        <Button
                                                            className={'overlay-button'}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                            onClick={(event: any) => this.setRemoveBannerFile()}
                                                        >
                                                            Remover banner
                                                            </Button>
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 200, height: 150, marginLeft: -20 }} /></div>
                                                    <Button
                                                        className={'overlay-button'}
                                                        variant="contained"
                                                        color="primary"
                                                        component="label"
                                                    >
                                                        Fazer upload de banner
                                                            <input
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(event: any) => this.handleBannerFileChange('banner', event.target.files[0])}
                                                            type="file"
                                                        />
                                                    </Button>
                                                    <div className={'info-icon'}>
                                                        <MouseOverPopover
                                                            text={
                                                                <HelpIcon fontSize="small"/>
                                                            }
                                                            content={
                                                                <React.Fragment>
                                                                    <p>- Deve ter uma proporção equivalente a <strong>240x340</strong></p>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #c3c3c3',
                                            padding: eventForm.thumb.value ? 0 : 24
                                        }}>
                                            {eventForm.thumb.value ?
                                                <div className={'image-wrapper'}>
                                                    <img className={'form-image'} src={eventForm.thumb.renderValue ? eventForm.thumb.renderValue : eventForm.thumb.value} alt={eventForm.slug.value} />
                                                    <div className={'overlay-image'}>
                                                        <Button
                                                            className={'overlay-button'}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                            onClick={(event: any) => this.setRemoveThumbFile()}
                                                        >
                                                            Remover miniatura
                                                            </Button>
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 200, height: 150, marginLeft: -20 }} /></div>
                                                    <Button
                                                        className={'overlay-button'}
                                                        variant="contained"
                                                        color="primary"
                                                        component="label"
                                                    >
                                                        Fazer upload de miniatura
                                                            <input
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={(event: any) => this.handleThumbFileChange('thumb', event.target.files[0])}
                                                            type="file"
                                                        />
                                                    </Button>
                                                    <div className={'info-icon'}>
                                                        <MouseOverPopover
                                                            text={
                                                                <HelpIcon fontSize="small"/>
                                                            }
                                                            content={
                                                                <React.Fragment>
                                                                    <p>- Deve ter uma proporção equivalente a <strong>252x150</strong></p>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ alignItems: 'stretch', marginBottom: 16 }}>
                                <Grid item xs={12} className={'container'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <TextField
                                                {...eventForm.name}
                                                fullWidth
                                                error={!!eventForm.formError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('name', event.target.value)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                                <InputLabel htmlFor={eventForm.categoryId.inputProps.name} required={eventForm.categoryId.inputProps.required}>{eventForm.categoryId.label}</InputLabel>
                                                <Select
                                                    native
                                                    {...eventForm.categoryId}
                                                    error={!!eventForm.formError}
                                                    onChange={(event) => this.handleChange('categoryId', event.target.value)}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {eventForm.categoryId.data.map((item) => {
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        {...eventForm.description}
                                        fullWidth
                                        multiline
                                        rows={5}
                                        error={!!eventForm.formError}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => this.handleChange('description', event.target.value)}
                                        variant="outlined"
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item sm={4} xs={6}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <TextField
                                                    {...eventForm.startDate}
                                                    fullWidth
                                                    error={!!eventForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('startDate', event.target.value)}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={2} xs={6}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <TextField
                                                    {...eventForm.startTime}
                                                    fullWidth
                                                    error={!!eventForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('startTime', event.target.value)}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={4} xs={6}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <TextField
                                                    {...eventForm.endDate}
                                                    fullWidth
                                                    error={!!eventForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('endDate', event.target.value)}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={2} xs={6}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <TextField
                                                    {...eventForm.endTime}
                                                    fullWidth
                                                    error={!!eventForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('endTime', event.target.value)}
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignContent="center" alignItems="center">
                                        <Grid item sm={2} xs={5}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    label={'Evento gratuito'}
                                                    control={
                                                        <Switch
                                                            id='isFree'
                                                            name={'isFree'}
                                                            value={0}
                                                            required={false}
                                                            checked={eventForm.value.value === '0.00'}
                                                            onChange={(event) => {
                                                                this.handleChange('value', event.target.checked ? '0' : '')
                                                                this.handleChange('discount', event.target.checked ? '0' : '')
                                                                 this.handleChange('installments', event.target.checked ? '0' : '')
                                                            }}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item sm={3} xs={7}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <InputLabel htmlFor={eventForm.value.name} required={eventForm.value.required}>{eventForm.value.label}</InputLabel>
                                                <OutlinedInput
                                                    {...eventForm.value}
                                                    onChange={(event) => this.handleChange('value', event.target.value)}
                                                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={2} xs={7}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <InputLabel htmlFor={eventForm.discount.name} required={eventForm.discount.required}>{eventForm.discount.label}</InputLabel>
                                                <OutlinedInput
                                                    {...eventForm.discount}
                                                    onChange={(event) => this.handleChange('discount', event.target.value)}
                                                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                       {/* <Grid item sm={2} xs={5}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <InputLabel htmlFor={eventForm.installments.name} required={eventForm.installments.required}>{eventForm.installments.label}</InputLabel>
                                                <OutlinedInput
                                                    {...eventForm.installments}
                                                    onChange={(event) => this.handleChange('installments', event.target.value)}
                                                    startAdornment={<InputAdornment position="start">X</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid> */}
                                        <Grid item sm={3} xs={5}>
                                            <FormControl fullWidth variant="outlined" margin={'normal'}>
                                                <InputLabel htmlFor={eventForm.exchange.name} required={eventForm.exchange.required}>{eventForm.exchange.label}</InputLabel>
                                                <OutlinedInput
                                                    {...eventForm.exchange}
                                                    onChange={(event) => this.handleChange('exchange', event.target.value)}
                                                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignContent="center" alignItems="center">
                                        <Grid item xs={4} >
                                            <TextField
                                                {...eventForm.facebook}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('facebook', event.target.value)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <TextField
                                                {...eventForm.twitter}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('twitter', event.target.value)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <TextField
                                                {...eventForm.linkedIn}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('linkedIn', event.target.value)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignContent="space-between" alignItems="center">
                                         <Grid item xs={6} >
                                            {/* <FormGroup>
                                                <FormControlLabel
                                                    label={eventForm.featured.label}
                                                    control={
                                                        <Switch
                                                            {...eventForm.featured}
                                                            onChange={(event) => this.handleCheckboxChange(
                                                                'featured',
                                                                (event.target.checked) ? 1 : 0
                                                            )}
                                                        />
                                                    }
                                                />
                                            </FormGroup> */}
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={6} >
                                                    <FormGroup>
                                                        <Typography component="div">
                                                            <Grid component="label" container alignItems="center" justify={'flex-end'} spacing={1}>
                                                                <Grid item>{i18n.PT.LABEL_CLOSED}</Grid>
                                                                <Grid item>
                                                                    <Switch 
                                                                        {...eventForm.status}
                                                                        onChange={(event) => this.handleCheckboxChange('status', (event.target.checked) ? 1 : 0)} />
                                                                </Grid>
                                                                <Grid item>{i18n.PT.LABEL_OPEN}</Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <FormGroup>
                                                        <Typography component="div">
                                                            <Grid component="label" container alignItems="center" justify={'flex-end'} spacing={1}>
                                                                <Grid item>Rascunho</Grid>
                                                                <Grid item>
                                                                    <Switch 
                                                                        {...eventForm.published}
                                                                        onChange={(event) => this.handleCheckboxChange('published', (event.target.checked) ? 1 : 0)} />
                                                                </Grid>
                                                                <Grid item>Publicado</Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 16 }} justify={'flex-end'}>
                                <Grid item>
                                    <Button size="large" type="submit" variant="contained" color="primary">
                                        {eventForm.editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>: null}
                {/* {this.props.currentUser.role === 'admin' ?<Accordion
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'sponsors'} onChange={() => this.handleAccordionChange('sponsors')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="sponsors-content"
                        id="sponsors-header"
                    >
                        <p className={'heading'}>Patrocinadores</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SponsorPage eventId={this.props.match.params.eventId}/>
                    </AccordionDetails>
                </Accordion> : null} */}
                {/* {this.props.currentUser.role === 'admin' ?<Accordion
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'coupons'} onChange={() => this.handleAccordionChange('coupons')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="coupons-content"
                        id="coupons-header"
                    >
                        <p className={'heading'}>Cupons de descontos</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CouponPage eventId={this.props.match.params.eventId}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> : null } */}
                </div>
                <div style={{marginBottom: 32}}>
                {this.props.currentUser.role === 'admin' ? <React.Fragment>
                <Accordion
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }} 
                    expanded={expanded === 'rooms'} onChange={() => this.handleAccordionChange('rooms')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="rooms-content"
                        id="rooms-header"
                    >
                        <p className={'heading'}>Salas</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RoomPage roomList={this.state.roomList} eventId={this.props.match.params.eventId} fetchRoomList={() => this.fetchRooms(this.props.match.params.eventId)}/>
                    </AccordionDetails>
                </Accordion>
                {/* <Accordion
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }} 
                    expanded={expanded === 'lectures'} onChange={() => this.handleAccordionChange('lectures')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="lectures-content"
                        id="lectures-header"
                    >
                        <p className={'heading'}>Palestras</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LecturePage roomList={this.state.roomList}/>
                    </AccordionDetails>
                </Accordion> */}
                </React.Fragment> : null}
                </div>
                <div style={{marginBottom: 32}}>
                {this.props.match.params.standId == undefined ? <Accordion 
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'stands'} onChange={() => this.handleAccordionChange('stands')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="stands-content"
                        id="stands-header"
                    >
                        <p className={'heading'}>Estandes</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <StandPage standList={this.state.standList} eventId={this.props.match.params.eventId} fetchStandList={() => this.fetchStands(this.props.match.params.eventId)}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> : null}
                 {/* <Accordion 
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'products'} onChange={() => this.handleAccordionChange('products')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="products-content"
                        id="products-header"
                    >
                        <p className={'heading'}>Produtos/Serviços</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ProductPage standList={this.state.standList} selectedStand={this.props.match.params.standId}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>  */}
                <Accordion 
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'medias'} onChange={() => this.handleAccordionChange('medias')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="medias-content"
                        id="medias-header"
                    >
                        <p className={'heading'}>Mídia</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MediaPage standList={this.state.standList} selectedStand={this.props.match.params.standId}/>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                </div>
                {/* {this.props.currentUser.role === 'admin' ? 
                <Accordion 
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === 'exclude'} onChange={() => this.handleAccordionChange('exclude')}>
                    <AccordionSummary
                        classes={{expanded: 'AccordionSelected'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="coupons-content"
                        id="coupons-header"
                    >
                        <p className={'heading'}>Remover Evento</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container style={{ marginTop: 16 }} justify={'center'}>
                            <Grid item>
                                <Button size="large" onClick={this.handleRemove} variant="contained" color="secondary">
                                    {i18n.PT.LABEL_REMOVE}
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>: null} */}
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { currentUser } = state.auth;
    const { root } = state;
    const appText = root.appText;
    return { currentUser, appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
        onHideDialog: () => dispatch(hideDialog()),
        onShowDialog: (data: DialogInterface) => dispatch(showDialog(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicInfoPage));