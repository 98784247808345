import React from 'react';
import {
    withRouter
} from "react-router-dom";
import moment from 'moment';
import ArrowDown from '../../../../../assets/images/arrowDown.png';

import LectureEvento from '../../../../../assets/images/platform/lecture.jpg';
import LectureEventoMobile from '../../../../../assets/images/platform/lecture-mobile.jpg';

import './lecture.component.scss';
import LectureService from "../../../../../Services/lecture/lecture.service";
import { connect } from "react-redux";

interface LecturePageProps {
    match: any;
    history: any;
    appText: any;
}
interface LecturePageState {
    banner: string;
    lectures: any[];
    date: string;
    eventId: number;
    currentLecture: any;
    nextLecture: any;
    orientationAngle: 'portrait' | 'landscape';
}

class LecturePage extends React.Component<LecturePageProps, LecturePageState> {
    lectureService: LectureService;
    _lectures: any[];
    orientationChangeListener;
    constructor(props) {
        super(props);
        this.lectureService = new LectureService();
        this._lectures = [];
        this.state = {
            banner: '',
            eventId: this.props.match.params.slug.split('-')[0],
            lectures: [],
            orientationAngle: window.innerHeight/window.innerWidth > 1 ? 'portrait' : 'landscape',
            currentLecture: null,
            nextLecture: null,
            date: moment(new Date()).format('dddd, DD [de] MMMM [de] YYYY, HH:mm')
        }
    }

    componentDidMount() {
        this.lectureService.getMyLectures({
            roomId: this.props.match.params.roomId,
            startDate: moment().format('YYYY-MM-DD'),
            order:'startTime:asc'
        }, 'SITE').then((responseData) => {
            const momentDate = moment(new Date());
            this._lectures = responseData.rows;
            let currentLecture: any = null;
            for (var index = 0; index < this._lectures.length; index++) {
                var lecture = this._lectures[index];
                if(lecture.startTime <= momentDate.format('HH:mm:ss') && lecture.endTime > momentDate.format('HH:mm:ss')) {
                    currentLecture = lecture;
                    this._lectures.splice(index,1);
                    break;
                }
            }
            let nextLecture: any = null;
            if(currentLecture == null && this._lectures.length > 0) {
                for (var index = 0; index < this._lectures.length; index++) {
                    var lecture = this._lectures[index];
                    if(lecture.startTime > momentDate.format('HH:mm:ss')) {
                        nextLecture = lecture;
                        break;
                    }
                }
            }
            this.setState({
                currentLecture,
                nextLecture
            })
        })
        .catch(error => {
            if (error.status >= 400) {
                window.location.href = `/plataforma/${this.state.eventId}/hall-entrada`
            }
        })

        this.orientationChangeListener = window.addEventListener("resize", (event: any) => {
            if(event !== null) {
                this.setState({
                    orientationAngle: event.target.innerHeight/event.target.innerWidth > 1 ? 'portrait' : 'landscape'
                })
            }
        });

        setInterval(() => {
            const momentDate = moment(new Date());
            this.setState({
                date: momentDate.format('dddd, DD [de] MMMM [de] YYYY, HH:mm')
            })
            if(!this.state.currentLecture || (this.state.currentLecture && (this.state.currentLecture.endTime < momentDate.format('HH:mm:ss')))) {
                let currentLecture = null;
                for (var index = 0; index < this._lectures.length; index++) {
                    var lecture = this._lectures[index];
                    if(lecture.startTime <= momentDate.format('HH:mm:ss') && lecture.endTime > momentDate.format('HH:mm:ss')) {
                        currentLecture = lecture;
                        this._lectures.splice(index,1);
                        break;
                    }
                }
                let nextLecture: any = null;
                if(currentLecture == null && this._lectures.length > 0) {
                    for (var index = 0; index < this._lectures.length; index++) {
                        var lecture = this._lectures[index];
                        if(lecture.startTime > momentDate.format('HH:mm:ss')) {
                            nextLecture = lecture;
                            break;
                        }
                    }
                }
                this.setState({
                    currentLecture,
                    nextLecture
                })
            }
        }, 5000)
    }

    renderScreen() {
        this.state.currentLecture.isRenderIframe = true;
        if(this.state.currentLecture.videoId.includes('iframe')){
            this.state.currentLecture.videoLabel = 'iframe';
            this.state.currentLecture.isRenderIframe = false;
            this.state.currentLecture.videoId = {__html: this.state.currentLecture.videoId};
        } else if(this.state.currentLecture.videoId.includes('youtube')) {
            this.state.currentLecture.videoId = this.state.currentLecture.videoId.split('&')[0].replace('watch?v=', 'embed/');
            this.state.currentLecture.videoLabel = 'youtube';
        } else if(this.state.currentLecture.videoId.includes('vimeo')){
            this.state.currentLecture.videoId = this.state.currentLecture.videoId.split('&')[0].replace('https://vimeo.com/','//player.vimeo.com/video/');
            this.state.currentLecture.videoLabel = 'vimeo';
        }
        return this.state.currentLecture.videoLabel !== 'iframe' ? <iframe 
            id="iframe_pop_video"
            src={`${this.state.currentLecture.videoId}?autoplay=1&byline=0&sidedock=0&portrait=0&api=1&mute=0&player_id=iframe_pop_video`}
            width="100%" 
            height="100%" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen={true}>
        </iframe>
        :
        <div dangerouslySetInnerHTML={this.state.currentLecture.videoId}/>
    }

    goBack() {
        this.props.history.goBack();
    }
    render() {
        const splitStartedDate = this.state.nextLecture ? this.state.nextLecture.startTime.split(':') : null;
        let formatedStartTime = null;
        if(splitStartedDate) {
            splitStartedDate.pop();
            formatedStartTime = splitStartedDate.join(':');
        }
        return (
            <div id="palestra-evento">  
                <section className="container-palestra" 
                    style={{backgroundImage: `url(${this.state.orientationAngle == 'landscape' ? LectureEvento : LectureEventoMobile})`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                                <div className={`tela-palestra ${
                                    (this.state.currentLecture && this.state.currentLecture.isRenderIframe) ? '-with-iframe' : ''
                                }`}>
                                    {this.state.currentLecture ? 
                                        this.renderScreen()
                                    :
                                        this.state.nextLecture ? 
                                            <p className="hour">{this.props.appText.FEEDBACK_TODAY_NEXT_LECTURE}<br/>{this.state.nextLecture.name + ' - '+ formatedStartTime}</p> 
                                        : 
                                            <p className="hour">{this.props.appText.FEEDBACK_TODAY_NOT_LECTURE}</p>}
                                </div>
                        </div>
                        <div className={'turn-back'} onClick={() => this.props.history.goBack()}>
                            <img src={ArrowDown} className={'fa'}/>
                            <p>{this.props.appText.LABEL_BACK}</p>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

export default connect(mapStateToProps)(withRouter(LecturePage));