
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";


export default class ClientService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    login(data: any) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.USER.LOGIN, data).then((responseData) => {
            return responseData.data;
        })
    }

    register(data: any) {
        return this._apiService.post(Configs.ENDPOINTS.USERS, data).then((responseData) => {
            return responseData;
        })
    }

    sendAuthToken(data) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.USER.SEND_AUTH_TOKEN, data).then((responseData) => {
            return responseData.data;
        })
    }

    logout() {
        return this._apiService.get(Configs.ENDPOINTS.AUTH.USER.LOGOUT)
        .then((response) => {
            return response.data;
        });
    };

    recoverPassword(data: any) {
        return this._apiService
            .post(Configs.ENDPOINTS.AUTH.USER.RECOVER_PASSWORD, data)
            .then(response => response.data)
    }

    changePassword(data: any) {
        return this._apiService
            .post(Configs.ENDPOINTS.AUTH.USER.CHANGE_PASSWORD, data)
            .then(response => response.data)
    }

    // checkIfAuthenticated() {
    //     return this._apiService.get(Configs.ENDPOINTS.AUTH.IS_AUTHENTICATED)
    //         .then(response => {
    //             return response.data;
    //         })
    // };
}
