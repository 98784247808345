import axios from 'axios';
// import Qs from 'qs';
import Configs from "../../Utils/configs.env";
// import { showToast } from "../../Redux/actions/root.actions";
// import { Store } from "redux";

type DataBody = { [key: string]: any };

enum MethodInterface {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

const api = axios.create({
  baseURL: Configs.BASE_URL
});

export default class ApiService {

  rawurlencode(str: any){
    str = (str + '')
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A');
  }

  public get(endpoint = "", data: DataBody = {}) {
    return this._request(MethodInterface.GET, endpoint, data);
  }
  public externalGet(endpoint) {
    return axios.get(endpoint, {headers: {"Access-Control-Allow-Origin": "*"}}).then((response) => {
      const responseData = response.data;
      return responseData;
    });
  }

  public post(endpoint = "", data: DataBody = {}, isFormData?) {
    return this._request(MethodInterface.POST, endpoint, data, isFormData);
  }

  public put(endpoint = "", data: DataBody = {}, isFormData?) {
    return this._request(MethodInterface.PUT, endpoint, data, isFormData);
  }
  public del(endpoint = "", data: DataBody = {}) {
    return this._request(MethodInterface.DELETE, endpoint, data);
  }

  private async _request(method: MethodInterface, endpoint: string, data: any = {}, isFormData = false) {
    let authorization: undefined | string = undefined;
    if (endpoint.includes('/site/')) {
      const token = localStorage.getItem('JWT_TOKEN_CLIENT');
      if(token) {
        authorization = token;
      }
    } else {
      const token = localStorage.getItem('JWT_TOKEN_USER');
      if(token) {
        authorization = token;
      }
    }
    let options: DataBody = {
      url: endpoint,
      method: method,
      params: data,
      headers: {
        Accept: '*/*',
        credentials: 'same-origin',
        'AcceptEncoding': 'gzip, deflate, br',
        Connection: 'keep-alive'
      },
    };
    if(authorization){
      options.headers.Authorization = authorization;
    }
    if(method !== MethodInterface.GET) {
      if(isFormData){
        options.data = options.params;
        options.headers['ContentType'] = 'multipart/form-data; charset=utf-8; boundary=' +options.params._boundary;
      } else {
        options.data = options.params;
        options.headers['ContentType'] = 'application/x-www-form-urlencoded; charset=uft-8';
      }
      delete options.params;
    }
    return api(options)
    .then((response: any) => {
      const responseData = response.data;
      return responseData;

    })
    .catch((error: any) => {
      throw error.response ? error.response.data : error;
    });
  }
}