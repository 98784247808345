import React from 'react';


const i18n = {
    PT: {
        LABEL_HEADER_CREATE_ACCOUNT: 'Criar Conta',
        LABEL_HEADER_EDIT_PROFILE: 'Editar Perfil',
        LABEL_HEADER_MY_EVENTS: 'Meus eventos',

        LABEL_FOOTER_BE_A_PARTNER: 'Seja um Parceiro',

        LABEL_CLOSE: 'Fechar',
        LABEL_LOGOUT: 'Sair',
        LABEL_BACK: 'Voltar',

        LABEL_ABOUT_US_HEADER: 'Quem Somos',
        LABEL_ABOUT_US_TITLE: 'Lorem Ipsum',
        LABEL_ABOUT_US_TEXT: `Lorem Ipsum is simply
                                    dummy text of the , Lorem Ipsum is simply
                                    dummy text of the, Lorem Ipsum is simplyLorem Ipsum is simply
                                    dummy text of the Lorem Ipsum is simply
                                    dummy text of the Lorem Ipsum is simply
                                    dummy text of the
                                    dummy text of the`,
        LABEL_ABOUT_US_MIDDLE_TITLE: 'Lorem Ipsum is simply dummy text of the',

        LABEL_CONTACT_HEADER: 'Fale Conosco',
        LABEL_CONTACT_WHERE: 'Onde Estamos',
        LABEL_CONTACT_CALL: 'Entre em Contato',
        LABEL_CONTACT_SUBJECT: 'Assunto',
        LABEL_CONTACT_SUBJECT_CHOOSE: 'Selecione um assunto',
        LABEL_CONTACT_SUBJECT_DOUBTS: 'Dúvidas',
        LABEL_CONTACT_SUBJECT_SUGGESTION: 'Sugestões',
        LABEL_CONTACT_SUBJECT_BE_A_PARTNER: 'Seja um Parceiro',
        LABEL_CONTACT_SUBJECT_OTHERS: 'Outros',
        LABEL_CONTACT_SEND_MESSAGE: 'Enviar Mensagem',
        LABEL_CONTACT_LOCATION: 'Nossa Localização',

        LABEL_SEARCH_HEADER: 'Buscar Eventos',
        LABEL_SEARCH_CATEGORY_ALL: 'Todas as categorias',
        LABEL_SEARCH: 'Buscar',

        LABEL_REGISTER_HEADER: 'Cadastro',
        LABEL_REGISTER_SUCCESS: 'Usuário cadastrado com sucesso!',
        LABEL_REGISTER_DESCRIPTION: 'Por favor, preencha todos os campos abaixo:',
        LABEL_REGISTER_PASSWORD_TIP_1: <span>- Deve ser compostos por no minimo <strong>8 caracteres</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_2: <span>- Deve ter ao menos um <strong>caractere especial</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_3: <span>- Deve ser <strong>alfanumerico</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_4: <span>- Deve ter ao um caracter <strong>maiusculo</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_5: <span>- Deve ter ao um caracter <strong>minusculo</strong></span>,

        LABEL_REGISTER_FINISHED_HEADER: 'Seja Bem Vindo',
        LABEL_REGISTER_FINISHED_TEXT: 'Seu cadastro foi realizado com sucesso!',
        LABEL_ACCESS_EVENTS: 'Acessar Eventos',
        LABEL_ACCESS_EVENT: 'Acessar Evento',

        LABEL_TERMS_HEADER: 'Termos de Uso',
        LABEL_TERMS_QUESTION_1: 'What is Lorem Ipsum?',
        LABEL_TERMS_ANSWER_1: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                It has survived not only five centuries, but also the leap into electronic typesetting, 
                                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                 sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
                                 like Aldus PageMaker including versions of Lorem Ipsum`,

        LABEL_PROFILE_HEADER: 'Dados pessoais',
        LABEL_PROFILE_CHANGE_PASS: 'Mudar Senha',
        LABEL_PROFILE_CHANGE_EMAIL_WARNING: 'Quer modificar o email?',
        LABEL_PROFILE_CALL: 'Entre em contato',
        LABEL_PROFILE_SUCCESS: 'Perfil atualizado com sucesso!',

        LABEL_MY_ORDERS_HEADER: 'Meus Pedidos',
        LABEL_MY_EVENTS_HEADER: 'Meus Pedidos',
        LABEL_MY_EVENTS_EMPTY_EVENT_TEXT: <span>Você ainda não comprou nenhum evento, mas <span className={'highlight'}>veja alguns eventos que podem te interessar...</span></span>,

        LABEL_FULLNAME: 'Nome Completo',
        LABEL_EMAIL: 'E-mail',
        LABEL_PASSWORD: 'Senha',
        LABEL_PASSWORD_CONFIRM: 'Confirmar Senha',
        LABEL_AUTH_CODE: 'Código de autenticação',
        LABEL_CEP: 'CEP',
        LABEL_ADDRESS: 'Endereço',
        LABEL_ESTATE: 'Estado',
        LABEL_CITY: 'Cidade',
        LABEL_CPF: 'CPF',
        LABEL_DDI: 'ddi',
        LABEL_PHONE: 'Telefone',
        LABEL_ROLE: 'Nível de permissão',
        LABEL_PRICE: 'Valor',
        LABEL_DISCOUNT: 'Desconto',
        LABEL_INSTALLMENTS: 'Parcelamento',
        LABEL_EXCHANGE: 'Taxa de Câmbio',
        LABEL_START_DATE: 'Data de início',
        LABEL_END_DATE: 'Data de término',
        LABEL_FACEBOOK: 'Perfil do Facebook',
        LABEL_INSTAGRAM: 'Perfil do Instagram',
        LABEL_YOUTUBE: 'Perfil do Youtube',
        LABEL_TWITTER: 'Perfil do Twitter',
        LABEL_LINKEDIN: 'Perfil do LinkedIn',
        LABEL_FEATURED: 'Evento em destaque',
        LABEL_OPEN: 'Aberto',
        LABEL_CLOSED: 'Fechado',
        LABEL_DESCRIPTION: 'Descrição',
        LABEL_EVENT_NAME: 'Nome do evento',
        LABEL_EVENT_DESCRIPTION: 'Descrição do evento',
        LABEL_CATEGORY_NAME: 'Nome da categoria',
        LABEL_CATEGORY_DESCRIPTION: 'Descrição da categoria',
        LABEL_ROOM_NAME: 'Nome da Sala',
        LABEL_ROOM_DESCRIPTION: 'Descrição da sala',
        LABEL_CALL_TO_ACTION_LABEL: 'Texto do botão',
        LABEL_CALL_TO_ACTION: 'Link de destino',
        LABEL_MEDIA_NAME: 'Nome do arquivo',
        LABEL_MEDIA_DESCRIPTION: 'Descrição do arquivo',
        LABEL_PRODUCTS: 'Produtos',
        LABEL_PRODUCT_TITLE: 'Nome do produto/Serviço',
        LABEL_PRODUCT_DESCRIPTION: 'Descrição do produto/serviço',
        LABEL_STAND_NAME: 'Nome do estande',
        LABEL_STAND_SUBTITLE: 'Subtítulo do estande',
        LABEL_STAND_DESCRIPTION: 'Descrição do estande',
        LABEL_SPONSORS: 'Patrocinadores',
        LABEL_SUPPORTERS: 'Apoiadores',
        LABEL_SPONSOR_NAME: 'Nome do patrocinador',
        LABEL_COUPON_CODE: 'Código do cupom',
        LABEL_COUPON_VALUE: (currencyLabel) => `Valor do desconto (em ${currencyLabel})`,
        LABEL_COUPON_PERCENT: 'Porcentagem de desconto (em %)',
        LABEL_COUPON_AMOUNT: 'Número de cupons disponíveis',
        LABEL_COUPON_VALIDADE_DATE: 'Data de expiração do cupom',
        LABEL_SPONSOR_NAME_PLACEHOLDER: 'Preencha com o nome da patrocinador',
        LABEL_LECTURE_NAME: 'Nome da palestra',
        LABEL_LECTURE_DESCRIPTION: 'Descrição da palestra',
        LABEL_SPEAKER_NAME: 'Nome do Palestrante',
        LABEL_SPEAKER_TITLE: 'Título do Palestrante',
        LABEL_SPEAKER_DESCRIPTION: 'Descrição do Palestrante',
        LABEL_GO_TO_SITE: 'ir para o site',
        LABEL_FORGOT_MY_PASSWORD: 'esqueci minha senha',
        LABEL_SEND: 'Enviar',
        LABEL_REGISTER: 'Cadastrar',
        LABEL_LOGIN: 'Entrar',
        LABEL_LOGGING_IN: 'entrando',
        LABEL_CODE_VALIDATE: 'Validar Código',
        LABEL_DOING_CODE_VALIDATE: 'Validando',
        LABEL_SAVE: 'Salvar',
        LABEL_ADD: 'Adicionar',
        LABEL_ADD_USER: 'Adicionar usuário',
        LABEL_SAVE_STAY: 'Salvar e continuar',
        LABEL_UPDATE: 'atualizar',
        LABEL_UPDATE_PROFILE: 'Atualizar dados',
        LABEL_UPDATING: 'atualizando',
        LABEL_UPDATE_STAY: 'atualizar e continuar',
        LABEL_START_TIME: 'Horário de início',
        LABEL_LECTURE_DATE: 'Data da palestra',
        LABEL_END_TIME: 'Horário de término',
        LABEL_CATEGORY: 'Categoria',
        LABEL_ROOM: 'Sala',
        LABEL_REMOVE: 'Remover',
        LABEL_CARD_NUMBER: 'Número do cartão',
        LABEL_CARD_NANE: 'Nome impresso no cartão',
        LABEL_CARD_VALIDATE: 'Data de validade do cartão',
        LABEL_CARD_VERIFICATION_CODE: 'Código de verificação',
        LABEL_SOCIAL_MEDIAS: 'Redes Sociais',
        LABEL_SELECT_A_OPTION: 'Selecione uma opção',
        LABEL_EVENTS_FOR_YOU: <span>Eventos <b> Para Você </b></span>,

        LABEL_SPONSOR_TYPE: 'Tipo de Patrocinador',
        LABEL_MASTER: 'Master',
        LABEL_PREMIUM: 'Premium',
        LABEL_SUPPORTER: 'Apoiador',

        LABEL_EVENT_INFO: 'Informações do Evento',
        LABEL_EVENT_START_HOUR: 'Horário de Início',
        LABEL_DO_A_SHARE: 'Compartilhe',
        LABEL_EVENT_BUYED: 'Evento Comprado',
        LABEL_BUY_EVENT: 'Comprar Evento',
        LABEL_BUY_LECTURE: 'Comprar Palestra',
        LABEL_PERFORM_ACCREDITATION: 'Realizar Credenciamento',
        LABEL_WAIT_ACCREDITATION: 'Aguarde Credenciamento',
        LABEL_EVENT_SOCIAL_MEDIAS: 'Redes sociais do evento',
        LABEL_EVENT_LECTURES: 'Palestras',
        LABEL_EVENT_SPEAKERS: 'Palestrante(s)',

        FORM_NAME_PLACEHOLDER: 'Preencha com seu nome completo',
        FORM_EMAIL_PLACEHOLDER: 'Preencha com seu email',
        FORM_CPF_PLACEHOLDER: 'Preencha com seu CPF (somente números)',
        FORM_CEP_PLACEHOLDER: 'Preencha com seu CEP (somente números)',
        FORM_DDI_PLACEHOLDER: 'DDI',
        FORM_PHONE_PLACEHOLDER: 'Telefone (somente números)',
        FORM_ADDRESS_PLACEHOLDER: 'Preencha com seu endereço completo',
        FORM_PASSWORD_PLACEHOLDER: 'Digite sua senha',
        FORM_CONFIRM_PASSWORD_PLACEHOLDER: 'Digite novamente sua senha',

        FORM_FACEBOOK_PLACEHOLDER: 'Ex.: https://www.facebook.com/HoffmannBr',
        FORM_TWITTER_PLACEHOLDER: 'Ex.: https://twitter.com/usuario',
        FORM_LINKEDIN_PLACEHOLDER: 'Ex.: https://www.linkedin.com/company/hoffmann/',

        FORM_CATEGORY_PLACEHOLDER: 'preencha com o nome da categoria',
        FORM_CATEGORY_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da categoria',

        FORM_MFACODE_PLACEHOLDER: 'digite o código de 4 digitos recebido por SMS',

        FORM_EVENT_NAME_PLACEHOLDER: 'preencha com o nome do evento',
        FORM_EVENT_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do evento',
        FORM_EVENT_START_DATE_PLACEHOLDER: 'defina a data de início',
        FORM_EVENT_END_DATE_PLACEHOLDER: 'defina a data de término',
        FORM_EVENT_START_TIME_PLACEHOLDER: 'defina o horário de início',
        FORM_EVENT_END_TIME_PLACEHOLDER: 'defina o horário de término',
        FORM_EVENT_FACEBOOK_PLACEHOLDER: 'digite a URL do perfil do Facebook',
        FORM_EVENT_INSTAGRAM_PLACEHOLDER: 'digite a URL do perfil do Instagram',
        FORM_EVENT_YOUTUBE_PLACEHOLDER: 'digite a URL do perfil do Youtube',
        FORM_EVENT_TWITTER_PLACEHOLDER: 'digite a URL do perfil do Twitter',
        FORM_EVENT_LINKEDIN_PLACEHOLDER: 'digite a URL do perfil do LinkedIn',
        FORM_EVENT_WEBSITE_PLACEHOLDER: 'digite a URL do Site Principal',
        FORM_PRICE_PLACEHOLDER: 'digite o valor da entrada do evento',
        FORM_DISCOUNT_PLACEHOLDER: 'digite o valor do desconto',
        FORM_INSTALLMENTS_PLACEHOLDER: 'Digite o número de parcelas possíveis',
        FORM_EXCHANGE_PLACEHOLDER: 'Digite a taxa de câmbio',

        FORM_ROOM_NAME_PLACEHOLDER: 'preencha com o nome da sala',
        FORM_ROOM_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da sala',
        FORM_MEDIA_NAME_PLACEHOLDER: 'preencha com o nome do arquivo',
        FORM_MEDIA_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do arquivo',
        FORM_PRODUCT_TITLE_PLACEHOLDER: 'preencha com o título do produto/serviço',
        FORM_PRODUCT_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do produto/serviço',
        FORM_STAND_NAME_PLACEHOLDER: 'preencha com o nome do estande',
        FORM_STAND_SUBTITLE_PLACEHOLDER: 'preencha com um complemento do título',
        FORM_STAND_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do estande',

        FORM_CALL_TO_ACTION_LABEL_PLACEHOLDER: 'preencha com o texto que aparecerá no botão',
        FORM_CALL_TO_ACTION_PLACEHOLDER: 'preencha com uma url(completa) de destino',

        FORM_LECTURE_NAME_PLACEHOLDER: 'preencha com o nome da palestra',
        FORM_LECTURE_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da palestra',

        FORM_SPEAKER_NAME_PLACEHOLDER: 'preencha com o nome do(a) palestrante',
        FORM_SPEAKER_TITLE_PLACEHOLDER: 'Ex.: Bacharel em Direito',
        FORM_SPEAKER_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do(a) palestrante',

        FORM_COUPON_CODE_PLACEHOLDER: 'preencha com o código (sem espaços e somente letras maiuscula)',
        FORM_COUPON_VALUE_PLACEHOLDER: 'preencha somente com números',
        FORM_COUPON_PERCENT_PLACEHOLDER: 'porcentagem aplicada ao valor inicial',
        FORM_COUPON_AMOUNT_PLACEHOLDER: 'preencha com o número de cupons disponíveis',
        FORM_COUPON_VALIDADE_DATE_PLACEHOLDER: 'Selecione a data de validade do cupom',
        FORM_CARD_NUMBER_PLACEHOLDER: 'Insira o número do seu cartão (somente os números)',
        FORM_CARD_NAME_PLACEHOLDER: 'Insira o nome que está impresso no cartão',
        FORM_CARD_VALIDATE_PLACEHOLDER: 'Insira a data de validade impressa do cartão',
        FORM_CARD_VERIFICATION_CODE_PLACEHOLDER: 'Insira a código de verificação',

        CHAT_TITLE: 'Como podemos ajudá-lo?',
        CHAT_SUBTITLE: 'Tire suas dúvidas sobre este evento com um de nossos atendentes',
        CHAT_SEND_PLACEHOLDER: 'Digite sua mensagem...',
        CHAT_FIRST_MESSAGE: 'Olá! Nos diga qual é a sua dúvida.',

        LABEL_VISIT_HOFFMANN_FACEBOOK: 'Visitar a Hoffman no Facebook',
        LABEL_VISIT_HOFFMANN_INSTAGRAM: 'Visitar a Hoffman no Instagram',
        LABEL_VISIT_HOFFMANN_VIMEO: 'Visitar a Hoffman no Vimeo',
        LABEL_VISIT_HOFFMANN_LINKEDIN: 'Visitar a Hoffman no Linkedin',

        LABEL_EVENT_FACEBOOK: 'Página do evento no Facebook',
        LABEL_EVENT_TWITTER: 'Página do evento no Twitter',
        LABEL_EVENT_LINKEDIN: 'Página do evento no Linkedin',

        LABEL_NETWORK_HEADER: 'Network',
        LABEL_NETWORK_TEXT: (eventName) => `Veja as pessoas que estão presentes no evento ${eventName} e interaja com elas`,
        LABEL_NETWORK_SEARCH_PLACEHOLDER: 'Procure por alguém que esteja nesse evento',
        LABEL_NETWORK_FACEBOOK: 'Página página no Facebook',
        LABEL_NETWORK_TWITTER: 'Página página no Twitter',
        LABEL_NETWORK_LINKEDIN: 'Página página no Linkedin',

        LABEL_INSIDE_MENU_STANDS_HALL: 'Hall de Estandes',
        LABEL_INSIDE_MENU_LECTURES: 'Palestras',
        LABEL_INSIDE_MENU_NETWORK: 'Network',

        LABEL_INSIDE_KEEP_WALKING: 'Continuar andando',
        LABEL_INSIDE_STAND_UNDER_CONSTRUCTION: 'Estande em construção',
        LABEL_INSIDE_EXPLORE_STAND: 'Explorar estande',
        LABEL_INSIDE_PHOTOS: 'Fotos',
        LABEL_INSIDE_VIDEOS: 'Vídeos',

        LABEL_CHECKOUT_ENTRANCES: 'Ingressos',
        LABEL_CHECKOUT_EMPTY_LIST: 'Carrinho Vazio',
        LABEL_CHECKOUT_DISCOUNT_CUPON: 'Cupom de Desconto',
        LABEL_CHECKOUT_DISCOUNT_VALUE: 'Valor Desconto',
        LABEL_CHECKOUT_TOTAL_VALUE: 'Valor Total',
        LABEL_CHECKOUT_PERCENT_DISCOUNT: 'de desconto',
        LABEL_CHECKOUT_DISCOUNT_APPLY: 'Aplicar',
        LABEL_CHECKOUT_MAX_INSTALLMENT: 'até',
        LABEL_CHECKOUT_PERSONAL_DATA_TITLE: 'Dados Pessoais para cobrança',
        LABEL_CHECKOUT_PAYMENT_DATA_TITLE: 'Dados Pagamento',
        LABEL_CHECKOUT_INSTALLMENT: 'Parcelamento',
        LABEL_CHECKOUT_CONGRATS_TITLE: 'Compra Realizada com Sucesso !',
        LABEL_CHECKOUT_CONGRATS_DESC: 'Você irá receber todos os dados no seu e-mail',
        LABEL_CHECKOUT_ORDER_NUMBER: 'Número da compra',
        LABEL_CHECKOUT_KEEP_BUYING: 'Continuar Comprando',
        LABEL_CHECKOUT_FINISH: 'Finalizar Compra',
        
    },
    en: {
        LABEL_HEADER_CREATE_ACCOUNT: 'Criar Conta',
        LABEL_HEADER_EDIT_PROFILE: 'Editar Perfil',
        LABEL_HEADER_MY_EVENTS: 'Meus eventos',

        LABEL_FOOTER_BE_A_PARTNER: 'Seja um Parceiro',

        LABEL_CLOSE: 'Fechar',
        LABEL_LOGOUT: 'Sair',
        LABEL_BACK: 'Voltar',

        LABEL_ABOUT_US_HEADER: 'Quem Somos',
        LABEL_ABOUT_US_TITLE: 'Lorem Ipsum',
        LABEL_ABOUT_US_TEXT: `Lorem Ipsum is simply
                                    dummy text of the , Lorem Ipsum is simply
                                    dummy text of the, Lorem Ipsum is simplyLorem Ipsum is simply
                                    dummy text of the Lorem Ipsum is simply
                                    dummy text of the Lorem Ipsum is simply
                                    dummy text of the
                                    dummy text of the`,
        LABEL_ABOUT_US_MIDDLE_TITLE: 'Lorem Ipsum is simply dummy text of the',

        LABEL_CONTACT_HEADER: 'Fale Conosco',
        LABEL_CONTACT_WHERE: 'Onde Estamos',
        LABEL_CONTACT_CALL: 'Entre em Contato',
        LABEL_CONTACT_SUBJECT: 'Assunto',
        LABEL_CONTACT_SUBJECT_CHOOSE: 'Selecione um assunto',
        LABEL_CONTACT_SUBJECT_DOUBTS: 'Dúvidas',
        LABEL_CONTACT_SUBJECT_SUGGESTION: 'Sugestões',
        LABEL_CONTACT_SUBJECT_BE_A_PARTNER: 'Seja um Parceiro',
        LABEL_CONTACT_SUBJECT_OTHERS: 'Outros',
        LABEL_CONTACT_SEND_MESSAGE: 'Enviar Mensagem',
        LABEL_CONTACT_LOCATION: 'Nossa Localização',

        LABEL_SEARCH_HEADER: 'Buscar Eventos',
        LABEL_SEARCH_CATEGORY_ALL: 'Todas as categorias',
        LABEL_SEARCH: 'Buscar',

        LABEL_REGISTER_HEADER: 'Cadastro',
        LABEL_REGISTER_SUCCESS: 'Usuário cadastrado com sucesso!',
        LABEL_REGISTER_DESCRIPTION: 'Por favor, preencha todos os campos abaixo:',
        LABEL_REGISTER_PASSWORD_TIP_1: <span>- Deve ser compostos por no minimo <strong>8 caracteres</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_2: <span>- Deve ter ao menos um <strong>caractere especial</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_3: <span>- Deve ser <strong>alfanumerico</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_4: <span>- Deve ter ao um caracter <strong>maiusculo</strong></span>,
        LABEL_REGISTER_PASSWORD_TIP_5: <span>- Deve ter ao um caracter <strong>minusculo</strong></span>,

        LABEL_REGISTER_FINISHED_HEADER: 'Seja Bem Vindo',
        LABEL_REGISTER_FINISHED_TEXT: 'Seu cadastro foi realizado com sucesso!',
        LABEL_ACCESS_EVENTS: 'Acessar Eventos',
        LABEL_ACCESS_EVENT: 'Acessar Evento',

        LABEL_TERMS_HEADER: 'Termos de Uso',
        LABEL_TERMS_QUESTION_1: 'What is Lorem Ipsum?',
        LABEL_TERMS_ANSWER_1: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                It has survived not only five centuries, but also the leap into electronic typesetting, 
                                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                                 sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
                                 like Aldus PageMaker including versions of Lorem Ipsum`,

        LABEL_PROFILE_HEADER: 'Dados pessoais',
        LABEL_PROFILE_CHANGE_PASS: 'Mudar Senha',
        LABEL_PROFILE_CHANGE_EMAIL_WARNING: 'Quer modificar o email?',
        LABEL_PROFILE_CALL: 'Entre em contato',
        LABEL_PROFILE_SUCCESS: 'Perfil atualizado com sucesso!',

        LABEL_MY_ORDERS_HEADER: 'Meus Pedidos',
        LABEL_MY_EVENTS_HEADER: 'Meus Pedidos',
        LABEL_MY_EVENTS_EMPTY_EVENT_TEXT: <span>Você ainda não comprou nenhum evento, mas <span className={'highlight'}>veja alguns eventos que podem te interessar...</span></span>,

        LABEL_FULLNAME: 'Nome Completo',
        LABEL_EMAIL: 'E-mail',
        LABEL_PASSWORD: 'Senha',
        LABEL_PASSWORD_CONFIRM: 'Confirmar Senha',
        LABEL_AUTH_CODE: 'Código de autenticação',
        LABEL_CEP: 'CEP',
        LABEL_ADDRESS: 'Endereço',
        LABEL_ESTATE: 'Estado',
        LABEL_CITY: 'Cidade',
        LABEL_CPF: 'CPF',
        LABEL_DDI: 'ddi',
        LABEL_PHONE: 'Telefone',
        LABEL_ROLE: 'Nível de permissão',
        LABEL_PRICE: 'Valor',
        LABEL_DISCOUNT: 'Desconto',
        LABEL_INSTALLMENTS: 'Parcelamento',
        LABEL_START_DATE: 'Data de início',
        LABEL_END_DATE: 'Data de término',
        LABEL_FACEBOOK: 'Perfil do Facebook',
        LABEL_TWITTER: 'Perfil do Twitter',
        LABEL_LINKEDIN: 'Perfil do LinkedIn',
        LABEL_FEATURED: 'Evento em destaque',
        LABEL_OPEN: 'Aberto',
        LABEL_CLOSED: 'Fechado',
        LABEL_DESCRIPTION: 'Descrição',
        LABEL_EVENT_NAME: 'Nome do evento',
        LABEL_EVENT_DESCRIPTION: 'Descrição do evento',
        LABEL_CATEGORY_NAME: 'Nome da categoria',
        LABEL_CATEGORY_DESCRIPTION: 'Descrição da categoria',
        LABEL_ROOM_NAME: 'Nome da Sala',
        LABEL_ROOM_DESCRIPTION: 'Descrição da sala',
        LABEL_CALL_TO_ACTION_LABEL: 'Texto do botão',
        LABEL_CALL_TO_ACTION: 'Link de destino',
        LABEL_MEDIA_NAME: 'Nome do arquivo',
        LABEL_MEDIA_DESCRIPTION: 'Descrição do arquivo',
        LABEL_PRODUCTS: 'Produtos',
        LABEL_PRODUCT_TITLE: 'Nome do produto/Serviço',
        LABEL_PRODUCT_DESCRIPTION: 'Descrição do produto/serviço',
        LABEL_STAND_NAME: 'Nome do estande',
        LABEL_STAND_SUBTITLE: 'Subtítulo do estande',
        LABEL_STAND_DESCRIPTION: 'Descrição do estande',
        LABEL_SPONSORS: 'Patrocinadores',
        LABEL_SUPPORTERS: 'Apoiadores',
        LABEL_SPONSOR_NAME: 'Nome do patrocinador',
        LABEL_COUPON_CODE: 'Código do cupom',
        LABEL_COUPON_VALUE: (currencyLabel) => `Valor do desconto (em ${currencyLabel})`,
        LABEL_COUPON_PERCENT: 'Porcentagem de desconto (em %)',
        LABEL_COUPON_AMOUNT: 'Número de cupons disponíveis',
        LABEL_COUPON_VALIDADE_DATE: 'Data de expiração do cupom',
        LABEL_SPONSOR_NAME_PLACEHOLDER: 'Preencha com o nome da patrocinador',
        LABEL_LECTURE_NAME: 'Nome da palestra',
        LABEL_LECTURE_DESCRIPTION: 'Descrição da palestra',
        LABEL_SPEAKER_NAME: 'Nome do Palestrante',
        LABEL_SPEAKER_TITLE: 'Título do Palestrante',
        LABEL_SPEAKER_DESCRIPTION: 'Descrição do Palestrante',
        LABEL_GO_TO_SITE: 'ir para o site',
        LABEL_FORGOT_MY_PASSWORD: 'esqueci minha senha',
        LABEL_SEND: 'Enviar',
        LABEL_REGISTER: 'Cadastrar',
        LABEL_LOGIN: 'Entrar',
        LABEL_LOGGING_IN: 'entrando',
        LABEL_CODE_VALIDATE: 'Validar Código',
        LABEL_DOING_CODE_VALIDATE: 'Validando',
        LABEL_SAVE: 'Salvar',
        LABEL_ADD: 'Adicionar',
        LABEL_ADD_USER: 'Adicionar usuário',
        LABEL_SAVE_STAY: 'Salvar e continuar',
        LABEL_UPDATE: 'atualizar',
        LABEL_UPDATE_PROFILE: 'Atualizar dados',
        LABEL_UPDATING: 'atualizando',
        LABEL_UPDATE_STAY: 'atualizar e continuar',
        LABEL_START_TIME: 'Horário de início',
        LABEL_LECTURE_DATE: 'Data da palestra',
        LABEL_END_TIME: 'Horário de término',
        LABEL_CATEGORY: 'Categoria',
        LABEL_ROOM: 'Sala',
        LABEL_REMOVE: 'Remover',
        LABEL_CARD_NUMBER: 'Número do cartão',
        LABEL_CARD_NANE: 'Nome impresso no cartão',
        LABEL_CARD_VALIDATE: 'Data de validade do cartão',
        LABEL_CARD_VERIFICATION_CODE: 'Código de verificação',
        LABEL_SOCIAL_MEDIAS: 'Redes Sociais',
        LABEL_SELECT_A_OPTION: 'Selecione uma opção',
        LABEL_EVENTS_FOR_YOU: <span>Eventos <b> Para Você </b></span>,

        LABEL_SPONSOR_TYPE: 'Tipo de Patrocinador',
        LABEL_MASTER: 'Master',
        LABEL_PREMIUM: 'Premium',
        LABEL_SUPPORTER: 'Apoiador',

        LABEL_EVENT_INFO: 'Informações do Evento',
        LABEL_EVENT_START_HOUR: 'Horário de Início',
        LABEL_DO_A_SHARE: 'Compartilhe',
        LABEL_EVENT_BUYED: 'Evento Comprado',
        LABEL_BUY_EVENT: 'Comprar Evento',
        LABEL_BUY_LECTURE: 'Comprar Palestra',
        LABEL_PERFORM_ACCREDITATION: 'Realizar Credenciamento',
        LABEL_WAIT_ACCREDITATION: 'Aguarde Credenciamento',
        LABEL_EVENT_SOCIAL_MEDIAS: 'Redes sociais do evento',
        LABEL_EVENT_LECTURES: 'Palestras',
        LABEL_EVENT_SPEAKERS: 'Palestrante(s)',

        FORM_NAME_PLACEHOLDER: 'Preencha com seu nome completo',
        FORM_EMAIL_PLACEHOLDER: 'Preencha com seu email',
        FORM_CPF_PLACEHOLDER: 'Preencha com seu CPF (somente números)',
        FORM_CEP_PLACEHOLDER: 'Preencha com seu CEP (somente números)',
        FORM_DDI_PLACEHOLDER: 'DDI',
        FORM_PHONE_PLACEHOLDER: 'Telefone (somente números)',
        FORM_ADDRESS_PLACEHOLDER: 'Preencha com seu endereço completo',
        FORM_PASSWORD_PLACEHOLDER: 'Digite sua senha',
        FORM_CONFIRM_PASSWORD_PLACEHOLDER: 'Digite novamente sua senha',

        FORM_FACEBOOK_PLACEHOLDER: 'Ex.: https://www.facebook.com/HoffmannBr',
        FORM_TWITTER_PLACEHOLDER: 'Ex.: https://twitter.com/usuario',
        FORM_LINKEDIN_PLACEHOLDER: 'Ex.: https://www.linkedin.com/company/hoffmann/',

        FORM_CATEGORY_PLACEHOLDER: 'preencha com o nome da categoria',
        FORM_CATEGORY_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da categoria',

        FORM_MFACODE_PLACEHOLDER: 'digite o código de 4 digitos recebido por SMS',

        FORM_EVENT_NAME_PLACEHOLDER: 'preencha com o nome do evento',
        FORM_EVENT_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do evento',
        FORM_EVENT_START_DATE_PLACEHOLDER: 'defina a data de início',
        FORM_EVENT_END_DATE_PLACEHOLDER: 'defina a data de término',
        FORM_EVENT_START_TIME_PLACEHOLDER: 'defina o horário de início',
        FORM_EVENT_END_TIME_PLACEHOLDER: 'defina o horário de término',
        FORM_EVENT_FACEBOOK_PLACEHOLDER: 'digite a URL do perfil do Facebook',
        FORM_EVENT_TWITTER_PLACEHOLDER: 'digite a URL do perfil do Twitter',
        FORM_EVENT_LINKEDIN_PLACEHOLDER: 'digite a URL do perfil do LinkedIn',
        FORM_PRICE_PLACEHOLDER: 'digite o valor da entrada do evento',
        FORM_DISCOUNT_PLACEHOLDER: 'digite o valor do desconto',
        FORM_INSTALLMENTS_PLACEHOLDER: 'Digite o número de parcelas possíveis',

        FORM_ROOM_NAME_PLACEHOLDER: 'preencha com o nome da sala',
        FORM_ROOM_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da sala',
        FORM_MEDIA_NAME_PLACEHOLDER: 'preencha com o nome do arquivo',
        FORM_MEDIA_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do arquivo',
        FORM_PRODUCT_TITLE_PLACEHOLDER: 'preencha com o título do produto/serviço',
        FORM_PRODUCT_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do produto/serviço',
        FORM_STAND_NAME_PLACEHOLDER: 'preencha com o nome do estande',
        FORM_STAND_SUBTITLE_PLACEHOLDER: 'preencha com um complemento do título',
        FORM_STAND_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do estande',

        FORM_CALL_TO_ACTION_LABEL_PLACEHOLDER: 'preencha com o texto que aparecerá no botão',
        FORM_CALL_TO_ACTION_PLACEHOLDER: 'preencha com uma url(completa) de destino',

        FORM_LECTURE_NAME_PLACEHOLDER: 'preencha com o nome da palestra',
        FORM_LECTURE_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição da palestra',

        FORM_SPEAKER_NAME_PLACEHOLDER: 'preencha com o nome do(a) palestrante',
        FORM_SPEAKER_TITLE_PLACEHOLDER: 'Ex.: Bacharel em Direito',
        FORM_SPEAKER_DESCRIPTION_PLACEHOLDER: 'preencha com a descrição do(a) palestrante',

        FORM_COUPON_CODE_PLACEHOLDER: 'preencha com o código (sem espaços e somente letras maiuscula)',
        FORM_COUPON_VALUE_PLACEHOLDER: 'preencha somente com números',
        FORM_COUPON_PERCENT_PLACEHOLDER: 'porcentagem aplicada ao valor inicial',
        FORM_COUPON_AMOUNT_PLACEHOLDER: 'preencha com o número de cupons disponíveis',
        FORM_COUPON_VALIDADE_DATE_PLACEHOLDER: 'Selecione a data de validade do cupom',
        FORM_CARD_NUMBER_PLACEHOLDER: 'Insira o número do seu cartão (somente os números)',
        FORM_CARD_NAME_PLACEHOLDER: 'Insira o nome que está impresso no cartão',
        FORM_CARD_VALIDATE_PLACEHOLDER: 'Insira a data de validade impressa do cartão',
        FORM_CARD_VERIFICATION_CODE_PLACEHOLDER: 'Insira a código de verificação',

        CHAT_TITLE: 'Como podemos ajudá-lo?',
        CHAT_SUBTITLE: 'Tire suas dúvidas sobre este evento com um de nossos atendentes',
        CHAT_SEND_PLACEHOLDER: 'Digite sua mensagem...',
        CHAT_FIRST_MESSAGE: 'Olá! Nos diga qual é a sua dúvida.',

        LABEL_VISIT_HOFFMANN_FACEBOOK: 'Visitar a Hoffman no Facebook',
        LABEL_VISIT_HOFFMANN_INSTAGRAM: 'Visitar a Hoffman no Instagram',
        LABEL_VISIT_HOFFMANN_VIMEO: 'Visitar a Hoffman no Vimeo',
        LABEL_VISIT_HOFFMANN_LINKEDIN: 'Visitar a Hoffman no Linkedin',

        LABEL_EVENT_FACEBOOK: 'Página do evento no Facebook',
        LABEL_EVENT_TWITTER: 'Página do evento no Twitter',
        LABEL_EVENT_LINKEDIN: 'Página do evento no Linkedin',

        LABEL_NETWORK_HEADER: 'Network',
        LABEL_NETWORK_TEXT: (eventName) => `Veja as pessoas que estão presentes no evento ${eventName} e interaja com elas`,
        LABEL_NETWORK_SEARCH_PLACEHOLDER: 'Procure por alguém que esteja nesse evento',
        LABEL_NETWORK_FACEBOOK: 'Página página no Facebook',
        LABEL_NETWORK_TWITTER: 'Página página no Twitter',
        LABEL_NETWORK_LINKEDIN: 'Página página no Linkedin',

        LABEL_INSIDE_MENU_STANDS_HALL: 'Hall de Estandes',
        LABEL_INSIDE_MENU_LECTURES: 'Palestras',
        LABEL_INSIDE_MENU_NETWORK: 'Network',

        LABEL_INSIDE_KEEP_WALKING: 'Continuar andando',
        LABEL_INSIDE_STAND_UNDER_CONSTRUCTION: 'Estande em construção',
        LABEL_INSIDE_EXPLORE_STAND: 'Explorar estande',
        LABEL_INSIDE_PHOTOS: 'Fotos',
        LABEL_INSIDE_VIDEOS: 'Vídeos',

        LABEL_CHECKOUT_ENTRANCES: 'Ingressos',
        LABEL_CHECKOUT_EMPTY_LIST: 'Carrinho Vazio',
        LABEL_CHECKOUT_DISCOUNT_CUPON: 'Cupom de Desconto',
        LABEL_CHECKOUT_DISCOUNT_VALUE: 'Valor Desconto',
        LABEL_CHECKOUT_TOTAL_VALUE: 'Valor Total',
        LABEL_CHECKOUT_PERCENT_DISCOUNT: 'de desconto',
        LABEL_CHECKOUT_DISCOUNT_APPLY: 'Aplicar',
        LABEL_CHECKOUT_MAX_INSTALLMENT: 'até',
        LABEL_CHECKOUT_PERSONAL_DATA_TITLE: 'Dados Pessoais para cobrança',
        LABEL_CHECKOUT_PAYMENT_DATA_TITLE: 'Dados Pagamento',
        LABEL_CHECKOUT_INSTALLMENT: 'Parcelamento',
        LABEL_CHECKOUT_CONGRATS_TITLE: 'Compra Realizada com Sucesso !',
        LABEL_CHECKOUT_CONGRATS_DESC: 'Você irá receber todos os dados no seu e-mail',
        LABEL_CHECKOUT_ORDER_NUMBER: 'Número da compra',
        LABEL_CHECKOUT_KEEP_BUYING: 'Continuar Comprando',
        LABEL_CHECKOUT_FINISH: 'Finalizar Compra',
        
    }
}

export default i18n;
