import io from 'socket.io-client';
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

const CHECK_CONNECTION_INTERVAL_MS = 2000;

export default class ChatService {
    _socket: any;
    _checkConnectionInterval: any;

    constructor(
        private _apiService = new ApiService()
    ) {}

    getChats = (standId: number, data?: any) =>
        this._apiService
            .get(`${Configs.ENDPOINTS.STANDS}/${standId}/chats`, data)
            .then(response => response.data);

    connect = (type, standId, clientId, onIncomingMessage) => {
        this._connect(type, standId, clientId, true, onIncomingMessage);

        this._checkConnectionInterval = setInterval(() => {
            if (!this._socket.connected) {
                this._socket.disconnect();
                this._connect(type, standId, clientId, false, onIncomingMessage);
            }
        }, CHECK_CONNECTION_INTERVAL_MS);
    }

    disconnect = () => {
        this._socket.disconnect();
        clearInterval(this._checkConnectionInterval);
    }

    isConnected = () => this._socket && this._socket.connected;

    send = (message, sender) => {
        const emittedEvent = (sender === 'stand')
            ? 'chat_stand_message'
            : 'chat_client_message';
        this._socket.emit(emittedEvent, message);
    }

    _connect = (type, standId, clientId, fetchMessages, onIncomingMessage) => {
        const token = localStorage.getItem(
            type === 'client' ? 'JWT_TOKEN_CLIENT' : 'JWT_TOKEN_USER'
        )
        this._socket = io.connect(Configs.BASE_URL, { query: `token=${token}` });
        this._socket.emit('chat_open', { standId, clientId, fetchMessages });
        this._socket.on('chat_message', (message, senderType) => {
            onIncomingMessage(message, senderType);
        });
    }
}
