import { AnyAction } from "redux";
import {
  SET_USER_STATE,
  REMOVE_USER_STATE,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL
} from "../actions/auth.actions";
import { LanguageEnum } from "./root.reducer";

export interface AvatarInterface {
  large: string;
  medium: string;
  small: string;
  xsmall: string;
}

export interface UserInterface {
  address: string;
  city: string;
  email: string;
  id: string;
  name: string;
  state: string;
  roles: {
    [key: string]: boolean;
  }
  avatar: AvatarInterface;
  avatarID: number;
  since: string;
  timestamp: number;
  language: LanguageEnum;
}

export interface UserState {
  currentUser?: UserInterface;
  currentClient?: UserInterface;
  clientAuthenticated: boolean | undefined;
  userAuthenticated: boolean | undefined;
  loginModalOpened: boolean;
}

const userState: UserState = {
  currentUser: undefined,
  currentClient: undefined,
  clientAuthenticated: undefined,
  userAuthenticated: undefined,
  loginModalOpened: false
};

export default (state = userState, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_STATE:
      if(action.payload.agent == 'user') {
        return { 
          ...state,
          currentUser: action.payload.userState,
          currentClient: action.payload.userState,
          userAuthenticated: true,
          clientAuthenticated: true
        };
      } else {
        return { 
          ...state,
          currentClient: action.payload.userState,
          clientAuthenticated: true
        };
      }

    case REMOVE_USER_STATE:
      return {
        ...state,
        currentUser: null,
        currentClient: null,
        userAuthenticated: false,
        clientAuthenticated: false
      }

    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpened: true
      }
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpened: false
      }

    default:
      return state;
  }
};
