import React from 'react';
import {
    withRouter
} from "react-router-dom";

import HallRooms from '../../../../../assets/images/platform/hall-rooms.jpg';
import HallRoomsMobile from '../../../../../assets/images/platform/hall-rooms-mobile.jpg';
import ArrowRight from '../../../../../assets/images/arrowRight.png';
import ArrowLeft from '../../../../../assets/images/arrowLeft.png';

import './hall-rooms.component.scss';
import RoomService from "../../../../../Services/room/room.service";
import SponsorService from "../../../../../Services/sponsor/sponsor.service";
import EventsService from "../../../../../Services/events/events.service";
import { connect } from "react-redux";

interface HallRoomsPageProps {
    match: any;
    history: any;
    appText: any;
}
interface HallRoomsPageState {
    rooms: any[];
    banner: string;
    sponsors: any[];
    offset: number;
    animating: boolean;
    count: number;
    orientationAngle: 'portrait' | 'landscape';
}

class HallRoomsPage extends React.Component<HallRoomsPageProps, HallRoomsPageState> {
    roomService: RoomService;
    sponsorService: SponsorService;
    eventsService: EventsService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.roomService = new RoomService();
        this.sponsorService = new SponsorService();
        this.eventsService = new EventsService();
        this.state = {
            rooms: [{dummy: true},{dummy: true},{dummy: true}],
            sponsors: [{image: ''}, {image: ''}],
            offset: 0,
            count: 0,
            banner: '',
            animating: false,
            orientationAngle: window.innerHeight/window.innerWidth > 1 ? 'portrait' : 'landscape',
        }
    }

    componentDidMount() {
        this.fetchRooms();
        this.eventsService.getEvent(this.props.match.params.slug.split('-')[0], 'SITE').then((responseData) => {
            this.setState({
                banner: responseData.banner
            })
        })
        this.sponsorService.getSponsors({eventId: this.props.match.params.slug.split('-')[0]}, 'SITE').then((responseData) => {
            this.setState({
                sponsors: responseData.rows
            })
        })
        this.orientationChangeListener = window.addEventListener("resize", (event: any) => {
            if(event !== null) {
                this.setState({
                    orientationAngle: event.target.innerHeight/event.target.innerWidth > 1 ? 'portrait' : 'landscape'
                })
            }
        });
    }

    fetchRooms() {
        this.roomService.getRooms({eventId: this.props.match.params.slug.split('-')[0], offset: this.state.offset, limit: 3}, 'SITE').then((responseData) => {
            let rooms = [...this.state.rooms];
            for (var index = 0; index < rooms.length; index++) {
                if(responseData.rows[index] == undefined) {
                    rooms[index] = {number: index + 1 + this.state.offset, dummy: true};
                } else {
                    rooms[index] = {number: index + 1 + this.state.offset, ...responseData.rows[index]};
                }
            }
            this.setState({
                rooms,
                count: responseData.count
            })
            setTimeout(() => {
                this.setState({
                    animating: false
                })
            }, 1000);
        });
    }

    goToRoom(room) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/salas/${room.id}`, {
            room
        });
    }

    changeOffset(increment) {
        if(this.state.offset == 0 && increment == -3) {
            this.props.history.goBack();
        } else {
            this.setState({
                animating: true
            }, () => {
                setTimeout(() => {
                    this.setState((state) => ({
                        offset: state.offset + increment
                    }), () => this.fetchRooms());
                }, 500)
            });
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div id="hall-rooms">
                <section className={`container-hall-rooms ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(${this.state.orientationAngle == 'landscape' ? HallRooms : HallRoomsMobile})`}}
                >
                    <div className="container">
                        <div className={`row justify-content-center ${this.state.orientationAngle == 'landscape' ? '': 'floating'}`}>
                            {
                                this.state.rooms.map((room, index) => {
                                    if(this.state.orientationAngle == 'landscape') {
                                        return (
                                            <React.Fragment>
                                                <div className={`titulo${index}`}> {room.name} </div><div
                                                onClick={() => room.dummy ? null : this.goToRoom(room)}
                                                key={room.slug} 
                                                className={`sala${index} ${room.dummy ? 'sala-dummy' : ''}`}
                                                ></div>
                                            </React.Fragment>
                                        )

                                    } else {
                                        return (
                                            <div className={`list-titulo list-titulo-${index}`} key={room.slug}  onClick={() => room.dummy ? null : this.goToRoom(room)}>
                                                <span className={'m-label-sala'}>Sala {room.number}</span>
                                                <p className={'m-title-sala'}>{room.name || 'Sala desocupada'}</p>
                                            </div>
                                        )
                                    }
                                })
                            }
                            {(this.state.orientationAngle == 'portrait') ? <div className={'tvs-mob'}>
                                {this.state.sponsors[0] ? <div className="tv1 mob" style={{backgroundImage: `url(${this.state.sponsors[0].image})`}} title={this.state.sponsors[0].name}>
                                </div> : null}
                                {this.state.sponsors[1] ? <div className="tv2 mob" style={{backgroundImage: `url(${this.state.sponsors[1].image})`}} title={this.state.sponsors[1].name}>
                                </div> : null}
                            </div> : null}
                            {this.state.sponsors[0] && (this.state.orientationAngle == 'landscape') ? <div className="tv1" style={{backgroundImage: `url(${this.state.sponsors[0].image})`}} title={this.state.sponsors[0].name}>
                            </div> : null}
                            {this.state.sponsors[1] && (this.state.orientationAngle == 'landscape') ? <div className="tv2" style={{backgroundImage: `url(${this.state.sponsors[1].image})`}} title={this.state.sponsors[1].name}>
                            </div> : null}
                            <div className="banner" style={{backgroundImage: `url(${this.state.banner})`}}>
                    
                            </div>
                            {!this.state.animating ? (this.state.offset >= (this.state.count - 3) ? null : 
                                <div className={'keep-going'} onClick={() => this.changeOffset(3)}>
                                    <img src={ArrowRight} className={'fa'}/>
                                    <p>{this.props.appText.LABEL_INSIDE_KEEP_WALKING}</p>
                                </div>)
                            : null}
                            {!this.state.animating ? 
                                <div className={'turn-back'} onClick={() => this.changeOffset(-3)}>
                                    <img src={ArrowLeft} className={'fa'}/>
                                    <p>{this.props.appText.LABEL_BACK}</p>
                                </div>
                            : null}
                        </div>
                        {this.state.orientationAngle == 'landscape' ? null :
                                <div className={'mobile-controllers'}>
                                    { 
                                        <div className={'turn-back mobile'} onClick={() => this.changeOffset(-3)}>
                                            <img src={ArrowLeft} className={'fa'}/>
                                            <p>{this.props.appText.LABEL_BACK}</p>
                                        </div>
                                    }
                                    {this.state.offset >= (this.state.count - 3) ? null : 
                                    <div className={'keep-going mobile'} onClick={() => this.changeOffset(3)}>
                                        <img src={ArrowRight} className={'fa'}/>
                                        <p>{this.props.appText.LABEL_INSIDE_KEEP_WALKING}</p>
                                    </div>}
                                </div>
                            }
                    </div>
		        </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

export default connect(mapStateToProps)(withRouter(HallRoomsPage));