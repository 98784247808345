import React, { Component } from 'react';


class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <p>Pagina não encontrada</p>
            </div>
        )
    }
}

export default NotFoundPage;