import React, { Component } from 'react';
import { Widget, addResponseMessage, addUserMessage, dropMessages } from 'react-chat-widget'
import ChatService from '../../../Services/chat/chat.service'
import avatar from '../../../assets/images/dummy-chat-attendant.png'

import 'react-chat-widget/lib/styles.css'
import './chat-widget.styles.scss'
import { connect } from "react-redux";

interface ChatWidgetProps {
    clientId: number;
    standId: number;
    appText: any;
}

interface ChatWidgetState {}

class ChatWidget extends Component<ChatWidgetProps, ChatWidgetState> {
    _chatService: ChatService;

    constructor(props) {
        super(props)
        this._chatService = new ChatService()
    }

    componentDidMount = () => {
        addResponseMessage(this.props.appText.CHAT_FIRST_MESSAGE)
    }

    componentWillUnmount = () => {
        if (this._chatService.isConnected()) {
            this._chatService.disconnect()
        }
        dropMessages()
    }

    _handleIncomingMessage = (message, senderType) => {
        senderType === 'stand'
            ? addResponseMessage(message)
            : addUserMessage(message)
    }

    _onSendMessage = message => {
        if (!this._chatService.isConnected()) {
            const { standId, clientId } = this.props;
            this._chatService.connect('client', standId, clientId, this._handleIncomingMessage)
        }
        this._chatService.send(message.trim(), 'client')
    };

    render() {
        return (
            <Widget
                handleNewUserMessage={this._onSendMessage}
                profileAvatar={avatar}
                title={this.props.appText.CHAT_TITLE}
                subtitle={this.props.appText.CHAT_SUBTITLE}
                senderPlaceHolder={this.props.appText.CHAT_SEND_PLACEHOLDER}
                showTimeStamp={false}
                autoAddUserMessage={false}
            />
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return {appText};
}

export default connect(mapStateToProps)(ChatWidget);
