import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../Utils/i18n';

import './login-cms.component.scss';
import '../../../assets/styles/objects/inputs.styles.scss';
import '../../../assets/styles/objects/container.styles.scss';
import { fetchLogin, fetchSendAuthToken } from "../../../Redux/actions/auth.actions";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { AppStore } from "../../../Interface/app-store.interface";
import { TextField, Button, CardContent, Card, CardActions, Box, Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { loginForm, mfaForm } from "../../../Utils/forms/login-form";

import Hoffmann from '../../../assets/images/hoffmann.png';

const DEFAULT_LOCK_TIME = 30;

interface LoginCmsPageProps {
    appText: any;
    onFetchLogin: (data: any) => any;
    onFetchSendAuthToken: (email: string, code: string) => any;
    history: any;
}

interface LoginCmsPageState {
    loginForm: {[key: string]: any};
    mfaForm: {[key: string]: any};
    enableMFA: boolean;
    submitting: boolean;
    lockTime: number;
}

class LoginCmsPage extends Component<LoginCmsPageProps, LoginCmsPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loginForm: {...loginForm(i18n.PT)},
            mfaForm: {...mfaForm(i18n.PT)},
            enableMFA: false,
            submitting: false,
            lockTime: DEFAULT_LOCK_TIME
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
    }

    _countdown = () => {
        const { lockTime } = this.state;
        if (lockTime >= -1) {
            setTimeout(() => {
                this.setState({ lockTime: lockTime - 1 }, this._countdown)
            }, 1000);
        }
    }

    _resendCode = () => {
        const data = {
            email: this.state.loginForm.Email.value,
            password: this.state.loginForm.Password.value,
            type: 'cms'
        }
        this.props.onFetchLogin(data);
        this.setState({ lockTime: DEFAULT_LOCK_TIME });
        setTimeout(this._countdown, 1);
    }

    _renderResendLink = () => {
        const { lockTime } = this.state;
        return (lockTime > 0)
            ? `Enviar novamente em ${lockTime} segundo(s).`
            : <Link onClick={this._resendCode}>Reenviar código?</Link>
    }

    handleChange(element: string, value: any) {
        this.setState((getState: LoginCmsPageState) => {
            return {
                loginForm: {
                    ...getState.loginForm,
                    formError: '',
                    [element]: { ...getState.loginForm[element], value }
                }
            }
        });
    }
    handleCodeChange(element: string, value: any) {
        this.setState((getState: LoginCmsPageState) => {
            return {
                mfaForm: {
                    ...getState.mfaForm,
                    formError: '',
                    [element]: { ...getState.mfaForm[element], value }
                }
            }
        });
    }

    handleCodeSubmit(event: any) {
        event.preventDefault();
        this.setState({ submitting: true })
        this.props.onFetchSendAuthToken(this.state.loginForm.Email.value, this.state.mfaForm.Code.value)
            .catch(error => {
                let msg = '';
                if (error.status >= 400) {
                    msg = this.props.appText[error.msg]
                }
                this.setState((state) => ({
                    mfaForm: {
                        ...state.mfaForm,
                        formError: msg,
                    },
                    submitting: false
                }))
            });

    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.setState({ submitting: true })
        const data = {
            email: this.state.loginForm.Email.value,
            password: this.state.loginForm.Password.value,
            type: 'cms'
        }
        this.props.onFetchLogin(data).then(responseData => {
            if (responseData.firstAccess) {
                this.props.history.push('/cms/password-change', {
                    email: this.state.loginForm.Email.value
                });
            } else {
                this._countdown();
                this.setState({ enableMFA: true, submitting: false });
            }
        })
        .catch((error) => {
            let msg = '';
            if (error.status >= 400) {
                msg = this.props.appText[error.msg]
            }
            this.setState((state) => ({
                loginForm: {
                    ...state.loginForm,
                    formError: msg,
                },
                submitting: false
            }))
        });

    }

    render() {
        const { loginForm, mfaForm, enableMFA, submitting } = this.state;
        return (
            <div className={'login-page'}>
            <Grid container justify={'center'} alignItems={'center'} className={'full-height'}>
                <Grid item xs={12} sm={6} md={4} lg={3} className={'container'}>
                    <Card variant="outlined" className={'login-card'}>
                        <div className={'content-header'}>
                            <img alt="hoffmann" src={Hoffmann} width={220}/><span className={'cms-label'}>CMS</span>
                        </div>
                        {!enableMFA ?
                        <React.Fragment>
                            <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
                                <CardContent>
                                    {loginForm.formError !== '' ? <Alert severity="error">{loginForm.formError}</Alert> : null}
                                    <TextField
                                        id={loginForm.Email.name}
                                        label={loginForm.Email.label}
                                        required={loginForm.Email.required}
                                        type={loginForm.Email.type}
                                        value={loginForm.Email.value}
                                        placeholder={loginForm.Email.placeholder}
                                        fullWidth
                                        error={!!loginForm.formError}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={'hfm-input'}
                                        onChange={(event) => this.handleChange('Email', event.target.value)}

                                    />
                                    <TextField
                                        id={loginForm.Password.name}
                                        label={loginForm.Password.label}
                                        required={loginForm.Password.required}
                                        type={loginForm.Password.type}
                                        value={loginForm.Password.value}
                                        placeholder={loginForm.Password.placeholder}
                                        fullWidth
                                        error={!!loginForm.formError}
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={'hfm-input'}
                                        onChange={(event) => this.handleChange('Password', event.target.value)}

                                    />
                                    <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                                        <Link href="/cms/password-recovery">{i18n.PT.LABEL_FORGOT_MY_PASSWORD}</Link>
                                    </Grid>
                                </CardContent>
                                <CardActions className={'margin-8'}>
                                    <Grid container justify={'space-between'} alignItems={'center'}>
                                        <Grid item >
                                            <Button
                                                size="small"
                                                href="/"
                                                color="primary"
                                                startIcon={<ChevronLeftIcon />}>
                                                { i18n.PT.LABEL_GO_TO_SITE }
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Box display="flex">
                                                <Button disabled={submitting} type="submit" size="large" variant="contained" color="primary">
                                                    { submitting ? i18n.PT.LABEL_LOGGING_IN : i18n.PT.LABEL_LOGIN }
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardActions>

                            </form>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <form onSubmit={this.handleCodeSubmit} noValidate>
                                <CardContent>
                                    {mfaForm.formError !== '' ? <Alert severity="error">{mfaForm.formError}</Alert> : null}
                                    <Typography component="p" color="textSecondary" style={{color: '#FFF'}}>
                                        Use o código de autenticação enviado por SMS. {this._renderResendLink()}
                                    </Typography>
                                    <TextField
                                        id={mfaForm.Code.name}
                                        label={mfaForm.Code.label}
                                        required={mfaForm.Code.required}
                                        type={mfaForm.Code.type}
                                        value={mfaForm.Code.value}
                                        placeholder={mfaForm.Code.placeholder}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => this.handleCodeChange('Code', event.target.value)}
                                        className={'hfm-input'}
                                    />
                                </CardContent>
                                <CardActions className={'margin-8'}>
                                    <Grid container justify={'center'} alignItems={'center'}>
                                        <Grid item>
                                            <Button disabled={mfaForm.Code.value.length < 3 || submitting} type="submit" size="large" variant="contained" color="primary">
                                                { submitting ? i18n.PT.LABEL_DOING_CODE_VALIDATE : i18n.PT.LABEL_CODE_VALIDATE }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </form>
                        </React.Fragment>
                        }
                    </Card>
                </Grid>
            </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: AppStore) => {
    const { auth, root } = state;
    const appText = root.appText;
    return { auth, appText };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLogin: (data) => dispatch(fetchLogin(data, 'user')),
    onFetchSendAuthToken: (email, code) => dispatch(fetchSendAuthToken({email, code, type: 'cms'}, 'user')),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LoginCmsPage));
