
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

export default class NetworkService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getNetwork(eventId: number, data?: any) {
        return this._apiService.get(Configs.ENDPOINTS.SITE.NETWORKS + '/' + eventId, data).then((responseData) => {

            return responseData.data;
        })
    }
}
