import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '../../../assets/styles/objects/table.styles.scss'
import ChatService from "../../../Services/chat/chat.service";
import ChatModal from './chat-modal.component';
import { Nullable } from '../../../Utils/types';
import { TimeUtils } from '../../../Utils/time';
import StringUtils from '../../../Utils/string.util';

const FETCH_CHATS_INTERVAL_MS = 3000;
const MAX_LAST_MESSAGE_SIZE = 70;

interface ChatsPageProps {
    history: any;
    match: any;
}

interface ChatsPageState {
    chats: any[],
    limit: number;
    offset: number;
    count: number;
    client?: Nullable<object>;
    openModal: boolean;
}

class ChatsPage extends Component<ChatsPageProps, ChatsPageState> {
    _chatService: ChatService;
    _fetchChatsInterval: any;

    constructor(props: Readonly<ChatsPageProps>) {
        super(props);
        this._chatService = new ChatService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            chats: [],
            client: null,
            openModal: false
        }
    }

    componentDidMount = () => {
        this._fetchChats();
        this._fetchChatsInterval = setInterval(() => {
            this._fetchChats();
        }, FETCH_CHATS_INTERVAL_MS);
    }

    componentWillUnmount = () => {
        clearInterval(this._fetchChatsInterval);
    }

    _isChatUpdated = (previous: { updatedAt: any; }, incoming: { updatedAt: any; lastSender: string; }) =>
        previous && previous.updatedAt !== incoming.updatedAt && incoming.lastSender !== 'stand';

    _chatsWithNotifications = (previousChats: any[], incomingChats: any[]) => {
        const updatedChats: Array<object> = [];
        incomingChats.forEach(incomingChat => {
            const previousChat = previousChats.find(prev => prev.id === incomingChat.id);
            if (previousChat) {
                incomingChat.updated =
                    previousChat.updated || this._isChatUpdated(previousChat, incomingChat);
            }
            updatedChats.push(incomingChat);
        })
        return updatedChats;
    }

    _fetchChats = () => {
        const data = { includeClients: true, hasLastMessage: true, order: 'updatedAt:desc' }
        this._chatService
            .getChats(this.props.match.params.standId, data)
            .then(res => this.setState(prevState => ({
                chats: this._chatsWithNotifications(prevState.chats, res.rows),
                count: res.count
            })))
    }

    _handleCloseModal = () => {
        this.setState({ client: null, openModal: false });
    }

    _openChat = (chat: { updated: boolean; Client: any; }) => {
        chat.updated = false;
        this.setState({ openModal: true, client: chat.Client })
    }

    renderChats = () => {
        const { chats } = this.state;
        return chats.map(chat => (
            <ListItem button key={chat.id} onClick={_ => this._openChat(chat)}>
                <ChatIcon color={chat.updated ? 'secondary' : 'action'} style={{ marginRight: 16 }} />
                <ListItemText>
                    {chat.Client.name} <Typography color="textSecondary" variant="caption"> {TimeUtils.fromNow(chat.updatedAt)}</Typography>
                    <Typography color="textSecondary" variant="body1"> {StringUtils.truncate(chat.lastMessage, MAX_LAST_MESSAGE_SIZE)}</Typography>
                </ListItemText>
            </ListItem>
        ))
    }

    render() {
        const { client, count, openModal } = this.state;
        const standId = this.props.match.params.standId;

        return (
            <React.Fragment>
                <Button style={{ marginBottom: 16 }} onClick={this.props.history.goBack} variant={'outlined'} color="primary">
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIcon fontSize={'small'} style={{ marginRight: 8 }} />voltar para listagem
                    </p>
                </Button>
                <Typography variant="h4">{count} chats</Typography>
                <List component="nav">
                    {this.renderChats()}
                </List>
                {client && <ChatModal standId={standId} client={client} isOpen={openModal} handleCloseModal={this._handleCloseModal} />}
            </React.Fragment>
        );
    }
};

export default withRouter(ChatsPage);
