import React, { Component } from 'react';

import i18n from '../../../../Utils/i18n';

import './basic-info-page.component.scss'
import '../../../../assets/styles/objects/table.styles.scss'
import '../../../../assets/styles/objects/image-wrapper.styles.scss'
import './../events-page.component.scss'
import {
    Grid,
    Button,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Modal,
    Card,
    CardContent,
    CardHeader,
    IconButton
} from "@material-ui/core";

import TableChartIcon from '@material-ui/icons/TableChart';
import CloseIcon from '@material-ui/icons/Close';

import { Alert } from "@material-ui/lab";
import { roomForm } from "../../../../Utils/forms/event-form";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import RoomService from "../../../../Services/room/room.service";
import RoomRow from "../../../../Components/UI/Table/Row/room-row.component";

interface RoomPageProps {
    eventId: any;
    fetchRoomList: () => any;
    roomList: any[];
    appText: any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface RoomPageState {
    roomForm: { [key: string]: any };
    submiting: boolean;
    openModal: boolean;
}

class RoomPage extends Component<RoomPageProps, RoomPageState> {
    private _roomService: RoomService;

    constructor(props) {
        super(props);
        this._roomService = new RoomService();

        this.state = {
            roomForm: { ...roomForm },
            submiting: false,
            openModal: false
        }

        this.handleRoomSubmit = this.handleRoomSubmit.bind(this);
        this._editRoom = this._editRoom.bind(this);
        this._removeRoom = this._removeRoom.bind(this);
    }

    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                roomForm: {
                    ...state.roomForm,
                    formError: false,
                    [element]: {
                        ...state.roomForm[element],
                        value
                    }
                }
            }
        })
    }

    handleRoomSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.roomForm.id.value,
            eventId: this.props.eventId,
            name: this.state.roomForm.name.value,
            link: this.state.roomForm.link.value
        }
        if (!this.state.roomForm.editing) {
            delete data.id;
        }
        this.props.onShowLoader();
        this._roomService.setRoom(data, this.state.roomForm.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.props.fetchRoomList();
            this.setState({
                openModal: false
            })
        })
            .catch((error) => {
                this.props.onHideLoader();
                if (error.status >= 400) {
                    this.setState((state) => ({
                        roomForm: {
                            ...state.roomForm,
                            formError: this.props.appText[error.msg]
                        }
                    }))
                }
            })

    }

    _removeRoom(room: any) {
        this.props.onShowDialog({
            handlePositive: () => {
                this._roomService.removeRoom([room.id]).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    this.props.fetchRoomList();
                })
                .catch((error) => {
                    if (error.status >= 400) {
                        this.props.onShowToast({
                            show: true,
                            type: 'warning',
                            message: this.props.appText[error.msg]
                        });
                    }
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir a sala "${room.name}"?`
        })

    }

    _editRoom(rowData, newRoom?) {
        let newForm = { ...this.state.roomForm };
        if (rowData || newRoom) {
            for (var key in newForm) {
                if (newForm.hasOwnProperty(key) && key !== 'formError' && key !== 'editing') {
                    newForm[key].value = newRoom ? '' : rowData[key];

                }
            }
            newForm.formError = false;
            newForm.editing = !newRoom;
            this.setState({
                roomForm: newForm,
                openModal: true
            })
        }
    }

    render() {
        const { roomForm, openModal } = this.state;
        const { roomList } = this.props;
        return (
            <div style={{ width: '100%' }}>
                <div style={{marginBottom: 16}}>
                    <Button onClick={() => this._editRoom(null, true)} variant={'contained'} color="primary">
                        {'Adicionar Sala'}
                    </Button>
                </div>
                {roomList.length <= 0 ? (
                    <div style={{display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <div><TableChartIcon color={'disabled'} style={{ fontSize: 200 }}/></div>
                        <p>Para inserir uma sala, preencha os dados nos campos acima</p>
                    </div>
                ) : (
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead className={'table-header'}>
                                <TableRow>
                                    <TableCell style={{width: '20%'}}>ID</TableCell>
                                    <TableCell style={{width: '20%'}}>Nome</TableCell>
                                    <TableCell style={{width: '20%'}}>Link</TableCell>
                                    <TableCell style={{width: '10%', textAlign: 'center'}}>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roomList.map((row) => {
                                    return (
                                        <RoomRow row={row} editRoom={this._editRoom} removeRoom={this._removeRoom}/>
                                    )}
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openModal}
                    onClose={() => this.setState({
                        openModal: false
                    })
                    }
                    aria-labelledby="adicionar Sala"
                    aria-describedby="adicionar Sala"
                >
                    <Card style={{ minWidth: '25%', maxHeight: '80%', overflowY: 'scroll' }} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={roomForm.editing ? "Editando Sala" :"Adicionar Sala"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <CardContent>
                            {!!roomForm.formError ? <Alert severity="error">{roomForm.formError}</Alert> : null}
                            <form onSubmit={this.handleRoomSubmit} noValidate>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} >
                                        <TextField
                                            {...roomForm.name}
                                            fullWidth
                                            error={!!roomForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('name', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            {...roomForm.link}
                                            fullWidth
                                            error={!!roomForm.formError}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('link', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justify={'flex-end'} className={'button-wrapper'}>
                                    <Grid item>
                                        <label>
                                            <Button type="submit" variant="contained" color="primary">
                                                {roomForm.editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE}
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowDialog: (data) => dispatch(showDialog(data)),
        onHideDialog: () => dispatch(hideDialog()),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomPage);