import React from 'react';
import { Modal, Link, Button, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

import './soft-login-modal.component.scss';

import Hoffmann from '../../assets/images/hoffmann.png';
import { fetchSoftLogin } from "../../Redux/actions/auth.actions";
import { loginForm } from "../../Utils/forms/login-form";
import { AppStore } from "../../Interface/app-store.interface";
import { Alert } from "@material-ui/lab";

interface SoftLoginModalProps {
    open: boolean;
    appText?: any;
    onFetchSoftLogin: (data: any) => any;
    onClose: () => any;
}

interface SoftLoginModalState {
    loginForm: {[key: string]: any};
    submitting: boolean;
}

class SoftLoginModal extends React.Component<SoftLoginModalProps, SoftLoginModalState>{

    constructor(props: any) {
        super(props);
        this.state = {
            loginForm: {...loginForm(props.appText)},
            submitting: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this._onClose = this._onClose.bind(this);
    }

    handleChange(form: string, element: string, value: any) {
        this.setState((getState: SoftLoginModalState) => {
            return {
                ...getState,
                [form]: {
                    ...getState[form],
                    formError: '',
                    [element]: { ...getState[form][element], value }
                }
            }
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        this.setState({ submitting: true })
        const data = {
            email: this.state.loginForm.Email.value
        }
        this.props.onFetchSoftLogin(data).then(responseData => {
            this.setState({ submitting: false });
            this.props.onClose();
        })
        .catch((error) => {
            let msg = '';
            if (error.status >= 400) {
                msg = error.msg
            }
            this.setState((state) => {
                return {
                    loginForm: {
                        ...state.loginForm,
                        formError: this.props.appText[msg]
                    },
                    submitting: false
                }
            })
        });

    }

    _onClose(event) {
        event.preventDefault();
        this.props.onClose();
    }

    _goToWp() {
        window.open('https://web.whatsapp.com/send?phone=5511989650115', '_blank');
    }

    render () {
        return <Modal
            className={"container-login"}
            open={this.props.open}
            onClose={(event) => this._onClose(event)}
        >
                <div className={"inner-container -soft"}>
                    <div className={'container-header'}>
                        <img src={Hoffmann} width={200}/>
                    </div>
                    <form onSubmit={this.handleSubmit} noValidate>
                        {this.state.loginForm.formError !== '' ? <Alert severity="error">{this.state.loginForm.formError}</Alert> : null}
                        <div className={'form-inputs'}>
                            <input type="text" name="username" value={this.state.loginForm.Email.value} onChange={(event) => {this.handleChange('loginForm','Email', event.target.value)}} placeholder={this.state.loginForm.Email.label} className={"form-contato"}/>
                        </div>
                        <div className={'form-link'}>
                            <Link href="javascript:;" onClick={() => this._goToWp()}>Solicitar Acesso</Link>
                        </div>
                        <div className={'form-buttons'}>
                            <Button type="submit" fullWidth className={"btn-logar"} disabled={this.state.submitting}>
                                {this.state.submitting ? <CircularProgress size={16} color={'inherit'}/> : 'Entrar'}
                            </Button>
                        </div>

                    </form>
                </div>
        </Modal>
    }
}

const mapStateToProps = (state: AppStore) => {
    const { auth, root } = state;
    const appText = root.appText;
    return { auth, appText };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSoftLogin: (data) => dispatch(fetchSoftLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftLoginModal);