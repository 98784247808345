import React from 'react';
import {
    withRouter
} from "react-router-dom";

import RoomService from "../../../../../Services/room/room.service";

// import Congresso from '../../../../../assets/images/platform/ante-sala.jpg';
import './cbmi-2021.component.scss';
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";

interface CbmiPageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface CbmiPageState {
    animating: boolean;
    rooms: any[];
}

class CbmiPage extends React.Component<CbmiPageProps, CbmiPageState> {
    roomService: RoomService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.roomService = new RoomService();
        this.state = {
            animating: false,
            rooms: [],
        }
    }

    goToStand(standId) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/estande/${standId}`);
    }

    componentWillUnmount() {
        this.setState((state) => ({
            animating: true
        }));
    }
    

    render() {
        return (
            <div id="hall-cbmi-2021">
                <section className={`container-hall-stands ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/ante-sala.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className={`tv left-tv`}>
                                <a target="_blank" href="https://web.whatsapp.com/send?phone=5511982098489"></a>
                            </div>
                            <div className={`tv right-tv`}>
                                <a href="https://d2t970vtndlymz.cloudfront.net/documents/cbmi21-informacao.pdf" target="_blank"></a>
                            </div>
                        </div>
                    </div>
		        </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(CbmiPage));