import React, { Component } from 'react';
import {
    withRouter
} from "react-router-dom";

import i18n from '../../../Utils/i18n';

import '../events/pages/basic-info-page.component.scss'
import '../../../assets/styles/objects/table.styles.scss'
import '../../../assets/styles/objects/image-wrapper.styles.scss'
import '../events/events-page.component.scss'
import {
    Grid,
    Button,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Modal,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    FormControl,
} from "@material-ui/core";

import TableChartIcon from '@material-ui/icons/TableChart';
import CloseIcon from '@material-ui/icons/Close';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HelpIcon from '@material-ui/icons/Help';

import { Alert } from "@material-ui/lab";
import { standForm } from "../../../Utils/forms/event-form";
import { showToast, showLoader, hideLoader } from "../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import StandService from "../../../Services/stand/stand.service";
import StandRow from "../../../Components/UI/Table/Row/stand-row.component";
import MouseOverPopover from "../../../Components/UI/Popover/popover.component";

interface StandPageProps {
    history: any;
    match: any;
    appText: any;
    onShowToast: (data: any) => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface StandPageState {
    standForm: { [key: string]: any };
    submiting: boolean;
    openModal: boolean;
    standList: any[];
    removeImage: 0 | 1;
}

class StandPage extends Component<StandPageProps, StandPageState> {
    private _standService: StandService;

    constructor(props) {
        super(props);
        this._standService = new StandService();

        this.state = {
            standForm: { ...standForm },
            submiting: false,
            openModal: false,
            standList: [],
            removeImage: 0,
        }

        this.handleStandSubmit = this.handleStandSubmit.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.setRemoveImage = this.setRemoveImage.bind(this);
        this._editStand = this._editStand.bind(this);
        this._openPage = this._openPage.bind(this);
    }

    componentDidMount() {
        this.fetchStands();
    }

    fetchStands() {
        this._standService.getStands().then((responseData) => {
            this.setState({
                standList: responseData.rows
            })
        })
    }
    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                standForm: {
                    ...state.standForm,
                    formError: false,
                    [element]: {
                        ...state.standForm[element],
                        value
                    }
                }
            }
        })
    }
    handleImageChange(element: string, value: any) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            this.setState((state: any) => {
                return {
                    ...state,
                    removeImage: 0,
                    standForm: {
                        ...state.standForm,
                        formError: false,
                        [element]: {
                            ...state.standForm[element],
                            renderValue: e.target.result,
                            value
                        }
                    }
                }
            })
        }
        reader.readAsDataURL(value);
    }

    handleStandSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.standForm.id.value,
            eventId: this.state.standForm.eventId.value,
            name: this.state.standForm.name.value,
            subtitle: this.state.standForm.subtitle.value,
            description: this.state.standForm.description.value,
            image: this.state.standForm.image.value,
            removeImage: this.state.removeImage
        }
        if (!this.state.standForm.editing) {
            delete data.id;
        }
        if (this.state.standForm.image.value === undefined || (typeof this.state.standForm.image.value == 'string')) {
            delete data.image;
        }
        this.props.onShowLoader();
        this._standService.setStand(data, this.state.standForm.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.fetchStands();
            this.setState({
                openModal: false
            })
        })
            .catch((error) => {
                this.props.onHideLoader();
                if (error.status >= 400) {
                    this.setState((state) => ({
                        standForm: {
                            ...state.standForm,
                            formError: this.props.appText[error.msg]
                        }
                    }))
                }
            })

    }

    setRemoveImage(){
        this.handleChange('image', undefined);
        this.setState({
            removeImage: 1
        })
    }

    _editStand(rowData, newStand?) {
        let newForm = { ...this.state.standForm };
        if (rowData || newStand) {
            for (var key in newForm) {
                if (newForm.hasOwnProperty(key) && key !== 'formError' && key !== 'editing') {
                    newForm[key].value = newStand ? '' : rowData[key];
                }
            }

            newForm.formError = false;
            newForm.editing = !newStand;
            this.setState({
                standForm: newForm,
                openModal: true
            })
        }
    }

    _openPage(id, page) {
        this.props.history.push(`${this.props.history.location.pathname}/${id}/${page}`);
    }

    render() {
        const { standForm, openModal, standList } = this.state;
        return (
            <div style={{ width: '100%' }}>
                {standList.length <= 0 ? (<div style={{display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <div><TableChartIcon color={'disabled'} style={{ fontSize: 200 }}/></div>
                    <p>Para inserir um Estande, preencha os dados nos campos acima</p>
                </div>) : (
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead className={'table-header'}>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Subtítulo</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Tipo de Estande</TableCell>
                                <TableCell style={{width: '20%', textAlign: 'center'}}>Ações</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {standList.map((row) => {
                                return (
                                    <StandRow row={row} editStand={this._editStand} openPage={this._openPage}/>
                                );
                                }
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openModal}
                    onClose={() => this.setState({
                        openModal: false
                    })
                    }
                    aria-labelledby="adicionar Estande"
                    aria-describedby="adicionar Estande"
                >
                    <Card style={{ minWidth: '50%', maxHeight: '80%', overflowY: 'scroll' }} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={standForm.editing ? "Editando Estande" :"Adicionar Estande"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <CardContent>
                            {!!standForm.formError ? <Alert severity="error">{standForm.formError}</Alert> : null}
                            <form onSubmit={this.handleStandSubmit} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #c3c3c3',
                                                padding: standForm.image.value ? 0 : 24
                                            }}>
                                                {standForm.image.value ?
                                                    <div className={'image-wrapper'}>
                                                        <img className={'form-image'} src={standForm.image.renderValue ? standForm.image.renderValue : standForm.image.value} alt={standForm.name.value} />
                                                        <div className={'overlay-image'}>
                                                            <Button
                                                                className={'overlay-button'}
                                                                variant="contained"
                                                                color="primary"
                                                                component="label"
                                                                onClick={(event: any) => this.setRemoveImage()}
                                                            >
                                                                Remover imagem
                                                    </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 100, height: 200, marginLeft: -20 }} /></div>
                                                        <Button
                                                            className={'overlay-button'}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                        >
                                                            Fazer upload de imagem
                                                    <input
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(event: any) => this.handleImageChange('image', event.target.files[0])}
                                                                type="file"
                                                            />
                                                        </Button>
                                                        <div className={'info-icon'}>
                                                            <MouseOverPopover
                                                                text={
                                                                    <HelpIcon fontSize="small"/>
                                                                }
                                                                content={
                                                                    <React.Fragment>
                                                                        <p>- Deve ser compostos por no minimo <strong>8 caracteres</strong></p>
                                                                        <p>- Deve ter ao menos um <strong>caractere especial</strong></p>
                                                                        <p>- Deve ser <strong>alfanumerico</strong></p>
                                                                        <p>- Deve ter ao um caracter <strong>maiusculo</strong></p>
                                                                        <p>- Deve ter ao um caracter <strong>minusculo</strong></p>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...standForm.name}
                                            fullWidth
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('name', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...standForm.subtitle}
                                            fullWidth
                                            multiline
                                            rows={1}
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('subtitle', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...standForm.description}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('description', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justify={'flex-end'} className={'button-wrapper'}>
                                    <Grid item>
                                        <label>
                                            <Button type="submit" variant="contained" color="primary">
                                                {standForm.editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE}
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StandPage));
