import React, { Component } from 'react';

import './basic-info-page.component.scss'
import '../../../../assets/styles/objects/table.styles.scss'
import '../../../../assets/styles/objects/image-wrapper.styles.scss'
import './../events-page.component.scss'
import {
    Grid,
    Button,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Modal,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel,
    CardHeader,
    IconButton
} from "@material-ui/core";

import i18n from '../../../../Utils/i18n'

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import TableChartIcon from '@material-ui/icons/TableChart';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';

import { Alert } from "@material-ui/lab";
import { mediaForm } from "../../../../Utils/forms/event-form";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import MediaService from "../../../../Services/media/media.service";
import MediaRow from "../../../../Components/UI/Table/Row/media-row.component";
import MouseOverPopover from "../../../../Components/UI/Popover/popover.component";

interface MediaPageProps {
    standList: any[];
    selectedStand: number;
    appText: any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface MediaPageState {
    mediaForm: { [key: string]: any };
    mediaList: any[];
    selectedStand: number | false;
    openModal: boolean;
    removeImage: 0 | 1;
}

class MediaPage extends Component<MediaPageProps, MediaPageState> {
    private _mediaService: MediaService;

    constructor(props) {
        super(props);
        this._mediaService = new MediaService();
        this.state = {
            mediaForm: { ...mediaForm },
            selectedStand: this.props.selectedStand,
            mediaList: [],
            openModal: false,
            removeImage: 0
        }

        this.handleMediaSubmit = this.handleMediaSubmit.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this._editMedia = this._editMedia.bind(this);
        this._removeMedia = this._removeMedia.bind(this);
        this.selectStand = this.selectStand.bind(this);
        this.setRemoveImage = this.setRemoveImage.bind(this);
    }

    componentDidMount() {
        if(this.state.selectedStand) {
            this.fetchMediaList();
        }
    }

    fetchMediaList() {
        this._mediaService.getMedias({ standId: this.state.selectedStand }).then((responseData) => {
            this.setState({
                mediaList: responseData.rows
            })
        })
    }

    selectStand(standId) {
        this.setState( {
            selectedStand: standId
        }, () => {
            this.fetchMediaList()
        })
    }

    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                mediaForm: {
                    ...state.mediaForm,
                    formError: false,
                    [element]: {
                        ...state.mediaForm[element],
                        value
                    }
                }
            }
        })
    }

    handleImageChange(element: string, value: any) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            let isVideo = false;
            let isDocument = false;
            if(value.type.includes('video')) {
                isVideo = true;
            }
            if(value.type.includes('pdf')) {
                isDocument = true;
            }
            this.setState((state: any) => {
                return {
                    ...state,
                    removeImage: 0,
                    mediaForm: {
                        ...state.mediaForm,
                        formError: false,
                        [element]: {
                            ...state.mediaForm[element],
                            renderValue: e.target.result,
                            isVideo,
                            isDocument,
                            value
                        }
                    }
                }
            })
        }
        reader.readAsDataURL(value);
    }

    handleMediaSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.mediaForm.id.value,
            standId: this.state.selectedStand,
            title: this.state.mediaForm.title.value,
            description: this.state.mediaForm.description.value,
            file: this.state.mediaForm.file.value,
            removeFile: this.state.removeImage
        }
        if (!this.state.mediaForm.editing) {
            delete data.id;
        }
        if (this.state.mediaForm.file.value === undefined || (typeof this.state.mediaForm.file.value == 'string')) {
            delete data.file;
        }
        this.props.onShowLoader();
        this._mediaService.setMedia(data, this.state.mediaForm.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.fetchMediaList();
            this.setState({
                openModal: false
            })
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState((state) => ({
                    mediaForm: {
                        ...state.mediaForm,
                        formError: this.props.appText[error.msg]
                    }
                }))
            }
        })
    }

    _removeMedia(media: any) {
        this.props.onShowDialog({
            handlePositive: () => {
                this._mediaService.removeMedia([media.id]).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    this.fetchMediaList()
                })
                .catch((error) => {
                    if (error.status >= 400) {
                        this.props.onShowToast({
                            show: true,
                            type: 'warning',
                            message: this.props.appText[error.msg]
                        });
                    }
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir a mídia "${media.title}"?`
        })

    }

    _editMedia(rowData, newMedia?) {
        let newForm = { ...mediaForm };
        if (rowData || newMedia) {
            for (var key in newForm) {
                if (newForm.hasOwnProperty(key) && key !== 'formError' && key !== 'editing') {
                    newForm[key].value = newMedia ? '' : rowData[key];

                }
            }
            newForm.formError = false;
            newForm.editing = !newMedia;
            this.setState({
                mediaForm: newForm,
                openModal: true
            })
        }
    }

    setRemoveImage(){
        this.handleChange('file', undefined);
        this.setState({
            removeImage: 1
        })
    }

    render() {
        const { mediaForm, openModal, mediaList } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <div style={{ marginBottom: 16, marginTop: 8 }}>
                    <FormControl variant="outlined" style={{marginRight: 16}}>
                        <InputLabel id="demo-simple-select-helper-label">Estande</InputLabel>
                        <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={this.state.selectedStand}
                        label="Estande"
                        onChange={(event) => this.selectStand(event.target.value)}
                        >
                            {this.props.standList.map((item) => {
                                return <MenuItem value={item.id}>{item.name}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>selecione um estande para ver as suas mídias</FormHelperText>
                    </FormControl>
                    <Button style={{padding: 15.5}} size="large" onClick={() => this._editMedia(null, true)} variant={'contained'} color="primary" disabled={!this.state.selectedStand}>
                        {'Adicionar Mídia'}
                    </Button>
                </div>
                { this.state.selectedStand ? (<React.Fragment>
                    {mediaList.length <= 0 ? (<div style={{ display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div><TableChartIcon color={'disabled'} style={{ fontSize: 200 }} /></div>
                        <p>Para inserir uma media, preencha os dados nos campos acima</p>
                    </div>) : (
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead className={'table-header'}>
                                        <TableRow>
                                            <TableCell style={{ width: '20%' }}>Nome</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell style={{ width: '10%', textAlign: 'center' }}>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mediaList.map((row) => {
                                            return (
                                                <MediaRow row={row} editMedia={this._editMedia} removeMedia={this._removeMedia}/>
                                            );
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    <Modal
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        open={openModal}
                        onClose={() => this.setState({
                            openModal: false
                        })
                        }
                        aria-labelledby="adicionar Mídia"
                        aria-describedby="adicionar Mídia"
                    >
                        <Card style={{ minWidth: '50%', maxHeight: '80%', overflowY: 'scroll' }} variant="outlined">
                            <CardHeader
                                action={
                                <IconButton 
                                    onClick={() => this.setState({
                                            openModal: false
                                        })
                                    }
                                    aria-label="settings">
                                    <CloseIcon />
                                </IconButton>
                                }
                                title={mediaForm.editing ? "Editando Mídia" :"Adicionar Mídia"}
                                subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                            />
                            <CardContent>
                                {!!mediaForm.formError ? <Alert severity="error">{mediaForm.formError}</Alert> : null}
                                <div className={'form-container'}>
                                    <form onSubmit={this.handleMediaSubmit} noValidate>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} >
                                                <TextField
                                                    {...mediaForm.title}
                                                    fullWidth
                                                    error={!!mediaForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('title', event.target.value)}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    {...mediaForm.description}
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    error={!!mediaForm.formError}
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => this.handleChange('description', event.target.value)}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} className={'form-image-buttom'} >
                                                <Grid container spacing={2} justify={'space-between'}>
                                                    <Grid item xs={12}>
                                                        <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                border: '1px solid #c3c3c3',
                                                                padding: mediaForm.file.value ? 0 : 24
                                                            }}>
                                                                {mediaForm.file.value ?
                                                                    <div className={`image-wrapper ${mediaForm.file.isVideo || mediaForm.file.isDocument ? '-video' : null}`}>
                                                                        {mediaForm.file.isVideo ? 
                                                                            <video controls>
                                                                                <source src={mediaForm.file.renderValue ? mediaForm.file.renderValue : mediaForm.file.value} id="video_here" />
                                                                                    Your browser does not support HTML5 video.
                                                                            </video>
                                                                        : 
                                                                        mediaForm.file.isDocument ? 
                                                                            <iframe className={'documents'} src={mediaForm.file.renderValue ? mediaForm.file.renderValue : mediaForm.file.value} height="100%" width="100%"></iframe>
                                                                        : 
                                                                            <img className={'form-image'} src={mediaForm.file.renderValue ? mediaForm.file.renderValue : mediaForm.file.value} alt={mediaForm.title.value} />
                                                                        }
                                                                        <div className={`overlay-image ${mediaForm.file.isVideo || mediaForm.file.isDocument ? '-video' : null}`}>
                                                                            <Button
                                                                                className={'overlay-button'}
                                                                                variant="contained"
                                                                                color="primary"
                                                                                component="label"
                                                                                onClick={(event: any) => this.setRemoveImage()}
                                                                            >
                                                                                Remover {mediaForm.file.isVideo? 'video' : 'imagem'}
                                                                    </Button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 100, height: 200, marginLeft: -20 }} /></div>
                                                                        <Button
                                                                            className={'overlay-button'}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            component="label"
                                                                        >
                                                                            Fazer upload de mídia
                                                                            <input
                                                                                accept="image/*,video/*,application/pdf"
                                                                                style={{ display: 'none' }}
                                                                                onChange={(event: any) => this.handleImageChange('file', event.target.files[0])}
                                                                                type="file"
                                                                            />
                                                                        </Button>
                                                                        <div className={'info-icon'}>
                                                                            <MouseOverPopover
                                                                                text={
                                                                                    <HelpIcon fontSize="small"/>
                                                                                }
                                                                                content={
                                                                                    <React.Fragment>
                                                                                        <p>- Deve ser compostos por no minimo <strong>8 caracteres</strong></p>
                                                                                        <p>- Deve ter ao menos um <strong>caractere especial</strong></p>
                                                                                        <p>- Deve ser <strong>alfanumerico</strong></p>
                                                                                        <p>- Deve ter ao um caracter <strong>maiusculo</strong></p>
                                                                                        <p>- Deve ter ao um caracter <strong>minusculo</strong></p>
                                                                                    </React.Fragment>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify={'flex-end'} spacing={3}>
                                            <Grid item spacing={2}>
                                                <label>
                                                    <Button type="submit" variant="contained" color="primary">
                                                        {mediaForm.editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE}
                                                    </Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </CardContent>
                        </Card>
                    </Modal>
                </React.Fragment>) : null}
            </div> 
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowDialog: (data) => dispatch(showDialog(data)),
        onHideDialog: () => dispatch(hideDialog()),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPage);