import React from 'react';
import Popover from '@material-ui/core/Popover';
// import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     popover: {
//       pointerEvents: 'none',
//     },
//     paper: {
//       padding: theme.spacing(1),
//     },
//   }),
// );

export default function MouseOverPopover({text, content}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
          className={'info-icon'}
          aria-owns={'mouse-over-popover'}
          aria-label="dicas para senha"
          onClick={handlePopoverOpen}
      >
          {text}
      </IconButton>
      <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
          }}
          transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{
              padding: 8
          }}
      >
          {content}
      </Popover>
    </div>
  );
}
