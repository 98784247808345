import { ADD_CARD_ITEM, REMOVE_CARD_ITEM, SET_COUPON, FINISH_CARD_ITEM, REMOVE_COUPON } from "../actions/checkout.actions";

export interface CheckoutState {
  carts: {}
}

const checkoutState: CheckoutState = {
  carts: {

  }
};

export default (state = checkoutState, action: any) => {
  let newCart = {...state.carts};
  switch (action.type) {
    case ADD_CARD_ITEM:
      if(newCart[action.payload.eventId] == undefined) {
        newCart[action.payload.eventId] = {
          all: action.payload.addType == 'event',
          list: [],
          step: 1
        }
      }
      if(action.payload.addType == 'event') {
        newCart[action.payload.eventId].list = [];
        newCart[action.payload.eventId].all = true;
      } else {
        if(!newCart[action.payload.eventId].list.includes(action.payload.addId)) {
          newCart[action.payload.eventId].list.push(action.payload.addId);
        }
        newCart[action.payload.eventId].all = false;
      }
      return {
        ...state,
        carts: newCart
      }
    case REMOVE_CARD_ITEM:
      if(action.payload.addType == 'event') {
        newCart[action.payload.eventId].list = [];
        newCart[action.payload.eventId].all = false;
        newCart[action.payload.eventId].step = 1;
      } else {
        const newList = newCart[action.payload.eventId].list.filter((item) => item !== action.payload.addId);
        newCart[action.payload.eventId].list = newList;
      }
      return {
        ...state,
        carts: newCart
      }
    case FINISH_CARD_ITEM:
      delete newCart[action.payload];
      return {
        ...state,
        carts: newCart
      }
    case SET_COUPON:
      newCart[action.payload.eventId].cupon = action.payload.data;
      return {
        ...state,
        carts: newCart
      }
    case REMOVE_COUPON:
      delete newCart[action.payload.eventId].cupon;
      return {
        ...state,
        carts: newCart
      }
    default:
      return state;
  }
};
