import i18n from '../i18n';

export class SpeakerForm {
    removeImage: 0 | 1;
    editing: boolean;
    formError: boolean;
    image: { id: string; label: string; name: string; type: string; value: undefined; renderValue: undefined; required: boolean; };
    description: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    title: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    name: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    id: { id: string; name: string; type: string; value: undefined; required: boolean; hidden: boolean; };

    constructor() {
        this.formError = false;
        this.editing = false;
        this.removeImage = 0;
        this.id = {
            id: 'id',
            name: 'id',
            type: 'hidden',
            value: undefined,
            required: true,
            hidden: true,
        };
        this.name = {
            id: 'name',
            label: i18n.PT.LABEL_SPEAKER_NAME,
            name: 'name',
            type: 'text',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_SPEAKER_NAME_PLACEHOLDER,
        };
        this.title = {
            id: 'title',
            label: i18n.PT.LABEL_SPEAKER_TITLE,
            name: 'title',
            type: 'text',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_SPEAKER_TITLE_PLACEHOLDER,
        };
        this.description = {
            id: 'description',
            label: i18n.PT.LABEL_SPEAKER_DESCRIPTION,
            name: 'description',
            type: 'textarea',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_SPEAKER_DESCRIPTION_PLACEHOLDER,
        };
        this.image = {
            id: 'image',
            label: 'imagem',
            name: 'image',
            type: 'file',
            value: undefined,
            renderValue: undefined,
            required: true,
        };
    }
}
export class EventForm {
    exchange: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    status: { id: string; label: string; name: string; value: number; checked: boolean; required: boolean; };
    featured: { id: string; label: string; name: string; required: boolean; value: number, checked: boolean };
    published: { id: string; label: string; name: string; required: boolean; value: number, checked: boolean };
    linkedIn: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    twitter: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    facebook: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    endDate: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    startDate: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    endTime: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    startTime: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    editing: boolean;
    id: { id: string; name: string; type: string; value: undefined; required: boolean; hidden: boolean; };
    installments: { id: string; label: string; name: string; type: string; value: string; required: boolean; data: never[]; placeholder: string; };
    discount: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    value: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    image: { id: string; label: string; name: string; type: string; value: undefined; renderValue: undefined, required: boolean; };
    banner: { id: string; label: string; name: string; type: string; value: undefined; renderValue: undefined, required: boolean; };
    thumb: { id: string; label: string; name: string; type: string; value: undefined; renderValue: undefined, required: boolean; };
    description: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    name: { id: string; label: string; name: string; type: string; value: string; required: boolean; placeholder: string; };
    slug: { id: string; label: string; name: string; type: string; value: string; hidden: boolean; };
    categoryId: { label: string; type: string; value: string; data: any[]; placeholder: string; inputProps: { name: string; id: string; required: boolean; }; };
    formError: boolean;

    constructor() {
        this.formError = false;
        this.editing = false;
        this.id = {
            id: 'id',
            name: 'id',
            type: 'hidden',
            value: undefined,
            required: true,
            hidden: true,
        };
        this.categoryId = {
            label: i18n.PT.LABEL_CATEGORY,
            type: 'select',
            value: '',
            data: [],
            placeholder: 'Selecione uma opção',
            inputProps: {
                name: 'category',
                id: 'category',
                required: true
            }
        };
        this.slug = {
            id: 'slug',
            label: 'slug',
            name: 'slug',
            type: 'text',
            value: '',
            hidden: true
        };
        this.name = {
            id: 'name',
            label: i18n.PT.LABEL_EVENT_NAME,
            name: 'name',
            type: 'text',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_EVENT_NAME_PLACEHOLDER,
        };
        this.description = {
            id: 'description',
            label: i18n.PT.LABEL_EVENT_DESCRIPTION,
            name: 'description',
            type: 'textarea',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_EVENT_DESCRIPTION_PLACEHOLDER,
        };
        this.image = {
            id: 'image',
            label: 'fundo',
            name: 'image',
            type: 'file',
            value: undefined,
            renderValue: undefined,
            required: true,
        };
        this.banner = {
            id: 'banner',
            label: 'banner',
            name: 'banner',
            type: 'file',
            value: undefined,
            renderValue: undefined,
            required: true,
        };
        this.thumb = {
            id: 'thumb',
            label: 'miniatura',
            name: 'thumb',
            type: 'file',
            value: undefined,
            renderValue: undefined,
            required: true,
        };
        this.value = {
            id: 'value',
            label: i18n.PT.LABEL_PRICE,
            name: 'value',
            type: 'text',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_PRICE_PLACEHOLDER,
        };
        this.discount = {
            id: 'discount',
            label: i18n.PT.LABEL_DISCOUNT,
            name: 'discount',
            type: 'text',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_DISCOUNT_PLACEHOLDER,
        };
        this.installments = {
            id: 'installments',
            label: i18n.PT.LABEL_INSTALLMENTS,
            name: 'installments',
            type: 'number',
            value: '',
            required: false,
            data: [],
            placeholder: i18n.PT.FORM_INSTALLMENTS_PLACEHOLDER,
        };
        this.exchange = {
            id: 'exchange',
            label: i18n.PT.LABEL_EXCHANGE,
            name: 'exchange',
            type: 'number',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EXCHANGE_PLACEHOLDER,
        };
        this.startDate = {
            id: 'startDate',
            label: i18n.PT.LABEL_START_DATE,
            name: 'startDate',
            type: 'date',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_EVENT_START_DATE_PLACEHOLDER,
        };
        this.endDate = {
            id: 'endDate',
            label: i18n.PT.LABEL_END_DATE,
            name: 'endDate',
            type: 'date',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EVENT_END_DATE_PLACEHOLDER,
        };
        this.startTime = {
            id: 'startTime',
            label: i18n.PT.LABEL_START_TIME,
            name: 'startTime',
            type: 'time',
            value: '',
            required: true,
            placeholder: i18n.PT.FORM_EVENT_START_TIME_PLACEHOLDER,
        };
        this.endTime = {
            id: 'endTime',
            label: i18n.PT.LABEL_END_TIME,
            name: 'endTime',
            type: 'time',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EVENT_END_TIME_PLACEHOLDER,
        };
        this.facebook = {
            id: 'facebook',
            label: i18n.PT.LABEL_FACEBOOK,
            name: 'facebook',
            type: 'text',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EVENT_FACEBOOK_PLACEHOLDER,
        };
        this.twitter = {
            id: 'twitter',
            label: i18n.PT.LABEL_TWITTER,
            name: 'twitter',
            type: 'text',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EVENT_TWITTER_PLACEHOLDER,
        };
        this.linkedIn = {
            id: 'linkedIn',
            label: i18n.PT.LABEL_LINKEDIN,
            name: 'linkedIn',
            type: 'text',
            value: '',
            required: false,
            placeholder: i18n.PT.FORM_EVENT_LINKEDIN_PLACEHOLDER,
        };
        this.featured = {
            id: 'featured',
            label: i18n.PT.LABEL_FEATURED,
            name: 'featured',
            value: 0,
            checked: false,
            required: false,
        };
        this.published = {
            id: 'published',
            label: i18n.PT.LABEL_FEATURED,
            name: 'published',
            value: 0,
            checked: false,
            required: false,
        };
        this.status = {
            id: 'status',
            label: i18n.PT.LABEL_OPEN,
            name: 'status',
            value: 0,
            checked: false,
            required: false,
        };
    }
};
export const roomForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    eventId: {
        id: 'eventId',
        name: 'eventId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    name: {
        id: 'name',
        label: i18n.PT.LABEL_ROOM_NAME,
        name: 'name',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_ROOM_NAME_PLACEHOLDER,
    },
    link: {
        id: 'link',
        label: 'Link do Zoom',
        name: 'Link do Zoom',
        type: 'text',
        value: '',
        required: false,
        placeholder: 'digite a URL do link do Zoom',
    },
};
export const mediaForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    standId: {
        id: 'standId',
        name: 'standId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    title: {
        id: 'title',
        label: i18n.PT.LABEL_MEDIA_NAME,
        name: 'title',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_MEDIA_NAME_PLACEHOLDER,
    },
    description: {
        id: 'description',
        label: i18n.PT.LABEL_MEDIA_DESCRIPTION,
        name: 'description',
        type: 'textarea',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_MEDIA_DESCRIPTION_PLACEHOLDER,
    },
    file: {
        id: 'file',
        label: 'Arquivo',
        name: 'file',
        type: 'file',
        value: undefined,
        required: true,
    }
};
export const standForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    eventId: {
        id: 'eventId',
        name: 'eventId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    name: {
        id: 'name',
        label: i18n.PT.LABEL_STAND_NAME,
        name: 'name',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_STAND_NAME_PLACEHOLDER,
    },
    subtitle: {
        id: 'subtitle',
        label: i18n.PT.LABEL_STAND_SUBTITLE,
        name: 'subtitle',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_STAND_SUBTITLE_PLACEHOLDER,
    },
    description: {
        id: 'description',
        label: i18n.PT.LABEL_STAND_DESCRIPTION,
        name: 'description',
        type: 'textarea',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_STAND_DESCRIPTION_PLACEHOLDER,
    },
    type: {
        id: 'type',
        label: 'Tipo',
        name: 'type',
        value: 0,
        checked: false,
        required: false,
    },
    facebook: {
        id: 'facebook',
        label: i18n.PT.LABEL_FACEBOOK,
        name: 'facebook',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_EVENT_FACEBOOK_PLACEHOLDER,
    },
    instagram: {
        id: 'instagram',
        label: i18n.PT.LABEL_INSTAGRAM,
        name: 'instagram',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_EVENT_INSTAGRAM_PLACEHOLDER,
    },
    youtube: {
        id: 'youtube',
        label: i18n.PT.LABEL_YOUTUBE,
        name: 'youtube',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_EVENT_YOUTUBE_PLACEHOLDER,
    },
    symposium: {
        id: 'symposium',
        label: 'Link para Simpósio',
        name: 'symposium',
        type: 'text',
        value: '',
        required: false,
        placeholder: 'digite o link do Simposio Satélite',
    },
    negotiation: {
        id: 'negotiation',
        label: 'Sala de negociação',
        name: 'negotiation',
        type: 'text',
        value: '',
        required: false,
        placeholder: 'digite o link da Sala de Negociação',
    },
    website: {
        id: 'website',
        label: 'Url do Website',
        name: 'website',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_EVENT_WEBSITE_PLACEHOLDER,
    },
    image: {
        id: 'image',
        label: 'imagem',
        name: 'image',
        type: 'file',
        value: undefined,
        required: true,
    }
};
export const lectureForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    name: {
        id: 'name',
        label: i18n.PT.LABEL_LECTURE_NAME,
        name: 'name',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_LECTURE_NAME_PLACEHOLDER,
    },
    videoId: {
        id: 'videoId',
        label: 'ID do Vídeo',
        name: 'videoId',
        type: 'text',
        value: '',
        required: true,
        placeholder: 'Preencha com o ID do vídeo da plataforma pré-selecionada',
    },
    description: {
        id: 'description',
        label: i18n.PT.LABEL_LECTURE_DESCRIPTION,
        name: 'description',
        type: 'textarea',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_LECTURE_DESCRIPTION_PLACEHOLDER,
    },
    startDate: {
        id: 'startDate',
        label: i18n.PT.LABEL_LECTURE_DATE,
        name: 'startDate',
        type: 'date',
        value: '',
        required: true,
    },
    startTime: {
        id: 'startTime',
        label: i18n.PT.LABEL_START_TIME,
        name: 'startTime',
        type: 'time',
        value: '',
        required: true,
    },
    endTime: {
        id: 'endTime',
        label: i18n.PT.LABEL_END_TIME,
        name: 'endTime',
        type: 'time',
        value: '',
        required: true,
    },
    roomId: {
        label: i18n.PT.LABEL_ROOM,
        type: 'select',
        value: '',
        data: [],
        placeholder: 'Selecione uma opção',
        inputProps: {
            name: 'room',
            id: 'room',
            required: true
        }
    },
    type: {
        id: 'type',
        name: 'type',
        value: 0,
        checked: false,
        required: true,
    },
    value: {
        id: 'value',
        label: i18n.PT.LABEL_PRICE,
        name: 'value',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_PRICE_PLACEHOLDER,
    },
    discount: {
        id: 'discount',
        label: i18n.PT.LABEL_DISCOUNT,
        name: 'discount',
        type: 'text',
        value: '',
        required: false,
        placeholder: i18n.PT.FORM_DISCOUNT_PLACEHOLDER,
    },
    installments: {
        id: 'installments',
        label: i18n.PT.LABEL_INSTALLMENTS,
        name: 'installments',
        type: 'number',
        value: '',
        required: false,
        data: [],
        placeholder: i18n.PT.FORM_INSTALLMENTS_PLACEHOLDER,
    },
};
export const sponsorForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    eventId: {
        id: 'eventId',
        name: 'eventId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    name: {
        id: 'name',
        label: i18n.PT.LABEL_SPONSOR_NAME,
        name: 'name',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.LABEL_SPONSOR_NAME_PLACEHOLDER,
    },
    type: {
        label: i18n.PT.LABEL_SPONSOR_TYPE,
        type: 'select',
        value: undefined,
        data: [
            {value: 'master', label: i18n.PT.LABEL_MASTER},
            {value: 'premium', label: i18n.PT.LABEL_PREMIUM},
            {value: 'supporter', label: i18n.PT.LABEL_SUPPORTER}
        ],
        required: true,
        inputProps: {
            name: 'type',
            id: 'type',
            required: true
        }
    },
    image: {
        id: 'image',
        label: 'imagem',
        name: 'image',
        type: 'file',
        value: undefined,
        required: true,
    }
};
export const couponForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    eventId: {
        id: 'eventId',
        name: 'eventId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    code: {
        id: 'code',
        label: i18n.PT.LABEL_COUPON_CODE,
        name: 'code',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_COUPON_CODE_PLACEHOLDER,
    },
    value: {
        id: 'value',
        label: i18n.PT.LABEL_COUPON_VALUE,
        name: 'value',
        type: 'number',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_COUPON_VALUE_PLACEHOLDER,
    },
    percent: {
        id: 'percent',
        label: i18n.PT.LABEL_COUPON_PERCENT,
        name: 'percent',
        type: 'number',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_COUPON_PERCENT_PLACEHOLDER,
    },
    amount: {
        id: 'amount',
        label: i18n.PT.LABEL_COUPON_AMOUNT,
        name: 'amount',
        type: 'number',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_COUPON_AMOUNT_PLACEHOLDER,
    },
    validateDate: {
        id: 'validateDate',
        label: i18n.PT.LABEL_COUPON_VALIDADE_DATE,
        name: 'validateDate',
        type: 'date',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_COUPON_VALIDADE_DATE_PLACEHOLDER,
    },
};
export const productForm = {
    formError: false,
    editing: false,
    id: {
        id: 'id',
        name: 'id',
        type: 'hidden',
        value: undefined,
        required: true,
        hidden: true,
    },
    standId: {
        id: 'standId',
        name: 'standId',
        type: 'hidden',
        value: '',
        required: true,
        hidden: true,
    },
    title: {
        id: 'title',
        label: i18n.PT.LABEL_PRODUCT_TITLE,
        name: 'title',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_PRODUCT_TITLE_PLACEHOLDER,
    },
    description: {
        id: 'description',
        label: i18n.PT.LABEL_PRODUCT_DESCRIPTION,
        name: 'description',
        type: 'textarea',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_PRODUCT_DESCRIPTION_PLACEHOLDER,
    },
    image: {
        id: 'image',
        label: 'imagem',
        name: 'image',
        type: 'file',
        value: undefined,
        renderValue: undefined,
        required: true,
    },
    value: {
        id: 'value',
        label: i18n.PT.LABEL_PRICE,
        name: 'value',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_PRICE_PLACEHOLDER,
    },
    discount: {
        id: 'discount',
        label: i18n.PT.LABEL_DISCOUNT,
        name: 'discount',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_DISCOUNT_PLACEHOLDER,
    },
    installments: {
        id: 'installments',
        label: i18n.PT.LABEL_INSTALLMENTS,
        name: 'installments',
        type: 'number',
        value: '',
        required: true,
        data: [],
        placeholder: i18n.PT.FORM_INSTALLMENTS_PLACEHOLDER,
    },
    callToActionLabel: {
        id: 'callToActionLabel',
        label: i18n.PT.LABEL_CALL_TO_ACTION_LABEL,
        name: 'callToActionLabel',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_CALL_TO_ACTION_LABEL_PLACEHOLDER,
    },
    callToAction: {
        id: 'callToAction',
        label: i18n.PT.LABEL_CALL_TO_ACTION,
        name: 'callToAction',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.PT.FORM_CALL_TO_ACTION_PLACEHOLDER,
    },
};
