import React from 'react';

import './event-card.styles.scss';

import DummyBg from '../../assets/images/dummy-card.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

interface EventCardProps {
    language: any;
    onClick: (key?: any) => any;
    item: any;
}

export const MONTH = {
    PT: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    EN: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    AL: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
}

class EventCard extends React.Component<EventCardProps> {
    render() {
        const timeArray = this.props.item.startDate.split('-');
        return (
            <div className={"box-evento"} onClick={() => this.props.onClick(this.props.item)}>
                {this.props.item.thumb ? 
                    <figure>
                        <img src={this.props.item.thumb} title={this.props.item.slug}/>
                    </figure>
                : 
                    <figure>
                        <img src={DummyBg} height="144" style={{borderRadius: 8}}/>
                    </figure>
                }
                <div className={'metadata-box'}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <div className={"box-data"}>
                                    <h5><FontAwesomeIcon icon={faCalendarAlt} className={'fa'} /> {MONTH[this.props.language][timeArray[1] - 1]}</h5>
                                    <h3>{timeArray[2]}</h3>
                                </div>

                            </div>
                            <div className={"col-8"}>
                                <div className={"box-desc"}>
                                    <h1 title={this.props.item.name}>{this.props.item.name}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FontAwesomeIcon icon={faAngleRight} className={'fa-action'} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, root } = state;
    const appText = root.appText;
    return { auth, appText, language: root.language };
}

export default connect(mapStateToProps)(EventCard);