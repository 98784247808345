
export default class Configs {
  public static BASE_URL = 'https://api.evento.st.tvg.net.br';
  // public static BASE_URL = 'http://localhost:3030';
  public static PAYPAL_CLIENT_ID = 'sb';
  public static ENDPOINTS = {
    AUTH: {
      CLIENT: {
        LOGIN: "/auth/login",
        SOFT_LOGIN: "/auth/soft/login",
        SEND_AUTH_TOKEN: "/auth/check/sms",
        LOGOUT: "/auth/logout",
        RECOVER_PASSWORD: "/auth/password/recovery",
        CHANGE_PASSWORD: "/auth/password/change",
      },
      USER: {
        LOGIN: "/auth/login",
        SEND_AUTH_TOKEN: "/auth/check/sms",
        LOGOUT: "/auth/logout",
        RECOVER_PASSWORD: "/auth/password/recovery",
        CHANGE_PASSWORD: "/auth/password/change",
      }
    },
    CLIENTS: "/api/cms/v1/clients",
    USERS: "/api/cms/v1/users",
    EVENTS: "/api/cms/v1/events",
    CATEGORIES: "/api/cms/v1/categories",
    ROOMS: "/api/cms/v1/rooms",
    SPONSORS: "/api/cms/v1/sponsors",
    LECTURES: "/api/cms/v1/lectures",
    STANDS: "/api/cms/v1/stands",
    SPEAKERS: "/api/cms/v1/speakers",
    PRODUCT: "/api/cms/v1/services",
    MEDIAS: "/api/cms/v1/medias",
    COUPONS: "/api/cms/v1/cupons",
    LOGS: "/api/cms/v1/logs",
    ZOOM: "/api/cms/v1/getSignature",
    CMS: {
      DASH_GENERAL: "/api/cms/v1/dashboards/general",
      DASH_EVENT: "/api/cms/v1/dashboards/event",
      MEDIAS: "/api/cms/v1/medias",
      PRODUCT: "/api/cms/v1/services",
      STANDS: "/api/cms/v1/stands",
      SPEAKERS: "/api/cms/v1/speakers",
      SPONSORS: "/api/cms/v1/sponsors",
      COUPONS: "/api/cms/v1/cupons",
      CATEGORIES: "/api/cms/v1/categories",
      CLIENTS: "/api/cms/v1/clients",
      EVENTS: "/api/cms/v1/events",
      ROOMS: "/api/cms/v1/rooms",
      LECTURES: "/api/cms/v1/lectures",
      ORDERS: "/api/cms/v1/orders",
      LANGUAGE: "/api/cms/v1/languages"
    },
    SITE: {
      NETWORKS: "/api/site/v1/networks",
      MEDIAS: "/api/site/v1/medias",
      PRODUCT: "/api/site/v1/services",
      STANDS: "/api/site/v1/stands",
      SPEAKERS: "/api/site/v1/speakers",
      SPONSORS: "/api/site/v1/sponsors",
      COUPONS: "/api/site/v1/cupons",
      CATEGORIES: "/api/site/v1/categories",
      CONTACTS: "/api/site/v1/contacts",
      CLIENTS: "/api/site/v1/clients",
      EVENTS: "/api/site/v1/events",
      ROOMS: "/api/site/v1/rooms",
      LECTURES: "/api/site/v1/lectures",
      PAYMENTS: "/api/site/v1/payments",
    }
  };
}
