import React from 'react';
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { useRowStyles } from "./row.styles";

export default function RoomRow(props: { row: any, editRoom: (row: any) => any, removeRoom: (id: any) => any}){
    const { row, editRoom, 
        // removeRoom 
    } = props;
    // const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell >{row.name}</TableCell>
                <TableCell >{row.link}</TableCell>
                <TableCell align="center">
                    <IconButton
                        title={'Editar Sala'}
                        aria-label="expand row"
                        onClick={() => editRoom(row)}>
                        <EditIcon />
                    </IconButton>
                    {/* <IconButton 
                        title={'Remover Sala'}
                        aria-label="expand row"
                        onClick={() => removeRoom(row)}
                        className={classes.actionButton}>
                        <DeleteIcon />
                    </IconButton> */}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}