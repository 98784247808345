import React from 'react';

import {
  Route,
  Switch,
  useRouteMatch,
  withRouter
} from "react-router-dom";

import LoginCmsPage from './login/login-cms.component';
import PasswordRecoveryCmsPage from './login/password-recovery-cms.component';
import PasswordChangeCmsPage from './login/password-change-cms.component';
import CmsPage from './cms-page.component';

import PublicRoute from '../../Components/ControlAccess/public.component';
import PrivateRoute from '../../Components/ControlAccess/private.component';

import ForbiddenPage from '../40X/403.component'

const CmsRoutes = (props) => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/nao-autorizado`} exact component={ForbiddenPage} />
            <PublicRoute path={`${path}/login`} isCms={true}>
                <LoginCmsPage />
            </PublicRoute>
            <PublicRoute path={`${path}/password-recovery`} isCms={true}>
              <PasswordRecoveryCmsPage />
            </PublicRoute>
            <PublicRoute path={`${path}/password-change`} isCms={true}>
              <PasswordChangeCmsPage />
            </PublicRoute>
            <PrivateRoute path={`${path}`} isCms={true}>
                <CmsPage/>
             </PrivateRoute>
        </Switch>
    );
};

export default withRouter(CmsRoutes);