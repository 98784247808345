import React from 'react';
import {
    withRouter
} from "react-router-dom";

// import Congresso from '../../../../../assets/images/platform/help-desk.jpg';
import './help-desk.component.scss';
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";

interface HelpDeskPageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface HelpDeskPageState {
    rooms: any[];
}

class HelpDeskPage extends React.Component<HelpDeskPageProps, HelpDeskPageState> {

    render() {
        return (
            <div id="hall-help-desk">
                <section className={`container-hall-stands`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/help-desk.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className={`tv left-tv`}>
                                <a target="_blank" href="https://web.whatsapp.com/send?phone=5511954280025"></a>
                            </div>
                            <div className={`tv middle-tv`}>
                                <a target="_blank" href="https://sistemaparaevento.com.br/evento/ecbmi2020/programacao/gradeatividades"></a>
                            </div>
                            <div className={`tv right-tv`}>
                                <a target="_blank" href="https://d2t970vtndlymz.cloudfront.net/documents/mapa.pdf"></a>
                            </div>
                        </div>
                    </div>
		        </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(HelpDeskPage));