import React, { Component } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Input,
    IconButton
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ChatService from '../../../Services/chat/chat.service';

const baseMessageStyle = {
    fontWeight: 500
}

interface ChatModalState {
    standId: number,
    clientId: number,
    clientName: string,
    isOpen: boolean,
    message: string,
    history: Array<any>
}

class ChatModal extends Component<any, ChatModalState> {
    _chatService: ChatService;
    _messagesEndRef: any;

    constructor(props) {
        super(props);
        this._chatService = new ChatService();
        this.state = {
            standId: props.standId,
            clientId: props.client.id,
            clientName: props.client.name,
            isOpen: props.isOpen,
            message: '',
            history: []
        }
    }

    componentDidMount = () => {
        const { standId, clientId } = this.state;
        this._chatService.connect('user', standId, clientId, this._handleIncomingMessage);
        if (this._messagesEndRef) this._scrollToBottom();
    }

    componentDidUpdate = prevProps => {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
        }
    }

    _handleIncomingMessage = (message, senderType) => {
        this.setState({ history: [...this.state.history, { text: message, senderType }] });
        this._scrollToBottom();
    }

    _scrollToBottom = () => {
        this._messagesEndRef.scrollIntoView({ behavior: 'smooth' });
    }

    _onTextChange = event => {
        this.setState({ message: event.target.value });
    }

    _onSendMessage = () => {
        if (this._chatService.isConnected() && !!this.state.message.trim()) {
            this._chatService.send(this.state.message.trim(), 'stand');
            this.setState({ message: '' });
        }
    }

    _onCloseModal = () => {
        this._chatService.disconnect();
        this.props.handleCloseModal();
    }

    _onInputKeyPress = event => {
        if (event.key === 'Enter') this._onSendMessage();
    }

    _whoSays = senderType =>
        (senderType === 'client')
            ? 'Cliente'
            : (senderType === 'stand')
            ? 'Você'
            : 'Alguém';

    _messageStyle = senderType =>
        senderType === 'client'
            ? ({ ...baseMessageStyle, color: '#3a0b00' })
            : ({ ...baseMessageStyle, color: '#F1654C' })

    _renderHistory = () => {
        const { history } = this.state;
        return history.map((msg, i) => (
            <div key={i}>
                <span style={this._messageStyle(msg.senderType)}>{this._whoSays(msg.senderType)}: </span>
                <span>{msg.text}</span>
            </div>
        ));
    }

    render() {
        return (
            <Dialog
                open={this.state.isOpen}
                onClose={this._onCloseModal}
                scroll="paper"
                fullWidth
                maxWidth="xs"
                aria-labelledby="Chat"
                aria-describedby={`Chat com ${this.state.clientName || 'cliente'}`}
            >
                    <DialogTitle>
                        {`Conversa com ${this.state.clientName || 'cliente'}`}
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            {this._renderHistory()}
                            <div ref={el => (this._messagesEndRef = el)}></div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Input
                            onChange={this._onTextChange}
                            value={this.state.message}
                            onKeyPress={this._onInputKeyPress}
                            placeholder="Digite uma mensagem"
                            style={{ width: '100%' }}
                        />
                        <IconButton onClick={this._onSendMessage} color="primary">
                            <SendIcon />
                        </IconButton>
                    </DialogActions>
            </Dialog>
        );
    }
}

export default ChatModal;
