import {
  SET_SCREEN_DIMENSIONS,
  SET_LANGUAGE
} from "../actions/root.actions";
// import { ISLToastPositions } from "../../enums/toast-positions.enum";
// import { ISLToastInterface } from "../../interfaces/isl-toast.interface";

export enum ScreenSizesEnum {
  xs,
  sm,
  md,
  lg,
  xl,
}

export interface DimensionInterface {
  height: number, width: number
}

export enum LanguageEnum {
  PT = 'PT', EN = 'EN', AL = 'AL'
}

export interface IAppState {
  dimensions: DimensionInterface;
  screenSize?: ScreenSizesEnum;
  language: LanguageEnum;
  appText: any | undefined;
}

const appState: IAppState = {
  dimensions: { height: 0, width: 0 },
  screenSize: ScreenSizesEnum.xs,
  language: LanguageEnum.PT,
  appText: undefined
};

export default (state = appState, action: any) => {
  switch (action.type) {

    case SET_SCREEN_DIMENSIONS:
      const { xs, sm, md, lg, xl } = ScreenSizesEnum;
      const { height, width } = action;

      let screenSize = xs;

      if (width <= 359) {
        screenSize = xs;
      } else if (width <= 639) {
        screenSize = sm;
      } else if (width <= 1023) {
        screenSize = md;
      } else if (width <= 1439) {
        screenSize = lg;
      } else {
        screenSize = xl;
      }
      return {
        ...state,
        dimensions: { height, width },
        screenSize
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
        appText: action.payload.response
      }
    default:
      return state;
  }
};
