export class PhoneUtil {
    static parsePhone(phone: string) {
        if(phone) {
            const rawPhone = phone.replace(/([+\(\) -])/g,'');
            return {
                ddd: rawPhone.substr(0,2),
                phone: rawPhone.substr(2)
            }
        }
        return {
                ddd: '',
                phone: ''
            }
    }

    static parseFullString(ddd, phone, ddi) {
        return `${ddi ? '+'+ddi+' ' : ' '}(${ddd}) ${phone}`;
    }
    static parseString(ddd, phone) {
        return `(${ddd}) ${phone}`;
    }
}