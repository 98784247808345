import React from 'react';

import {
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ children, auth, isCms, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (isCms && auth.userAuthenticated) || (!isCms && auth.clientAuthenticated) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/" +(isCms ? 'cms/login' : ''),
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
}

export default connect(mapStateToProps)(PrivateRoute);;