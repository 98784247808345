import React, { Component } from 'react'
import { connect } from "react-redux";

import './global-loader.component.scss';


import Loader from '../UI/Loader/loader.component';
import { AppStore } from "../../Interface/app-store.interface";

interface GlobalLoaderProps {
    loader: any;
}

class GlobalLoader extends Component<any, GlobalLoaderProps> {
    render() {
        const {active} = this.props.loader;
        return (
            !!active ?
            <div className={'GlobalLoader'}>
                <div className={'overlay'}/>
                <Loader />
            </div> : null
        )
    }
}

const mapStateToProps = (store: AppStore) => {
    const { loader } = store.layout;

    return { loader }
}

export default connect(mapStateToProps)(GlobalLoader);