import React, {Component} from 'react';
import {
  withRouter
} from "react-router-dom";
import InputMask from "react-input-mask";

import i18n from '../../../Utils/i18n';

import '../../../assets/styles/objects/table.styles.scss'

import MaterialTable from "material-table";
import { Grid, IconButton, Modal, Card, CardContent, CardActions, Button, CardHeader, 
    FormControl, Select, InputLabel, 
    TextField } from "@material-ui/core";

import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { fetchRegister } from "../../../Redux/actions/auth.actions";
import { DDIs } from "../../../Utils/ddi";
import { PhoneUtil } from "../../../Utils/phone.util";
import { Alert, Autocomplete } from "@material-ui/lab";
import UserService from "../../../Services/user/user.service";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../Redux/actions/layout.actions";

// import ResultTableActions from '../../../Components/UI/TableActions/table-actions.component'

interface UsersPageProps {
    appText: any;
    onFetchRegister: (data: any) => any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface UsersPageState {
    rows: any[],
    openModal: boolean,
    registerForm: {[key: string]: any};
    submiting: boolean;
    formError: string;
    cities: string[];
    editing: boolean;
    limit: number;
    offset: number;
    count: number;
    query: {
        pageSize: number,
        page: number,
        search: string,
        orderBy: {
            field: string,
            orderDirection: 'asc' | 'desc'
        } | undefined
    }
    // order: string;
}

class UsersPage extends Component<UsersPageProps, UsersPageState> {
    _userService: UserService;
    tableRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this._userService = new UserService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            rows: [],
            cities: [],
            openModal: false,
            query: {
                pageSize: 20,
                page: 0,
                search: '',
                orderBy : undefined
            },
            registerForm: {
                id: {
                    name: 'id',
                    type: 'hidden',
                    value: '',
                    required: true,
                    hidden: true,
                },
                name: {
                    label: i18n.PT.LABEL_FULLNAME,
                    name: 'nome',
                    type: 'text',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_NAME_PLACEHOLDER,
                },
                email: {
                    label: i18n.PT.LABEL_EMAIL,
                    name: 'email',
                    type: 'text',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_EMAIL_PLACEHOLDER,
                },
                ddi: {
                    label: 'DDI',
                    name: 'ddi',
                    type: 'select',
                    value: '',
                    required: true,
                    data: DDIs,
                    placeholder: 'Selecione uma opção',
                },
                dddPhone: {
                    label: 'Telefone',
                    name: 'dddPhone',
                    type: 'text',
                    value: '',
                    mask: '(99) 9 9999-9999',
                    required: true,
                    placeholder: i18n.PT.FORM_EMAIL_PLACEHOLDER,
                },
                role: {
                    label: i18n.PT.LABEL_ROLE,
                    name: 'role',
                    type: 'select',
                    value: '',
                    required: true,
                    data: [{value: 'admin', label: 'Administrador'},{value: 'attendant', label: 'Atendente'}]
                },
            },
            submiting: false,
            formError: '',
            editing: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
    }

    fetchUsers(query?) {
        if(query !== undefined){
            this.setState({
                query
            })
        } else {
            query = this.state.query;
        }
        const data: {limit: number, offset: number, search: string, order?: string} = {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            search: query.search
        }
        if(query.orderBy) {
            data.order = query.orderBy.field + ':' + query.orderDirection
        }
        return this._userService.getUsers(data).then((response) => {
            this.setState({rows: response.rows, count: response.count});
            return {
                data: response.rows,
                page: query.page,
                totalCount: response.count
            }
        })
    }

    handleChange(element: string, value: any) {
        this.setState((getState: UsersPageState) => {
            return {
                registerForm: {
                    ...getState.registerForm,
                    [element]: { ...getState.registerForm[element], value }
                }
            }
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: parseInt(this.state.registerForm.id.value),
            name: this.state.registerForm.name.value,
            email: this.state.registerForm.email.value,
            ddi: ''+parseInt(this.state.registerForm.ddi.value.fone),
            ...PhoneUtil.parsePhone(this.state.registerForm.dddPhone.value),
            role: this.state.registerForm.role.value
        }
        if(!this.state.editing) {
            delete data.id;
        }
        this.props.onShowLoader();
        this._userService.setUser(data, this.state.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.setState({
                openModal: false
            });
            if (this.tableRef.current) {
                this.tableRef.current.onQueryChange();
            }
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState({
                    formError: this.props.appText[error.msg]
                })
            }
        })

    }

    _removeSelected(users: any | any[]) {
        this.props.onShowDialog({
            handlePositive: () => {
                const userIds = users.map((user) => user.id)
                this._userService.removeUsers(userIds).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    if (this.tableRef.current) {
                        this.tableRef.current.onQueryChange();
                    }
                })
                .catch((error) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'warning',
                        message: this.props.appText[error.msg]
                    });
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o(s) usuário(s) selecionado(s)"?`
        })
    }

    render () {
        const { openModal, registerForm, formError, editing } = this.state;
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            columns={[
                                { title: "#", field: "id", headerStyle: {maxWidth: '20px', minWidth: '20px'}, cellStyle: {width: '5%'}},
                                { title: "Nome", field: "name", headerStyle: {width: '20%'} },
                                { title: "Email", field: "email", headerStyle: {width: '10%'} },
                                { title: "Telefone", field: "dddFullPhone", headerStyle: {width: '10%'} },
                                { title: "Telefone", field: "dddPhone", headerStyle: {width: '10%'}, hidden: true },
                                { title: "Nível de permissão", field: "roleLabel", headerStyle: {width: '10%'} },
                                { title: "ddi", field: "ddi", headerStyle: {width: '10%'}, hidden: true },
                                { title: "role", field: "role", headerStyle: {width: '10%'}, hidden: true },
                                { title: "Criado em", field: "createdAt", headerStyle: {width: '10%'} }
                            ]}
                            options={{
                                sorting: true,
                                search: true,
                                searchFieldVariant: 'outlined',
                                pageSize: this.state.limit,
                                initialPage: this.state.offset,
                                paginationType: 'stepped',
                                pageSizeOptions: [10,20,50,100],
                                selection: true
                            }}
                            localization={{
                                'toolbar': {
                                    nRowsSelected: '{0} usuário(s) selecionado(s)',
                                    searchPlaceholder: 'Nome do usuário'
                                }
                            }}
                            style={{
                            paddingTop: 8,
                        }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Adicionar Usuário',
                                    isFreeAction: true,
                                    onClick: () => {
                                        let newForm = {...this.state.registerForm};
                                        for (var key in newForm) {
                                            if (newForm.hasOwnProperty(key)) {
                                                newForm[key].value = '';
                                                
                                            }
                                        }
                                        this.setState({
                                            registerForm: newForm,
                                            openModal: true,
                                            editing: false
                                        })
                                    }
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Remover usuários selecionadas',
                                    onClick: (evt, data: any | any[]) => this._removeSelected(data)
                                }
                            ]}
                            onRowClick={(event, rowData) => {
                                let newForm = {...this.state.registerForm};
                                if(rowData) {
                                    for (var key in newForm) {
                                        if (newForm.hasOwnProperty(key) && key !== 'ddi') {
                                            newForm[key].value = rowData[key];
                                            
                                        }
                                        if(key == 'ddi') {
                                            newForm[key].value = DDIs.filter((item) => parseInt(item.fone) == rowData[key])[0];
                                        }
                                    }
                                    this.setState({
                                        registerForm: newForm,
                                        openModal: true,
                                        editing: true,
                                        formError: ''
                                    })
                                }
                            }}
                            data={this.fetchUsers}
                            title={<p className={'title-table'}><GroupIcon fontSize={'large'}/>Usuários</p>}
                        />
                    </Grid>
                </Grid>
                <Modal
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    open={openModal}
                    onClose={() => this.setState({
                            openModal: false
                        })
                    }
                    aria-labelledby="adicionar usuários"
                    aria-describedby="adicionar usuários"
                    >
                    <Card style={{minWidth: '25%', maxHeight: '70%', overflowY: 'scroll'}} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={editing ? "Editando Usuário" :"Adicionar Usuário"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <CardContent>
                            {formError !== '' ? <Alert severity="error">{formError}</Alert> : null}
                            <Grid container>
                                <Grid item xs={12}>
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <TextField
                                            id={registerForm.name.name}
                                            label={registerForm.name.label}
                                            required={registerForm.name.required}
                                            type={registerForm.name.type}
                                            value={registerForm.name.value}
                                            placeholder={registerForm.name.placeholder}
                                            fullWidth
                                            error={!!formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('name', event.target.value)}
                                            variant="outlined"
                                        />
                                        <TextField
                                            id={registerForm.email.name}
                                            label={registerForm.email.label}
                                            required={registerForm.email.required}
                                            type={registerForm.email.type}
                                            value={registerForm.email.value}
                                            placeholder={registerForm.email.placeholder}
                                            fullWidth
                                            error={!!formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('email', event.target.value)}
                                            variant="outlined"
                                        />
                                         <Grid container spacing={2}>
                                            <Grid item xs={5} >
                                                <FormControl variant="outlined" margin={'normal'} fullWidth>
                                                    <Autocomplete
                                                        options={registerForm.ddi.data}
                                                        value={registerForm.ddi.value}
                                                        openOnFocus
                                                        fullWidth
                                                        onChange={(event: any, newValue: any | null) => {
                                                            this.handleChange('ddi', newValue);
                                                        }}
                                                        getOptionSelected={(option: any, value: any) => option.codigo === value.codigo}
                                                        getOptionLabel={(option) => option.codigo ? `(+${parseInt(option.fone)}) ${option.nome}` : ''}
                                                        renderInput={(params) => 
                                                            <TextField 
                                                                {...params}
                                                                name={registerForm.ddi.name}
                                                                id={registerForm.ddi.name}
                                                                required={registerForm.ddi.required}
                                                                fullWidth
                                                                label="ddi"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    fullWidth: true,
                                                                    autoComplete:"new-password"
                                                                }}
                                                                />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={7} >
                                                <InputMask
                                                    {...registerForm.dddPhone}
                                                    id={registerForm.dddPhone.name}
                                                    fullWidth
                                                    onChange={(event) => this.handleChange('dddPhone', event.target.value)}
                                                    placeholder={registerForm.dddPhone.placeholder} >
                                                    <TextField
                                                        error={!!formError}
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </InputMask>
                                            </Grid>
                                        </Grid>
                                        <FormControl variant="outlined" margin={'normal'} fullWidth>
                                            <InputLabel htmlFor={registerForm.role.name}>{registerForm.role.label} *</InputLabel>
                                            <Select
                                                native
                                                value={registerForm.role.value}
                                                label={`${registerForm.role.label} *`}
                                                onChange={(event) => this.handleChange('role', event.target.value)}
                                                error={!!formError}
                                                fullWidth
                                                inputProps={{
                                                    name: registerForm.role.name,
                                                    id: registerForm.role.name,
                                                    required: registerForm.role.required,
                                                    error: !!formError
                                                }}
                                            >

                                                <option aria-label="None" value="''"/>
                                                {registerForm.role.data.map((item, index) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </form>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div style={{padding: '0px 16px 16px'}}>
                                <Button  size="large" onClick={this.handleSubmit} variant="contained" color="primary">
                                    { editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE }
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Modal>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
  return { 
    onFetchRegister: (data) => dispatch(fetchRegister(data, 'CMS', 'user')),
    onShowToast: (data) => dispatch(showToast(data)),
    onShowDialog: (data) => dispatch(showDialog(data)),
    onHideDialog: () => dispatch(hideDialog()),
    onShowLoader: () => dispatch(showLoader()),
    onHideLoader: () => dispatch(hideLoader())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UsersPage));