
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

export default class RoomService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getRooms(data: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].ROOMS, data).then((responseData) => {
            return responseData.data;
        });
    }
    
    getRoom(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.ROOMS+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }

    getDateList(id: number, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(`https://d2t970vtndlymz.cloudfront.net/rooms/${id}.json`).then((responseData) => {
            return responseData;
        })
    }

    setRoom(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';
        let formData = new FormData();
        for (var key in data) {
            if(key === 'image') {
                formData.append(key, data[key]);
            } else {
                formData.set(key, data[key]);
            }
        }
        return this._apiService[method](Configs.ENDPOINTS.ROOMS + (editing ? `/${data.id}` : ''), formData, true).then((responseData) => {
            return responseData;
        })
    }

    removeRoom(roomIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.ROOMS, {ids: roomIdArray}).then((responseData) => {
            return responseData;
        })
    }
}