import React from 'react';
import {
    withRouter
} from "react-router-dom";

import RoomService from "../../../../../Services/room/room.service";

// import Congresso from '../../../../../assets/images/platform/amib-em-cena.jpg';
import './amib-em-cena.component.scss';
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";

interface AmibEmCenaPageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface AmibEmCenaPageState {
    animating: boolean;
    rooms: any[];
}

class AmibEmCenaPage extends React.Component<AmibEmCenaPageProps, AmibEmCenaPageState> {
    roomService: RoomService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.roomService = new RoomService();
        this.state = {
            animating: false,
            rooms: [],
        }
    }

    componentDidMount() {
        this.fetchRooms();
    }

    fetchRooms() {
        this.roomService.getRooms({eventId: this.props.match.params.slug.split('-')[0], offset: 11, limit: 5}, 'SITE').then((responseData) => {
            let rooms = [...this.state.rooms];
            for (var index = 0; index < responseData.rows.length; index++) {
                rooms.push({number: index + 1, ...responseData.rows[index]});
            }
            this.setState({
                rooms
            })
            setTimeout(() => {
                this.setState({
                    animating: false
                })
            }, 1000);
        });
    }

    goToStand(standId) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/estande/${standId}`);
    }

    componentWillUnmount() {
        this.setState((state) => ({
            animating: true
        }));
    }
    

    render() {
        return (
            <div id="hall-amib-em-cena">
                <section className={`container-hall-stands ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/amib-em-cena.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                             {this.state.rooms.map((room) => {
                                return <div className={`door door-${room.number}`}>
                                    <a href={room.link} target="_blank" title={room.name}></a>
                                </div>
                            })}
                        </div>
                    </div>
		        </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(AmibEmCenaPage));