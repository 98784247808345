export const loginForm = (i18n: any = {}) => ({
    formError: '',
    Email: {
        label: i18n.LABEL_EMAIL,
        name: 'email',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.FORM_EMAIL_PLACEHOLDER,
    },
    Password: {
        label: i18n.LABEL_PASSWORD,
        name: 'password',
        type: 'password',
        value: '',
        required: true,
        placeholder: i18n.FORM_PASSWORD_PLACEHOLDER,
    }
});

export const mfaForm = (i18n) => ({
    formError: '',
    Code: {
        label: i18n.LABEL_AUTH_CODE,
        name: 'code',
        type: 'password',
        value: '',
        required: true,
        placeholder: i18n.FORM_MFACODE_PLACEHOLDER,
    }
})

export const passwordRecoveryForm: any = (i18n) => ({
    formError: '',
    Email: {
        label: i18n.LABEL_EMAIL,
        name: 'email',
        type: 'text',
        value: '',
        required: true,
        placeholder: i18n.FORM_EMAIL_PLACEHOLDER,
    }
});

export const passwordChangeForm: any = (i18n) => ({
    formError: '',
    Password: {
        label: 'Nova senha',
        name: 'password1',
        type: 'password',
        value: '',
        required: true,
        placeholder: i18n.FORM_PASSWORD_PLACEHOLDER,
    },
    PasswordConfirmation: {
        label: 'Confirme sua senha',
        name: 'password2',
        type: 'password',
        value: '',
        required: true,
        placeholder: i18n.FORM_CONFIRM_PASSWORD_PLACEHOLDER,
    },
    Code: {
        label: i18n.LABEL_AUTH_CODE,
        name: 'code',
        type: 'password',
        value: '',
        required: true,
        placeholder: i18n.FORM_MFACODE_PLACEHOLDER,
    }
});
