import {
  HIDE_TOAST,
  SHOW_TOAST,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_DIALOG,
  HIDE_DIALOG,
} from "../actions/layout.actions";

export interface ToastInterface {
  show: boolean,
  type: 'error' | 'warning' | 'info' | 'success'
  handleClose: null | ((key?: any) => any),
  message: string,
  timeout: number
}

export interface DialogInterface {
  open: boolean,
  textHeader: string,
  textBody: string,
  handleClose: (any: any) => any,
  handleNegative: (any: any) => any,
  handlePositive: (any: any) => any,
  negativeButton: string,
  positiveButton: string,
}

export interface ILayoutState {
  toast: ToastInterface;
  dialog: DialogInterface;
  loader: {active: boolean} | null;
}

const appState: ILayoutState = {
  toast: {
    show: false,
    type: 'info',
    handleClose: () => null,
    message: '',
    timeout: 3000
  },
  dialog: {
    open: false,
    textHeader: '',
    textBody: '',
    handleClose: () => null,
    handleNegative: () => null,
    handlePositive: () => null,
    negativeButton: 'Não',
    positiveButton: 'Sim',
  },
  loader: {
    active: false
  },
};

export default (state = appState, action: any) => {
  switch (action.type) {

    case SHOW_TOAST:
      return {
        ...state,
        toast: {
          ...appState.toast,
          ...action.toast
        }
      };

    case HIDE_TOAST:
      return {
        ...state,
        toast: appState.toast
      };

    case SHOW_LOADER:
      return {
        ...state,
        loader: { active: true }
      };

    case HIDE_LOADER:
      return {
        ...state,
        loader: { active: false }
      };

    case SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          ...appState.dialog,
          ...action.dialog,
          open: true
        }
      };

    case HIDE_DIALOG:
      return {
        ...state,
        dialog: appState.dialog
      };

    default:
      return state;
  }
};
