
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";
import { PhoneUtil } from "../../Utils/phone.util";
import { TimeUtils } from "../../Utils/time";

import parse from 'html-react-parser';

enum roles {
    attendant = 'Atendente',
    admin = 'Administrador'
}
        
export default class UserService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getUsers(data?: any) {
        return this._apiService.get(Configs.ENDPOINTS.USERS, data).then((responseData) => {
            responseData.data.rows.map((item, index) => {
                responseData.data.rows[index].roleLabel = roles[item.role];
                item['dddFullPhone'] = PhoneUtil.parseFullString(item.ddd, item.phone, item.ddi);
                item['dddPhone'] = PhoneUtil.parseString(item.ddd, item.phone);
                item['createdAt'] = TimeUtils.timestampToMomentTamplate(item.createdAt);
            })
            return responseData.data;
        })
    }
    getUser(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.USERS+'/'+id).then((responseData) => {
            responseData.data.roleLabel = roles[responseData.data.role];
            responseData.data['dddFullPhone'] = PhoneUtil.parseFullString(responseData.data.ddd, responseData.data.phone, responseData.data.ddi);
            responseData.data['dddPhone'] = PhoneUtil.parseString(responseData.data.ddd, responseData.data.phone);
            responseData.data['createdAt'] = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            return responseData.data;
        })
    }

    setUser(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';


        return this._apiService[method](Configs.ENDPOINTS.USERS + (editing ? `/${data.id}` : ''), data).then((responseData) => {
            return responseData;
        })
    }

    removeUsers(userIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.USERS, {ids: userIdArray}).then((responseData) => {
            return responseData;
        })
    }

    me() {
        return this._apiService.get(Configs.ENDPOINTS.USERS + '/me').then((responseData) => {
            responseData.data.roleLabel = roles[responseData.data.role];
            responseData.data['dddFullPhone'] = PhoneUtil.parseFullString(responseData.data.ddd, responseData.data.phone, responseData.data.ddi);
            responseData.data['dddPhone'] = PhoneUtil.parseString(responseData.data.ddd, responseData.data.phone);
            responseData.data['createdAt'] = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            return responseData.data;
        })
    }

    getUserLanguage(language) {
        const endpoint = `https://d2t970vtndlymz.cloudfront.net/i18n/${language}.json?v=${Date.now()}`;
        return this._apiService.externalGet(endpoint).then((responseData) => {
            for (var key in responseData) {
                if (responseData.hasOwnProperty(key)) {
                    responseData[key] = parse(responseData[key]);
                }
            }
            return responseData;
        })
    }
    setUserLanguage(language, file) {
        let formData = new FormData();
        formData.append('file', file);
        return this._apiService.put(Configs.ENDPOINTS.CMS.LANGUAGE + '/'+ language, formData, true).then((responseData) => {
            return responseData;
        })
    }
}
