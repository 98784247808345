import React from 'react';
import {
    withRouter
} from "react-router-dom";

// import StandEvento from '../../../../../assets/images/platform/stand-evento-master.jpeg';
// import StandEventoMobile from '../../../../../assets/images/platform/stand-evento-prata.jpeg';

import './stand.component.scss';
import EventsService from "../../../../../Services/events/events.service";
import { Modal } from "@material-ui/core";
import StandService from "../../../../../Services/stand/stand.service";
import ProductService from "../../../../../Services/product/product.service";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaService from "../../../../../Services/media/media.service";
import { AppStore } from "../../../../../Interface/app-store.interface";
import { connect } from "react-redux";

import ChatWidget from '../../../../Site/Chat/chat-widget.component';
import { showToast, showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { toggleWidget } from "react-chat-widget";
import ContactService from "../../../../../Services/contact/contact.service";

// import { Carousel } from 'react-responsive-carousel';

interface StandPageProps {
    match: any;
    history: any;
    auth: any;
    appText: any;
    language: any;
    onShowToast: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface StandPageState {
    data: any;
    products: any[];
    mediasImage: any[];
    mediasVideo: any[];
    mediasDocuments: any[];
    media: any;
    product: any;
    openStandModal: boolean;
    openImageModal: boolean;
    showChatWidget: boolean;
    menuOpened: boolean;
    orientationAngle: 'portrait' | 'landscape';
}

class StandPage extends React.Component<StandPageProps, StandPageState> {
    eventsService: EventsService;
    standService: StandService;
    productService: ProductService;
    mediaService: MediaService;
    contactService: ContactService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.eventsService = new EventsService();
        this.standService = new StandService();
        this.productService = new ProductService();
        this.mediaService = new MediaService();
        this.contactService = new ContactService();
        this.state = {
            data: {},
            menuOpened: false,
            products: [],
            mediasImage: [],
            mediasVideo: [],
            mediasDocuments: [],
            media: undefined,
            product: undefined,
            openStandModal: false,
            openImageModal: false,
            showChatWidget: false,
            orientationAngle: window.innerHeight/window.innerWidth > 1 ? 'portrait' : 'landscape',
        }
        this.openImage = this.openImage.bind(this);
        this.openProduct = this.openProduct.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        const standId = this.props.match.params.standId.split('-')[0];
        this.standService.getStand(standId, 'SITE').then((responseData) => {
            this.setState({
                data: responseData,
                showChatWidget: true
            })
        })
        this.productService.getProducts({ standId: standId }, 'SITE').then((responseData) => {
            this.setState({
                products: responseData.rows
            })
        })

        this.orientationChangeListener = window.addEventListener("resize", (event: any) => {
            if(event !== null) {
                this.setState({
                    orientationAngle: event.target.innerHeight/event.target.innerWidth > 1 ? 'portrait' : 'landscape'
                })
            }
        });
        
        this.mediaService.getMedias({ standId: standId }, 'SITE').then((responseData) => {
            let mediasImage: any[] = [];
            let mediasVideo: any[] = [];
            let mediasDocuments: any[] = [];
            if (responseData.rows) {
                for (var index = 0; index < responseData.rows.length; index++) {
                    var element = responseData.rows[index];
                    if (element.type === undefined || element.type == 'IMAGE') {
                        mediasImage.push(element);
                    } else if (element.type === undefined || element.type == 'VIDEO'){
                        mediasVideo.push(element);
                    } else {
                        mediasDocuments.push(element);

                    }
                }
            }
            this.setState({
                mediasImage,
                mediasVideo,
                mediasDocuments
            })
        })
    }

    goBack() {
        this.props.history.goBack();
    }

    goTo(url) {
        if(url) {
            window.open(url);
        }
    }

    openImage(media) {
        this.setState({
            media: media
        })
    }
    openProduct(product) {
        this.setState({
            product: product
        })
    }

    toggleMenu() {
        this.setState((state) => ({
            menuOpened: !state.menuOpened
        }))
    }

    sendForm(){
        const standId = this.props.match.params.standId.split('-')[0];
        this.props.onShowLoader();
        this.contactService.sendStandContact({standId}).then(() => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: 'Seu contato foi enviado com sucesso!'
            });
        }).catch((error) => {
            this.props.onShowToast({
                show: true,
                type: 'error',
                message: 'Ocorreu um erro. Tente novamente mais tarde.'
            });
        })
    }
    render() {
        return (
            <div id="stand-evento" >
                <section 
                    className="container-stand" 
                    style={{ backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/backgrounds/${this.props.match.params.standId}.jpg)` }}
                >
                    <section className={'menu-lateral'}>
                        <ul>
                            <li className={'back-button'} onClick={() => this.goBack()}>
                                <FontAwesomeIcon icon={faChevronLeft} className={'fa'} /> {this.props.appText.LABEL_BACK}
                            </li>
                        </ul>
                    </section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div onClick={() => this.goTo(this.state.data.website)} className="website" style={{ cursor: this.state.data.website ? 'pointer' : 'default' , backgroundImage: `url(${this.state.data.image})` }}></div>
                            {this.state.data.type == 'BRONZE' ? null :<div onClick={() => this.goTo(this.state.data.negotiation)} className={`negotiation ${this.state.data.type == 'MASTER' ? '-master' : ''}`} style={{ cursor: this.state.data.negotiation ? 'pointer' : 'default' }}></div>}
                            {this.state.data.type == 'MASTER' ? <div onClick={() => this.goTo(this.state.data.symposium)} className="symposium" style={{ cursor:this.state.data.symposium ? 'pointer' : 'default' }}></div> : null}
                            <div onClick={() => this.sendForm()} className={`form ${this.state.data.type == 'MASTER' ? '-master' : ''}`} style={{ cursor:'pointer' }}></div>
                            <div onClick={() => toggleWidget()} className="chat" style={{ cursor:'pointer' }}></div>
                             {this.state.mediasVideo.map((media, index) => {
                                return index >= 2 && this.state.data.type !== 'MASTER' ? null : <div onClick={() => this.openImage(media)} className={`media${index} -${this.state.data.type ? this.state.data.type.toLowerCase() : ''}`} style={{ cursor: 'pointer' }}></div>;
                            })}
                             {this.state.mediasDocuments.map((media, index) => {
                                return index >= 2 && this.state.data.type !== 'MASTER' ? null : <div onClick={() => this.goTo(media.file)} className={`document${index} -${this.state.data.type ? this.state.data.type.toLowerCase() : ''}`} style={{ cursor:'pointer' }}></div>;
                            })}
                            <div className={`social instagram -${this.state.data.type ? this.state.data.type.toLowerCase() : ''}`}>
                                {this.state.data.instagram ? <a href={this.state.data.instagram} target="_blank"></a>: null}
                            </div>
                            <div className={`social facebook -${this.state.data.type ? this.state.data.type.toLowerCase() : ''}`}>
                                {this.state.data.facebook ? <a href={this.state.data.facebook} target="_blank"></a> : null}
                            </div>
                            <div className={`social youtube -${this.state.data.type ? this.state.data.type.toLowerCase() : ''}`}>
                                {this.state.data.youtube ? <a href={this.state.data.youtube} target="_blank"></a> : null}
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    id="modal"
                    className={'modal-image'}
                    open={this.state.media !== undefined}
                    onClose={() => this.setState({ media: undefined })}
                >
                    <div className={"modal-content"}>
                        {this.state.media !== undefined ?
                                <React.Fragment>
                                    <p>{this.state.media.title}</p>
                                    {this.state.media.type == 'VIDEO' ?
                                        <video controls onContextMenu={(e)=> e.preventDefault()} controlsList="nodownload">
                                            <source src={this.state.media.file} id="video_here" />
                                            Your browser does not support HTML5 video.
                                        </video>
                                    :
                                        <img src={this.state.media.file} title={this.state.media.title} alt={this.state.media.title} />
                                    }
                                    <p className={'image-description'}>{this.state.media.description}</p>
                                </React.Fragment>
                            : null}
                    </div>
                </Modal>
                {this.props.auth.currentClient && this.state.showChatWidget ?
                    <ChatWidget clientId={this.props.auth.currentClient.id} standId={this.props.match.params.standId.split('-')[0]} />
                : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state: AppStore) => {
    const { auth, root } = state;
    const appText = root.appText;
    return { auth, appText, language: root.language };
};

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: (data) => dispatch(showLoader()),
        onHideLoader: (data) => dispatch(hideLoader()),
        onShowToast: (data) => dispatch(showToast(data))
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(StandPage));