import React, { Component } from 'react'
import { connect } from "react-redux";

import './global-toast.component.scss';

import { AppStore } from "../../Interface/app-store.interface";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ToastInterface } from "../../Redux/reducers/layout.reducer";
import { hideToast } from "../../Redux/actions/layout.actions";
import { store } from "../../Redux/stores/index";

interface GlobalToastProps {
    toast: ToastInterface;
    onHideToast: (key?: any) => any;
}
interface GlobalToastState {
    toast: ToastInterface;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class GlobalToast extends Component<GlobalToastProps, GlobalToastState> {
    _toastUnsubscribe: any;
    constructor(props) {
        super(props);
        this.state = {
            toast: props.toast
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this._toastUnsubscribe = store.subscribe(() => {
            const { layout } = store.getState();
            if (layout.toast !== this.state.toast) {
                this.setState((state) => {
                    return {
                        toast: {
                            ...state.toast,
                            show: false
                        }
                    }
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            toast: layout.toast
                        })
                    }, 1000)
                })
            }
        });
    }

    componentWillUnmount() {
        if(this._toastUnsubscribe){
            this._toastUnsubscribe()
        }
    }
    
    
    handleClose() {
        if(this.props.toast.handleClose) {
            this.props.toast.handleClose();
        }
        this.props.onHideToast();
    }

    render() {
        const {toast} = this.state;
        return <Snackbar open={toast.show} autoHideDuration={toast.timeout} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={toast.type}>
                    {toast.message}
                </Alert>
            </Snackbar>
    }
}

const mapStateToProps = (store: AppStore) => {
    const { layout } = store;
    return { toast: layout.toast }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onHideToast: () => dispatch(hideToast())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalToast);