import React, { Component } from 'react'
import { connect } from "react-redux";

import './global-dialog.component.scss';

import { AppStore } from "../../Interface/app-store.interface";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { DialogInterface } from "../../Redux/reducers/layout.reducer";
import { hideDialog } from "../../Redux/actions/layout.actions";

interface GlobalDialogProps {
    dialog: DialogInterface;
    onHideDialog: (key: any) => any;
}

class GlobalDialog extends Component<any, GlobalDialogProps> {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleNegative = this.handleNegative.bind(this);
    }

    handleClose() {
        if(this.props.dialog.handleClose) {
            this.props.dialog.handleClose();
        }
        this.props.onHideDialog();
    }

    handleNegative() {
        if(this.props.dialog.handleNegative) {
            this.props.dialog.handleNegative();
        }
        this.props.onHideDialog();
    }

    render() {
        const {dialog} = this.props;
        return <Dialog
                    open={dialog.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{dialog.textHeader}</DialogTitle>
                    {dialog.textBody ?<DialogContent>
                        <DialogContentText>
                            {dialog.textBody}
                        </DialogContentText>
                    </DialogContent>: null}
                    <DialogActions>
                        <Button autoFocus onClick={this.handleNegative} color="primary">
                            {dialog.negativeButton}
                        </Button>
                        <Button onClick={dialog.handlePositive} color="primary" variant="contained" autoFocus>
                            {dialog.positiveButton}
                        </Button>
                    </DialogActions>
                </Dialog>
    }
}

const mapStateToProps = (store: AppStore) => {
    const { layout } = store;
    return { dialog: layout.dialog }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onHideDialog: () => dispatch(hideDialog())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);