import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import root from "../reducers/root.reducer";
import auth from "../reducers/auth.reducer";
import layout from "../reducers/layout.reducer";
import checkout from "../reducers/checkout.reducer";

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
  
const persistConfig = {
  key: 'root',
  blacklist: ['layout'],
  storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({ root, auth, layout, checkout }))

export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)