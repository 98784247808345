
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";


export default class CategoryService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getCategories(data?: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].CATEGORIES, data).then((responseData) => {
            return responseData.data;
        })
    }
    getCategory(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.CATEGORIES+'/'+id).then((responseData) => {
            return responseData.data;
        })
    }
    setCategory(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';


        return this._apiService[method](Configs.ENDPOINTS.CATEGORIES + (editing ? `/${data.id}` : ''), data).then((responseData) => {
            return responseData;
        })
    }

    removeCategories(categoryIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.CATEGORIES, {ids: categoryIdArray}).then((responseData) => {
            return responseData;
        })
    }
}