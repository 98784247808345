import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, IconButton } from "@material-ui/core";

// import DeleteIcon from '@material-ui/icons/Delete';

import './../../../../assets/styles/objects/container.styles.scss'

import EditIcon from '@material-ui/icons/Edit';
import ChatIcon from '@material-ui/icons/Chat';
// import PermMediaIcon from '@material-ui/icons/PermMedia';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { useRowStyles } from "./row.styles";

export default function StandRow(
    props: { 
        row: any, 
        editStand: (row: any) => any,
        removeStand?: (row: any) => any,
        openAttendantModal?: (row: any) => any,
        openPage?: (row: any, page: string) => any,
    }) {
    const { row, 
        editStand, 
        // removeStand = () => null, 
        openPage = () => null, openAttendantModal = () => null } = props;
    const classes = useRowStyles();
    const auth = useSelector((state: any) => state.auth);
    return (
            <TableRow>
                <TableCell component="th" scope="row">{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.subtitle}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell align="center">
                     {auth.currentUser.role === 'admin' ? <IconButton
                        title={'Editar Estande'}
                        aria-label="expand row" 
                        onClick={() => editStand(row)}>
                        <EditIcon />
                    </IconButton> : null}
                    {auth.currentUser.role === 'admin' ?
                    <React.Fragment>
                            {/* <IconButton
                            title={'Remover Estande'}
                            onClick={() => removeStand(row) }
                            aria-label="expand row" 
                            className={classes.actionButton}>
                            <DeleteIcon />
                        </IconButton> */}
                        <IconButton
                            title={'Gerenciar Atendentes'}
                            onClick={() => openAttendantModal(row.id) }
                            aria-label="expand row" 
                            className={classes.actionButton}>
                            <RecordVoiceOverIcon />
                        </IconButton>
                    </React.Fragment> : 
                        auth.currentUser.role === 'attendant' ? 
                        <React.Fragment><IconButton
                            title={'Gerenciar Chats'}
                            onClick={() => openPage(row.id, 'chats') }
                            aria-label="expand row" 
                            className={classes.actionButton}>
                            <ChatIcon />
                        </IconButton>
                        {/* <IconButton
                            title={'Gerenciar Produtos/Serviços'}
                            onClick={() => openPage(row.id, 'produtos-e-servicos') }
                            aria-label="expand row" 
                            className={classes.actionButton}>
                            <AddShoppingCartIcon />
                        </IconButton>
                        <IconButton
                            title={'Gerenciar Mídias'}
                            onClick={() => openPage(row.id, 'midias') }
                            aria-label="expand row" 
                            className={classes.actionButton}>
                            <PermMediaIcon />
                        </IconButton> */}
                        </React.Fragment>
                        : null
                    }
                </TableCell>
            </TableRow>
    )

}