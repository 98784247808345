import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";

export default class ContactService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    sendContact(data?: any) {
        return this._apiService.post(Configs.ENDPOINTS.SITE.CONTACTS, data).then((response) => {
            return response.data
        })
    }
    sendStandContact(data?: any) {
        return this._apiService.post(Configs.ENDPOINTS.SITE.CONTACTS + '/stand', data).then((response) => {
            return response.data
        })
    }
}
