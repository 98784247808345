import React from 'react';
import { connect } from "react-redux";
import './navigation-controls.component.scss';

import {
  withRouter
} from "react-router-dom";

import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MapIcon from '@material-ui/icons/Map';

import HelpIcon from '@material-ui/icons/Help';
import { fetchLogout } from "../../../../../Redux/actions/auth.actions";
import { Modal } from "@material-ui/core";

interface NavigationControlsProps {
    eventId: string;
	auth: any;
    history: any;
    nextLink: any;
    mapImage: any;
	onFetchLogout: () => any;
}

class NavigationControls extends React.Component<NavigationControlsProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            openImage: false
        }
        this.logout = this.logout.bind(this);
        this.historyBack = this.historyBack.bind(this);
    }
    
    historyBack() {
        this.props.history.goBack();
    }

    logout() {
        this.props.onFetchLogout();
        this.props.history.push(`/plataforma/${this.props.eventId}/hall-entrada`);
    }
    
    render() {
        const map = this.props.mapImage;
        return (
        <header className="navigation-controls">
            <div className="icon-outer">
                <a href={`/plataforma/${this.props.eventId}/hall-entrada`}>
                    <HomeIcon fontSize={'large'} />
                </a>
            </div>
            <div className={'arrow-control'}>
                <div className="icon-outer">
                    <a href={'javascript:;'} onClick={() => this.historyBack()}>
                        <ArrowBackIcon fontSize={'large'} />
                    </a>
                </div>
                {this.props.nextLink ? <div className="icon-outer">
                    <a href={this.props.nextLink}>
                        <ArrowForwardIcon fontSize={'large'} />
                    </a>
                </div>: null}
            </div>
            {this.props.mapImage ? <div className="icon-outer">
                <a href="javascript:;" onClick={() => this.setState({openImage: true})}>
                    <MapIcon fontSize={'large'} />
                </a>
            </div> : null}
            <div className="icon-outer">
                <a href={`/plataforma/${this.props.eventId}/help-desk`}>
                    <HelpIcon fontSize={'large'} />
                </a>
            </div>
            {this.props.auth.clientAuthenticated ? <div className="icon-outer">
                <a href={'javascript:;'} onClick={() => this.logout()}>
                    <MeetingRoomIcon fontSize={'large'} />
                </a>
            </div> : null}
            <Modal
                id="modal"
                className={'modal-hall-principal'}
                open={this.state.openImage}
                onClose={() => this.setState({ openImage: false })}
            >
                <div className={"modal-content map-nav"}>
                    <React.Fragment>
                        <img width={1024} height={576} src={`https://d2t970vtndlymz.cloudfront.net/maps/${map}.jpg`} />
                    </React.Fragment>
                </div>
            </Modal>
		</header>)
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
}

const dispatchToProps = (dispatch) => {
    return {
        onFetchLogout: () => dispatch(fetchLogout('SITE'))
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(NavigationControls));