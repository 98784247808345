import React, { Component } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import ForbiddenBg from '../../assets/images/forbidden-bg.jpg';
class ForbiddenPage extends Component {
    render() {
        return (
            <div style={{
                height: '100vh', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                backgroundColor: '#000',
                backgroundImage: `url(${ForbiddenBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'auto 100%',
                backgroundRepeat: 'no-repeat'
            }}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <LockIcon fontSize={'large'} style={{fontSize: 180, marginBottom: 48, color: '#FFF'}}/>
                    <p style={{fontSize: 22, color: '#FFF', textAlign: 'center'}}>Você não está autorizado a entrar nessa página</p>
                </div>
            </div>
        )
    }
}

export default ForbiddenPage;