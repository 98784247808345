import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  CardActions,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore } from '../../../Interface/app-store.interface';
import { passwordRecoveryForm } from '../../../Utils/forms/login-form';
import { fetchPasswordRecovery } from '../../../Redux/actions/auth.actions';
import i18n from '../../../Utils/i18n';

import '../../../assets/styles/objects/inputs.styles.scss';
import '../../../assets/styles/objects/container.styles.scss';
import { Alert } from "@material-ui/lab";

interface PasswordRecoveryPageState {
  passwordRecoveryForm: {[key: string]: any};
  submitting: boolean;
}

class PasswordRecoveryPage extends Component<any, PasswordRecoveryPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      passwordRecoveryForm: { ...passwordRecoveryForm(i18n.PT) },
      submitting: false
    };
  }

  _handleChange = (element: string, value: any) => {
    this.setState((getState: PasswordRecoveryPageState) => ({
      passwordRecoveryForm: {
        ...getState.passwordRecoveryForm,
        formError: '',
        [element]: { ...getState.passwordRecoveryForm[element], value }
      }
    }));
  }

  _handleSubmit = (event: any) => {
    event.preventDefault();
    this.setState({ submitting: true });
    const data = {
      email: this.state.passwordRecoveryForm.Email.value,
      type: 'cms'
    };
    this.props.onFetchPasswordRecovery(data, 'user')
      .then(() => this.props.history.push('/cms/password-change', {
        email: this.state.passwordRecoveryForm.Email.value
      }))
      .catch((error) => {
        let msg = '';
        if (error.status >= 400) {
          msg = this.props.appText[error.msg];
        }
        this.setState((state) => ({
            passwordRecoveryForm: {
                ...state.passwordRecoveryForm,
                formError: msg,
            },
            submitting: false
        }))
      });
  }

  render() {
    const { submitting, passwordRecoveryForm } = this.state;

    return (
      <div className={'login-page'}>
      <Grid container justify={'center'} alignItems={'center'} className={'full-height'}>
        <Grid item xs={3}>
          <Card variant="outlined" className={'login-card'}>
            <CardContent>
              <Typography variant="h5" component="h2" style={{color: '#FFF'}}>
                Esqueceu sua senha?
              </Typography>
              <Typography component="p" style={{color: '#FFF', marginBottom: 16}}>
                Entre com seu email. Se ele existir, você receberá um código por SMS para usar na próxima etapa.
              </Typography>
              {passwordRecoveryForm.formError !== '' ? <Alert severity="error">{passwordRecoveryForm.formError}</Alert> : null}
              <form noValidate autoComplete="off">
                <TextField
                  id={passwordRecoveryForm.Email.name}
                  label={passwordRecoveryForm.Email.label}
                  required={passwordRecoveryForm.Email.required}
                  type={passwordRecoveryForm.Email.type}
                  placeholder={passwordRecoveryForm.Email.placeholder}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={'hfm-input'}
                  onChange={event => this._handleChange('Email', event.target.value)}
                />
              </form>
            </CardContent>
            <CardActions>
              <Grid container justify={'center'} alignItems={'center'}>
                <Grid item>
                  <Button disabled={submitting} size="large" onClick={this._handleSubmit} variant="contained" color="primary">
                    { i18n.PT.LABEL_SEND }
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppStore) => {
  const { auth, root } = state;
    const appText = root.appText;
  return { auth, appText };
};

const mapDispatchToProps = dispatch => ({
  onFetchPasswordRecovery: data => dispatch(fetchPasswordRecovery(data, 'user')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordRecoveryPage));
