import React from 'react';

import {
  Route,
  Switch,
  useRouteMatch,
  withRouter,
  useParams,
  useLocation
} from "react-router-dom";

import EntrancePage from './pages/entrance/entrance.component';
import HallStandsGoldPage from './pages/hall-stands-gold/hall-stands-gold.component';
import HallStandsBronze1Page from './pages/hall-stands-bronze/hall-stands-bronze.component';
import HallStandsBronze2Page from './pages/hall-stands-bronze-2/hall-stands-bronze.component';
import HallStandsSilverPage from './pages/hall-stands-prata/hall-stands-prata.component';
import CbmiPage from './pages/cbmi-2021/cbmi-2021.component';
import HelpDeskPage from './pages/help-desk/help-desk.component';
import PalestrantePage from './pages/palestrantes/palestrantes.component';
import AmibEmCenaPage from './pages/amib-em-cena/amib-em-cena.component';
import CongressoPage from './pages/congresso-1/congresso.component';
import Congresso2Page from './pages/congresso-2/congresso.component';
import LecturePage from './pages/lecture/lecture.component';
import StandPage from './pages/stand/stand.component';
import HallRoomsPage from './pages/hall-rooms/hall-rooms.component';
import NetworkPage from './pages/network/network.component';
import RoomsPerDatePage from './pages/rooms-per-date/rooms-per-date.component';
import EventProtectedRoute from './../../../Components/ControlAccess/event-protected.component';

import NavigationControls from './components/navigation-controls/navigation-controls.component';

const InsideRoutes = (props: any) => {
    let { path } = useRouteMatch();
    let { pathname } = useLocation();
    let { slug } = useParams();
    let eventId: string = slug;
    const paths = {
        [`/plataforma/${slug}/hall-estandes-gold`]: `/plataforma/${slug}/hall-estandes-prata`,
        [`/plataforma/${slug}/hall-estandes-prata`]: `/plataforma/${slug}/hall-estandes-bronze/1`,
        [`/plataforma/${slug}/hall-estandes-bronze/1`]: `/plataforma/${slug}/hall-estandes-bronze/2`,
        [`/plataforma/${slug}/congresso/1`]: `/plataforma/${slug}/congresso/2`,
    }
    const mapPaths = {
        [`/plataforma/${slug}/hall-entrada`]: `hall-entrada`,
        [`/plataforma/${slug}/hall-estandes-gold`]: `hall-estandes-gold`,
        [`/plataforma/${slug}/hall-estandes-prata`]: `hall-estandes-prata`,
        [`/plataforma/${slug}/hall-estandes-bronze/1`]: `hall-estandes-bronze-1`,
        [`/plataforma/${slug}/hall-estandes-bronze/2`]: `hall-estandes-bronze-2`
    }
    const navProps = {
        eventId: eventId,
        nextLink: paths[pathname],
        mapImage: mapPaths[pathname]
    }

    // const eventId = slug.split('-')[0];
    window.document.getElementsByClassName("App")[0].classList.add("black-bg");
    return (
        <React.Fragment>
            <Switch>
                <Route path={`${path}/salas/:roomId`} component={LecturePage}/>
                <Route path={`${path}/hall-entrada`} component={EntrancePage}/>
                 <EventProtectedRoute eventId={eventId}>
                    <Route path={`${path}/hall-estandes-bronze/1`} component={HallStandsBronze1Page}/>
                    <Route path={`${path}/hall-estandes-bronze/2`} component={HallStandsBronze2Page}/>
                    <Route path={`${path}/hall-estandes-prata`} component={HallStandsSilverPage}/>
                    <Route path={`${path}/hall-estandes-gold`} component={HallStandsGoldPage}/>
                    <Route path={`${path}/amib-em-cena`} component={AmibEmCenaPage}/>
                    <Route path={`${path}/cbmi-2021`} component={CbmiPage}/>
                    <Route path={`${path}/help-desk`} component={HelpDeskPage}/>
                    <Route path={`${path}/palestrantes`} component={PalestrantePage}/>
                    <Route path={`${path}/congresso/1`} component={CongressoPage}/>
                    <Route path={`${path}/congresso/2`} component={Congresso2Page}/>
                    <Route path={`${path}/congresso/salas/:roomId`} component={RoomsPerDatePage}/>
                    <Route path={`${path}/estande/:standId`} component={StandPage}/>
                    <Route path={`${path}/hall-salas`} component={HallRoomsPage}/>
                    <Route path={`${path}/sala-network`} component={NetworkPage}/>
                 </EventProtectedRoute>  
            </Switch>
            <NavigationControls {...navProps}/>
        </React.Fragment>
    );
};

export default withRouter(InsideRoutes);