import React from 'react';

import {
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { AppStore } from "../../Interface/app-store.interface";
import EventsService from "../../Services/events/events.service";

interface EventProtectedRouteProps {
  rest?: any;
  children: any;
  eventId: string;
  clientAuthenticated: any;
}

interface EventProtectedRouteState {
  event: any
}

class EventProtectedRoute extends React.Component<EventProtectedRouteProps, EventProtectedRouteState> {
  eventService: EventsService;
  constructor(props) {
    super(props);
    this.eventService = new EventsService();
    this.state = {
      event: null
    }
  }

  componentDidMount() {
    const eventId = this.props.eventId.split('-')[0];
    this.eventService.getEvent(eventId, 'SITE').then((responseData) => {
      this.setState({
        event: responseData
      })
    })
  }
  
  
  render(){
    return this.state.event ? <Route
        {...this.props.rest}
        render={({ location }) =>
          this.props.clientAuthenticated ? (
            this.props.children
          ) : (
            <Redirect
              to={{
                pathname: `/plataforma/${this.props.eventId}/hall-entrada`,
                state: { from: location }
              }}
            />
          )
        }
      /> : null
    ;
  }
}

const mapStateToProps = (state: AppStore) => {
    const { currentUser, clientAuthenticated } = state.auth;
    return { currentUser, clientAuthenticated };
}

export default connect(mapStateToProps)(EventProtectedRoute);