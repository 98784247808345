import React, {Component} from 'react';
import {
    withRouter
} from 'react-router-dom';

import { connect } from "react-redux";

import { AppStore } from "../../Interface/app-store.interface";
import './logged-box.component.scss';
import { fetchLogout } from "../../Redux/actions/auth.actions";
import { showDialog, hideDialog } from "../../Redux/actions/layout.actions";

interface LoggedBoxProps {
    history: any;
    onShowDialog: any;
    onLogout: any;
    onHideDialog: any;
    menu: boolean;
    auth: any;
}

class LoggedBox extends Component<LoggedBoxProps> {

    constructor(props: any) {
        super(props);
    }

    _dialogLogout() {
        this.props.onShowDialog({
            handlePositive: () => {
                this.props.onLogout();
                this.props.onHideDialog();
                this.props.history.push('/cms/login');
            },
            textHeader: 'Você deseja mesmo sair?',
            positiveButton: 'Sair',
            negativeButton: 'Voltar'
        })
    }
    render() {
        return <div className={'logged-box'} style={{marginBottom: this.props.menu ? 0 : 16}}>
            <p style={{color: this.props.menu ? '#FFFFFF' : 'inherit'}}>Olá, <span className={'username'}>{this.props.auth.currentUser.name}</span></p>
            <p 
                className={'email'} 
                style={{color: this.props.menu ? '#FFFFFF' : 'inherit'}}>
                {this.props.auth.currentUser.email}
                <a className={'logout-link'} href="#" onClick={() => this._dialogLogout()}>sair?</a>
            </p>
        </div>
    }
}

const mapStateToProps = (state: AppStore) => {
    const { auth } = state;

    return { auth };
}

const mapDispatchToProps = (dispatch) => {
  return { 
    onLogout: () => dispatch(fetchLogout('USER')),
    onShowDialog: (data) => dispatch(showDialog(data)),
    onHideDialog: () => dispatch(hideDialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoggedBox));