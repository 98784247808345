import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";
import { TimeUtils } from "../../Utils/time";


export default class EventsService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getEvents(data?: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].EVENTS, data).then((responseData) => {
            responseData.data.rows.map((data, index) => {
                responseData.data.rows[index].createdAt = TimeUtils.timestampToMomentTamplate(data.createdAt);
                if(data.discount && data.discount > 0) {
                    responseData.data.rows[index].discountValue = data.value - data.discount;
                }
            })
            return responseData.data;
        })
    }
    getMyEvents() {
        return this._apiService.get(Configs.ENDPOINTS.SITE.EVENTS + '/me').then((responseData) => {
            return responseData.data;
        })
    }

    // No-used
    getFeatured(data?: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.get(Configs.ENDPOINTS[type].EVENTS+'/featured', data).then((responseData) => {
            responseData.data.rows.map((data, index) => {
                responseData.data.rows[index].createdAt = TimeUtils.timestampToMomentTamplate(data.createdAt);
                if(data.discount && data.discount > 0) {
                    responseData.data.rows[index].discountValue = data.value - data.discount;
                }
            })
            return responseData.data;
        })
    }
    
    getPrivateEvent(id: string, type: 'SITE' | 'CMS' = 'CMS', isDolar?: boolean) {
        return this._apiService.get(Configs.ENDPOINTS[type].EVENTS+'/private/'+id).then((responseData) => {
            responseData.data.createdAt = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            responseData.data.currency = 'R$';
            if(isDolar) {
                responseData.data.currency = '$';
                responseData.data.discount = responseData.data.discount / responseData.data.exchange;
                responseData.data.value = responseData.data.value / responseData.data.exchange;
            }
            responseData.hasDiscount = false;
            if(responseData.data.discount && responseData.data.discount > 0) {
                responseData.data.hasDiscount = true;
                responseData.data.discountValue = responseData.data.value - responseData.data.discount;
            }
            return responseData.data;
        })
    }
    getEvent(id: string, type: 'SITE' | 'CMS' = 'CMS', isDolar?: boolean) {
        return this._apiService.get(Configs.ENDPOINTS[type].EVENTS+'/'+id).then((responseData) => {
            responseData.data.createdAt = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            responseData.data.currency = 'R$';
            if(isDolar) {
                responseData.data.currency = '$';
                responseData.data.discount = responseData.data.discount / responseData.data.exchange;
                responseData.data.value = responseData.data.value / responseData.data.exchange;
            }
            responseData.hasDiscount = false;
            if(responseData.data.discount && responseData.data.discount > 0) {
                responseData.data.hasDiscount = true;
                responseData.data.discountValue = responseData.data.value - responseData.data.discount;
            }
            return responseData.data;
        })
    }

    setEvent(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';
        let formData = new FormData();
        for (var key in data) {
            if(key === 'image') {
                formData.append(key, data[key]);
            } else {
                formData.set(key, data[key]);
            }
        }
        return this._apiService[method](Configs.ENDPOINTS.EVENTS + (editing ? `/${data.id}` : ''), formData, true).then((responseData) => {
            return responseData;
        })
    }
    removeEvent(eventIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.EVENTS, {ids: eventIdArray}).then((responseData) => {
            return responseData;
        })
    }
}