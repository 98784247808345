import React from 'react';

import {
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { AppStore } from "../../Interface/app-store.interface";

function ProtectedRoute({ children, auth, role, isCms, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
      !auth.userAuthenticated ?
          <Redirect
            to={{
              pathname: "/cms/login",
              state: { from: location }
            }}
          />
      :
        auth.currentUser && role.includes(auth.currentUser.role) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/" +(isCms ? 'cms/nao-autorizado' : 'nao-autorizado'),
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state: AppStore) => {
    const { auth } = state;
    return { auth };
}

export default connect(mapStateToProps)(ProtectedRoute);