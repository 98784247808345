import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

export class TimeUtils {
    static timestampToMomentTamplate(timestamp){
        return <Moment date={timestamp} format="DD-MM-YYYY HH:mm"/>
    }
    static timestampToTemplate(timestamp, template){
        return <Moment date={timestamp} format={template}/>
    }
    static timestampToMomentFormat(timestamp){
        return moment(timestamp).format('DD-MM-YYYY HH:mm');
    }
    static fromNow(timestamp: string) {
        return moment(timestamp).fromNow();
    }
}
