import ClientAuthService from "../../Services/auth/client.service";
import UserAuthService from "../../Services/auth/user.service";
import ClientsService from "../../Services/clients/clients.service";
import UserService from "../../Services/user/user.service";
import { UserInterface } from "../reducers/auth.reducer";
import { setLanguage } from "./root.actions";

export const SET_USER_STATE = "SET_USER_STATE";
export const REMOVE_USER_STATE = "REMOVE_USER_STATE";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

// import RNUxcam from 'react-native-ux-cam';

const getAuthService = agent => (
  (agent === 'user')
    ? new UserAuthService()
    : new ClientAuthService()
)

const getResourceService = agent => (
  (agent === 'user')
    ? new UserService()
    : new ClientsService()
)

const doLogin = (response: any, dispatch: any, agent: 'user' | 'client') => {
  if (response.token) {
    localStorage.setItem('JWT_TOKEN_'+agent.toUpperCase(), response.token);
    // if(agent == 'user') {
    //   localStorage.setItem('JWT_TOKEN_CLIENT', response.token);
    // }
    return getResourceService(agent)
      .me()
      .then(response => {
        // if(agent === 'user') {
        //   response.moderator = 1;
        // }
        return dispatch(setUserState(response, agent))
      });
  }
  throw 'User data not found';
}

export const fetchLogin = (data: any, agent: 'user' | 'client') => dispatch =>
  getAuthService(agent)
    .login(data)

export const fetchSoftLogin = (data: any) => dispatch =>
  new ClientAuthService()
    .softLogin(data)
    .then(response => doLogin(response, dispatch, 'client'))

export const fetchSendAuthToken = (data, agent: 'user' | 'client') => dispatch =>
  getAuthService(agent)
    .sendAuthToken(data)
    .then(response => doLogin(response, dispatch, agent))

export const fetchPasswordRecovery = (data: any, agent: 'user' | 'client') => dispatch =>
  getAuthService(agent)
    .recoverPassword(data);

export const fetchPasswordChange = (data: any, agent: 'user' | 'client') => dispatch =>
  getAuthService(agent)
    .changePassword(data)
    .then(response => doLogin(response, dispatch, agent))
    .catch((error) => {throw error});;

export const fetchRegister = (fields, type, agent) => {
  return (dispatch) => {
    return new ClientAuthService().register(fields, type)
      .then((responseData) => {
        localStorage.setItem('JWT_TOKEN_'+agent.toUpperCase(), responseData.token);
        return new ClientsService().me().then((response) => {
          return dispatch(setUserState(response, agent))
        })
        
      })
      .catch((error) => {throw error});
  }
}

export const fetchEditProfile = (fields) => {
  return (dispatch) => {
    return new ClientAuthService().editProfile(fields)
      .then((responseData) => {
        return new ClientsService().me().then((response) => {
          return dispatch(setUserState(response, 'client'))
        })
      })
      .catch((error) => {throw error});
  }
}

export const fetchLogout = (agent) => dispatch => {
  localStorage.removeItem('JWT_TOKEN_USER');
  localStorage.removeItem('JWT_TOKEN_CLIENT');
  return dispatch(removeUserState(agent));
};

export const setUserState = (userState: UserInterface, agent: 'user' | 'client') => (dispatch) => {
  dispatch(setLanguage(userState.language));
  return dispatch({
    type: SET_USER_STATE,
    payload: {userState, agent}
  })
};

export const removeUserState = (agent) => ({
  type: REMOVE_USER_STATE,
  payload: agent
});

export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL
});

export const closeLoginModal = () => ({
  type: CLOSE_LOGIN_MODAL
});