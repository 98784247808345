import React from 'react'

import './loader.component.scss';

import logo from '../../../assets/images/hoffmann.png';
import logoPb from '../../../assets/images/hoffmann-pb.png';

const Loader = () => (
    <div className={'loader'}>
        <div style={{position: 'relative'}}>
            <img src={logo} className="App-logo" alt="logo" width="180" height="37"/>
            <div className={'App-logo-pb'}>
                <img src={logoPb} className="logo-pb" alt="logo" width="180" height="37"/>
            </div>
        </div>
    </div>
)

export default Loader;