import React, { Component } from 'react';
import { Provider } from 'react-redux'

import ReactGA from 'react-ga';

import { PersistGate } from 'redux-persist/lib/integration/react';

import './App.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'


import NotFoundPage from './Views/40X/404.component';
// import SiteRoutes from './Views/Site/site.component';
import CmsRoutes from './Views/Cms/cms.component';
import InsideRoutes from './Views/Site/InsideEvent/inside-event.component';

import GlobalLoader from './Components/GlobalLoader/global-loader.component';
import GlobalToast from './Components/GlobalToast/global-toast.component';
import GlobalDialog from './Components/GlobalDialog/global-dialog.component';

import { store, persistor } from './Redux/stores';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { setLanguage } from "./Redux/actions/root.actions";
import { AnyAction } from "redux";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#a34100',
      main: '#a34100',
    },
  },
});

interface AppState {
  canRender: boolean;
}

class App extends Component<any, AppState> {

  constructor(props) {
    super(props);
    this.state = {
      canRender: store.getState().root.appText !== undefined
    }
  }
  
  componentDidMount() {
    this.getLanguage();
    const trackingId = "UA-179223934-1";
    ReactGA.initialize(trackingId, { standardImplementation: true });
    if(store.getState().auth.currentClient) {
      ReactGA.set({
        userId: store.getState().auth.currentClient.id,
      })
    }
  }
  
  getLanguage() {
    if(store.getState().root.appText == undefined){
      store.dispatch(setLanguage() as unknown as AnyAction).then(() => {
        this.setState({
          canRender: true
        })
      })
    } else {
      this.setState({
          canRender: true
        })
    }
  }

  render() {
    return (
      <div className="App">
        <Provider store={store}>
            <PersistGate loading={<GlobalLoader active={true}/>} persistor={persistor}>
              {this.state.canRender ? <BrowserRouter>
                  <Switch>
                      <Route path="/cms">
                        <ThemeProvider theme={theme}>
                          <CmsRoutes />
                        </ThemeProvider>
                      </Route>
                      <Route path="/plataforma/:slug">
                        <InsideRoutes/>
                      </Route>
                      <Route path="/" exact
                        render={({ location }) =>
                          <Redirect
                            to={{
                              pathname: `/plataforma/1/hall-entrada`,
                              state: { from: location }
                            }}
                          />
                        }
                      >
                        {/* <SiteRoutes/> */}
                      </Route>
                    <Route path='*' component={NotFoundPage} />
                  </Switch>
              </ BrowserRouter> : null}
              <GlobalLoader/>
              <GlobalToast/>
              <GlobalDialog/>
            </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
