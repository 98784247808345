import React, {Component} from 'react';
import {
  withRouter
} from "react-router-dom";

import '../../../assets/styles/objects/table.styles.scss'
import './events-page.component.scss'
import MaterialTable from "material-table";
import EventsService from "../../../Services/events/events.service";
import { 
    Grid,
    Button
} from "@material-ui/core";

import EventIcon from '@material-ui/icons/Event';
import TableChartIcon from '@material-ui/icons/TableChart';
import EventCard from "../../../Components/EventCard/event-card.component";

interface EventsPageProps {
    history: any;
}

interface EventsPageState {
    rows: any[];
    limit: number;
    offset: number;
    count: number;
    query: {
        pageSize: number,
        page: number,
        search: string,
        orderBy: {
            field: string,
            orderDirection: 'asc' | 'desc'
        } | undefined
    }
    productList: any[];
}

class EventsPage extends Component<EventsPageProps, EventsPageState> {
    private _eventsService: EventsService;
    tableRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this._eventsService = new EventsService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            rows: [],
            query: {
                pageSize: 20,
                page: 0,
                search: '',
                orderBy : undefined
            },
            productList: []
        }

        this.fetchEvents = this.fetchEvents.bind(this);
        this._addEvent = this._addEvent.bind(this);
    }

    fetchEvents(query?) {
        if(query !== undefined){
            this.setState({
                query
            })
        } else {
            query = this.state.query;
        }
        const data: {limit: number, offset: number, search: string, order?: string} = {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            search: query.search
        }
        if(query.orderBy) {
            data.order = query.orderBy.field + ':' + query.orderDirection
        }
        return this._eventsService.getEvents(data, 'CMS').then((response) => {
            this.setState({rows: response.rows, count: response.count});
            return {
                data: response.rows,
                page: query.page,
                totalCount: response.count
            }
        })
    }

    _addEvent(id) {
        this.props.history.push(`${this.props.history.location.pathname}/${id}`);
    }

    render () {
        const { rows } = this.state;
        return (
            <React.Fragment>
                <Grid container className={'event-page'}>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            columns={[
                                { title: "#", field: "id", headerStyle: {maxWidth: '20px', minWidth: '20px'}, cellStyle: {width: '5%'}},
                                { title: "Categoria", field: "categoryId", headerStyle: {width: '20%'} },
                                { title: "Nome", field: "name", headerStyle: {width: '20%'} },
                                { title: "Descrição", field: "description", headerStyle: {width: '10%'} },
                                { title: "Imagem", field: "image", headerStyle: {width: '10%'} },
                                { title: "Preço", field: "value", headerStyle: {width: '20%'}},
                                { title: "Desconto", field: "discount", headerStyle: {width: '10%'} },
                                { title: "Parcelamento", field: "installments", headerStyle: {width: '10%'} }
                            ]}
                            options={{
                                sorting: true,
                                search: true,
                                searchFieldVariant: 'outlined',
                                pageSize: this.state.limit,
                                initialPage: this.state.offset,
                                paginationType: 'stepped',
                                pageSizeOptions: [10,20,50,100],
                                maxBodyHeight: 0
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: 'Nome do evento'
                                }
                            }}
                            style={{
                                boxShadow: 'none',
                                paddingTop: 8,
                            }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Adicionar Evento',
                                    isFreeAction: true,
                                    onClick: () => this._addEvent('new')
                                }
                            ]}
                            data={this.fetchEvents}
                            title={<p className={'title-table'}><EventIcon fontSize={'large'}/>Eventos</p>}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {rows.length <= 0 ? (
                            <div style={{display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <div>
                                    <TableChartIcon color={'disabled'} style={{ fontSize: 200 }}/>
                                </div>
                                <p style={{marginBottom: 16}}>Não existe nenhum evento cadastrado, clique no botão abaixo para criar o primeiro evento.</p>
                                <Button variant="contained" 
                                        color="primary"
                                        onClick={() => this._addEvent('new')} >Criar Evento</Button>
                            </div>
                        ) : (
                        <Grid container className={'cms-list-eventos'}>
                            {rows.map((item) => {
                                return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={item.slug} style={{height: 300, padding: 0}} >
                                    <EventCard item={item} onClick={(event) => this._addEvent(item.id)} />
                                </Grid>
                                )
                            })}
                        </Grid>
                        )}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
};

export default withRouter(EventsPage);