
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";
import { PhoneUtil } from "../../Utils/phone.util";

export default class ClientService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    login(data: any) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.CLIENT.LOGIN, data).then((responseData) => {
            return responseData.data;
        })
    }
    softLogin(data: any) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.CLIENT.SOFT_LOGIN, data).then((responseData) => {
            console.log(responseData.data);
            return responseData.data;
        })
    }

    register(data: any, type: 'SITE' | 'CMS' = 'CMS') {
        return this._apiService.post(Configs.ENDPOINTS[type].CLIENTS, data).then((responseData) => {
            if(responseData.data.rows){
                responseData.data.rows.map((item) => {
                    item['dddFullPhone'] = PhoneUtil.parseFullString(item.ddd, item.phone, item.ddi);
                    item['dddPhone'] = PhoneUtil.parseString(item.ddd, item.phone);
                })
            }
            
            return responseData.data;
        })
    }

    editProfile(data: any) {
        return this._apiService.put(Configs.ENDPOINTS.SITE.CLIENTS+ '/me', data).then((responseData) => {
            return responseData;
        })
    }

    sendAuthToken(data) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.CLIENT.SEND_AUTH_TOKEN, data).then((responseData) => {
            return responseData.data;
        })
    }

    logout() {
        return this._apiService.get(Configs.ENDPOINTS.AUTH.CLIENT.LOGOUT)
        .then((response) => {
            return response.data;
        });
    };

    recoverPassword(data: any) {
        return this._apiService
            .post(Configs.ENDPOINTS.AUTH.CLIENT.RECOVER_PASSWORD, data)
            .then(response => response.data)
    }

    changePassword(data: any) {
        return this._apiService
            .post(Configs.ENDPOINTS.AUTH.CLIENT.CHANGE_PASSWORD, data)
            .then(response => response.data)
    }

    // checkIfAuthenticated() {
    //     return this._apiService.get(Configs.ENDPOINTS.AUTH.IS_AUTHENTICATED)
    //         .then(response => {
    //             return response.data;
    //         })
    // };
}
