import React from 'react';
import {
    withRouter
} from "react-router-dom";

// import HallEntrada from '../../../../../assets/images/platform/hall-entrada-v2.jpeg';

import './entrance.component.scss';
import SponsorService from "../../../../../Services/sponsor/sponsor.service";
import { connect } from "react-redux";

import SoftLoginModal from '../../../../../Components/SoftLoginModal/soft-login-modal.component';
import { Modal } from "@material-ui/core";


interface EntrancePageProps {
    match: any;
    history: any;
    appText: any;
    auth: any;
}
interface EntrancePageState {
    openVideo: boolean;
    sponsors: any[];
}

class EntrancePage extends React.Component<EntrancePageProps, EntrancePageState> {
    sponsorService: SponsorService;
    constructor(props) {
        super(props);
        this.sponsorService = new SponsorService();
        this.state = {
            openVideo: false,
            sponsors: [{image: ''}, {image: ''}]
        }
    }

    componentDidMount() {
        this.sponsorService.getSponsors({eventId: this.props.match.params.slug.split('-')[0]}, 'SITE').then((responseData) => {
            this.setState({
                sponsors: responseData.rows
            })
        })
    }
    
    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div id={'login-evento'}>
                <section className="container-login" style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/hall-entrada-v2.jpg)`}}>
                    <div className={'led'} onClick={() => this.setState({openVideo: true})} style={{ cursor:'pointer' }}></div>
                    
                    <div className={'sponsor1'} style={{ cursor:'pointer' }}>
                        <a href="https://www.amib.org.br" target="_blank" ></a>
                    </div>

                    <div className={'sponsor2'} style={{ cursor:'pointer' }}>
                        <a href="https://www.pfizer.com.br" target="_blank"></a>
                    </div>
                    
                    <div className={'sponsor3'} style={{ cursor:'pointer' }}>
                        <a href="https://www.msd.com.br" target="_blank"></a>
                    </div>
                    
                    <div className={'sponsor4'} style={{ cursor:'pointer' }}>
                        <a href="https://www.sanofi.com.br" target="_blank"></a>
                    </div>
                    
                    <div className={'entrance1'} style={{ cursor:'pointer' }}>
                        <a href="cbmi-2021"></a>
                    </div>
                    <div className={'entrance2'} style={{ cursor:'pointer' }}>
                        <a href="hall-estandes-gold"></a>
                    </div>
                    <div className={'entrance3'} style={{ cursor:'pointer' }}>
                        <a href="palestrantes"></a>
                    </div>
                    <div className={'entrance4'} style={{ cursor:'pointer' }}>
                        <a href="hall-estandes-gold"></a>
                    </div>
                    <div className={'helpdesk'} style={{ cursor:'pointer' }}>
                        <a href="help-desk"></a>
                    </div>
                </section>
                <SoftLoginModal open={!this.props.auth.clientAuthenticated} onClose={() => window.location.reload()}/>
                <Modal
                    id="modal"
                    className={'modal-hall-principal'}
                    open={this.state.openVideo}
                    onClose={() => this.setState({ openVideo: false })}
                >
                    <div className={"modal-content"}>
                        <React.Fragment>
                            <iframe src="https://player.vimeo.com/video/476305935" width="640" height="360"  frame-border={0} allow="autoplay; fullscreen" allow-fullscreen></iframe>
                        </React.Fragment>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root, auth } = state;
    const appText = root.appText;
    return { appText, auth };
}

export default connect(mapStateToProps)(withRouter(EntrancePage));