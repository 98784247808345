import React, { Component } from 'react';

import i18n from '../../../../Utils/i18n';

import './basic-info-page.component.scss'
import '../../../../assets/styles/objects/table.styles.scss'
import '../../../../assets/styles/objects/image-wrapper.styles.scss'
import './../events-page.component.scss'
import {
    Grid,
    Button,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Modal,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
} from "@material-ui/core";

import TableChartIcon from '@material-ui/icons/TableChart';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HelpIcon from '@material-ui/icons/Help';

import { Alert } from "@material-ui/lab";
import { standForm } from "../../../../Utils/forms/event-form";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import StandService from "../../../../Services/stand/stand.service";
import StandRow from "../../../../Components/UI/Table/Row/stand-row.component";

import Autocomplete from '@material-ui/lab/Autocomplete';
import UserService from "../../../../Services/user/user.service";
import AttendantRow from "../../../../Components/UI/Table/Row/attendant-row.component";
import MouseOverPopover from "../../../../Components/UI/Popover/popover.component";

interface UserType {
    id: number;
    name: string;
}

interface StandPageProps {
    eventId: any;
    fetchStandList: () => any;
    standList: any[];
    appText: any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface StandPageState {
    standForm: { [key: string]: any };
    submiting: boolean;
    openModal: boolean;
    openAttendantModal: boolean;
    fetchingUserList: boolean;
    selectedUser: UserType | null;
    selectedStandId: number | null;
    usersList: UserType[];
    attendantList: any[];
    removeImage: 0 | 1;
    selectedType: any;
}

class StandPage extends Component<StandPageProps, StandPageState> {
    private _standService: StandService;
    private _usersService: UserService;

    constructor(props) {
        super(props);
        this._standService = new StandService();
        this._usersService = new UserService();

        this.state = {
            standForm: { ...standForm },
            submiting: false,
            openModal: false,
            openAttendantModal: false,
            fetchingUserList: false,
            selectedUser: null,
            selectedStandId: null,
            usersList: [],
            attendantList: [],
            removeImage: 0,
            selectedType: null
        }

        this.handleStandSubmit = this.handleStandSubmit.bind(this);
        this._editStand = this._editStand.bind(this);
        this._removeStand = this._removeStand.bind(this);
        this._openAttendantModal = this._openAttendantModal.bind(this);
        this._fetchUsers = this._fetchUsers.bind(this);
        this._fetchAttendant = this._fetchAttendant.bind(this);
        this.addAttendant = this.addAttendant.bind(this);
        this._removeAttendant = this._removeAttendant.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.setRemoveImage = this.setRemoveImage.bind(this);
    }

    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                standForm: {
                    ...state.standForm,
                    formError: false,
                    [element]: {
                        ...state.standForm[element],
                        value
                    }
                }
            }
        })
    }

    handleImageChange(element: string, value: any) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            this.setState((state: any) => {
                return {
                    ...state,
                    removeImage: 0,
                    standForm: {
                        ...state.standForm,
                        formError: false,
                        [element]: {
                            ...state.standForm[element],
                            renderValue: e.target.result,
                            value
                        }
                    }
                }
            })
        }
        reader.readAsDataURL(value);
    }

    handleStandSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.standForm.id.value,
            eventId: this.props.eventId,
            name: this.state.standForm.name.value,
            subtitle: this.state.standForm.subtitle.value,
            description: this.state.standForm.description.value,
            image: this.state.standForm.image.value,
            removeImage: this.state.removeImage,
            facebook: this.state.standForm.facebook.value,
            instagram: this.state.standForm.instagram.value,
            youtube: this.state.standForm.youtube.value,
            symposium: this.state.standForm.symposium.value,
            negotiation: this.state.standForm.negotiation.value,
            website: this.state.standForm.website.value,
            type: this.state.selectedType,
        }
        
        if (!this.state.standForm.editing) {
            delete data.id;
        }
        if (this.state.standForm.image.value === undefined || (typeof this.state.standForm.image.value == 'string')) {
            delete data.image;
        }
        if(this.state.standForm.type.value == 0) {
            delete data.symposium
        }
        this.props.onShowLoader();
        this._standService.setStand(data, this.state.standForm.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.props.fetchStandList();
            this.setState({
                openModal: false
            })
        })
            .catch((error) => {
                this.props.onHideLoader();
                if (error.status >= 400) {
                    this.setState((state) => ({
                        standForm: {
                            ...state.standForm,
                            formError: this.props.appText[error.msg]
                        }
                    }))
                }
            })

    }

    setRemoveImage(){
        this.handleChange('image', undefined);
        this.setState({
            removeImage: 1
        })
    }

    _removeStand(stand: any) {
        this.props.onShowDialog({
            handlePositive: () => {
                this._standService.removeStand([stand.id]).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    this.props.fetchStandList();
                })
                .catch((error) => {
                    if (error.status >= 400) {
                        this.props.onShowToast({
                            show: true,
                            type: 'warning',
                            message: this.props.appText[error.msg]
                        });
                    }
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o Estande "${stand.name}"?`
        })

    }

    _editStand(rowData, newStand?) {
        let newForm = { ...this.state.standForm };
        let selectedType = 'master';
        if (rowData || newStand) {
            for (var key in newForm) {
                if (newForm.hasOwnProperty(key) && key !== 'formError' && key !== 'editing') {
                    newForm[key].value = newStand ? '' : rowData[key];

                }
                if(key === 'type' && !newStand) {
                    selectedType = rowData[key].toLowerCase();
                }
            }
            newForm.formError = false;
            newForm.editing = !newStand;
            this.setState({
                standForm: newForm,
                openModal: true,
                selectedType
            })
        }
    }

    _openAttendantModal(standId) {
        this.setState({
            selectedStandId: standId,
            openAttendantModal: true
        }, () => {
            this._fetchAttendant();
        })
    }

    _fetchUsers(){
        this.setState({
            fetchingUserList: true
        }, () => {
            this._usersService.getUsers().then((response) => {
                const filterArray = this.state.attendantList.map((item) => item.userId);
                const filteredArray = response.rows.filter(({id}) => !filterArray.includes(id))
                this.setState({
                    usersList: filteredArray,
                    fetchingUserList: false
                })
            })
        })
    }
    _fetchAttendant(){
        this._standService.getAttendants(this.state.selectedStandId).then((response) => {
            this.setState({
                attendantList: response.rows,
                fetchingUserList: false
            })
        })
    }

    addAttendant() {
        if(this.state.selectedUser) {
            this._standService.addAttendant(this.state.selectedStandId, this.state.selectedUser.id).then((response) => {
                this.props.onShowToast({
                    show: true,
                    type: 'success',
                    message: response.msg
                });
                this.setState({
                    selectedUser: null
                })
                this._fetchAttendant();
            })
        }
    }

    _removeAttendant(row: any) {
        this.props.onShowDialog({
            handlePositive: () => {
                this._standService.removeAttendant(this.state.selectedStandId, [row.id]).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    this._fetchAttendant();
                })
                .catch((error) => {
                    if (error.status >= 400) {
                        this.props.onShowToast({
                            show: true,
                            type: 'warning',
                            message: this.props.appText[error.msg]
                        });
                    }
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o(a) Atendente "${row.name}"?`
        })
    }

    handleCheckboxChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                standForm: {
                    ...state.standForm,
                    formError: false,
                    [element]: {
                        ...state.standForm[element],
                        checked: value == 1 ? true : false,
                        value
                    }
                }
            }
        })
    }

    selectType(type) {
        this.setState( {
            selectedType: type
        })
    }

    render() {
        const { standForm, openModal, openAttendantModal } = this.state;
        const { standList } = this.props;
        return (
            <div style={{ width: '100%' }}>
                <div style={{marginBottom: 16}}>
                <Button onClick={() => this._editStand(null, true)} variant={'contained'} color="primary">
                    {'Adicionar Estande'}
                </Button>
                </div>
                {standList.length <= 0 ? (<div style={{display: 'flex', minHeight: 300, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <div><TableChartIcon color={'disabled'} style={{ fontSize: 200 }}/></div>
                    <p>Para inserir um Estande, preencha os dados nos campos acima</p>
                </div>) : (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead className={'table-header'}>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Subtítulo</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Tipo de Estande</TableCell>
                                    <TableCell style={{width: '20%', textAlign: 'center'}}>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {standList.map((row) => {
                                return (
                                    <StandRow row={row} editStand={this._editStand} removeStand={this._removeStand} openAttendantModal={this._openAttendantModal}/>
                                );
                                }
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openModal}
                    onClose={() => this.setState({
                        openModal: false
                    })
                    }
                    aria-labelledby="adicionar Estande"
                    aria-describedby="adicionar Estande"
                >
                    <Card style={{ minWidth: '50%', maxHeight: '80%', overflowY: 'scroll' }} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={standForm.editing ? "Editando Estande" :"Adicionar Estande"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <CardContent>
                            {!!standForm.formError ? <Alert severity="error">{standForm.formError}</Alert> : null}
                            <form onSubmit={this.handleStandSubmit} noValidate>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" className={'category-field'} margin={'normal'}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px solid #c3c3c3',
                                                padding: standForm.image.value ? 0 : 24
                                            }}>
                                                {standForm.image.value ?
                                                    <div className={'image-wrapper'}>
                                                        <img className={'form-image'} src={standForm.image.renderValue ? standForm.image.renderValue : standForm.image.value} alt={standForm.name.value} />
                                                        <div className={'overlay-image'}>
                                                            <Button
                                                                className={'overlay-button'}
                                                                variant="contained"
                                                                color="primary"
                                                                component="label"
                                                                onClick={(event: any) => this.setRemoveImage()}
                                                            >
                                                                Remover imagem
                                                    </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        <div><AddAPhotoIcon color={'disabled'} style={{ fontSize: 100, height: 200, marginLeft: -20 }} /></div>
                                                        <Button
                                                            className={'overlay-button'}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                        >
                                                            Fazer upload de imagem
                                                    <input
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(event: any) => this.handleImageChange('image', event.target.files[0])}
                                                                type="file"
                                                            />
                                                        </Button>
                                                        <div className={'info-icon'}>
                                                            <MouseOverPopover
                                                                text={
                                                                    <HelpIcon fontSize="small"/>
                                                                }
                                                                content={
                                                                    <React.Fragment>
                                                                        <p>- Deve ser compostos por no minimo <strong>8 caracteres</strong></p>
                                                                        <p>- Deve ter ao menos um <strong>caractere especial</strong></p>
                                                                        <p>- Deve ser <strong>alfanumerico</strong></p>
                                                                        <p>- Deve ter ao um caracter <strong>maiusculo</strong></p>
                                                                        <p>- Deve ter ao um caracter <strong>minusculo</strong></p>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <TextField
                                            {...standForm.name}
                                            fullWidth
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('name', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">Tipo de Estande</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={this.state.selectedType || standForm.type.value}
                                            label="Tipo de estande"
                                            onChange={(event) => this.setState( {selectedType: event.target.value})}
                                            >
                                                {['master', 'silver', 'bronze'].map((item) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...standForm.subtitle}
                                            fullWidth
                                            multiline
                                            rows={1}
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('subtitle', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            {...standForm.description}
                                            fullWidth
                                            multiline
                                            rows={5}
                                            error={!!standForm.formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('description', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignContent="center" alignItems="center">
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.facebook}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('facebook', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.instagram}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('instagram', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.youtube}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('youtube', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.symposium}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('symposium', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.negotiation}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('negotiation', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4} >
                                        <TextField
                                            {...standForm.website}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('website', event.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justify={'flex-end'} className={'button-wrapper'}>
                                    <Grid item>
                                        <label>
                                            <Button type="submit" variant="contained" color="primary">
                                                {standForm.editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE}
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Modal>
                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openAttendantModal}
                    onClose={() => this.setState({
                        openAttendantModal: false
                    })
                    }
                    aria-labelledby="Gerenciar Atendente"
                    aria-describedby="Gerenciar Atendente"
                >
                    <Card style={{ minWidth: '50%', maxHeight: '80%', overflowY: 'scroll' }} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton
                                onClick={() => this.setState({
                                        openAttendantModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={"Gerenciar Atendente"}
                        />
                        <CardContent>
                            <Grid container spacing={2} style={{marginBottom: 16}}>
                                <Grid item xs={12} sm={5}>
                                    <Autocomplete
                                        options={this.state.usersList}
                                        openOnFocus
                                        onOpen={() => {
                                            this._fetchUsers();
                                        }}
                                        fullWidth
                                        onClose={() => {
                                            this.setState({
                                                usersList: []
                                            })
                                        }}
                                        onChange={(event: any, newValue: any | null) => {
                                            this.setState({selectedUser: newValue});
                                        }}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        getOptionLabel={(option) => option.name}
                                        loading={this.state.fetchingUserList}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Usuários"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                    <React.Fragment>
                                                        {this.state.fetchingUserList ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                    ),
                                                }}
                                        />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button style={{padding: 15.5}} disabled={this.state.selectedUser === null} onClick={this.addAttendant} variant="contained" color="primary">
                                        {i18n.PT.LABEL_ADD_USER}
                                    </Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="attendant-table">
                                    <TableHead className={'table-header'}>
                                        <TableRow>
                                            <TableCell style={{ width: '25%' }}>Nome</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell style={{ width: '10%' }}>Telefone</TableCell>
                                            <TableCell style={{ width: '10%', textAlign: 'center' }}>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.attendantList.map((row) => {
                                            return (
                                                <AttendantRow row={row} removeAttendant={this._removeAttendant}/>
                                            );
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowDialog: (data) => dispatch(showDialog(data)),
        onHideDialog: () => dispatch(hideDialog()),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandPage);
