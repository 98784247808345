import React from 'react';
import {
    withRouter
} from "react-router-dom";

import RoomService from "../../../../../Services/room/room.service";

// import Congresso from '../../../../../assets/images/platform/congresso.jpg';
import './rooms-per-date.component.scss';
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

interface RoomsPerDatePageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface RoomsPerDatePageState {
    animating: boolean;
    openVideo: boolean;
    openList: boolean;
    dateList: any[];
    dateTitle: string;
    lectureItem: any;
    choosenDateList: any[];
    listID: number | null;
}

class RoomsPerDatePage extends React.Component<RoomsPerDatePageProps, RoomsPerDatePageState> {
    roomService: RoomService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.roomService = new RoomService();
        this.state = {
            animating: false,
            dateList: [],
            choosenDateList: [],
            openVideo: false,
            dateTitle: '',
            openList: false,
            listID: null,
            lectureItem: null
        }
        this.chooseDate = this.chooseDate.bind(this);
    }

    componentDidMount() {
        this.fetchDateList();
    }

    fetchDateList() {
        this.roomService.getDateList(this.props.match.params.roomId, 'SITE').then((responseData) => {
            this.setState({
                dateList: responseData.lectures
            })
        });
    }

    goToStand(standId) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/estande/${standId}`);
    }

    componentWillUnmount() {
        this.setState((state) => ({
            animating: true
        }));
    }
    
    chooseDate(date) {
        let filtered: any[] = [];
        if(this.state.dateList !== undefined) {
            filtered = this.state.dateList.filter((item) => {
                return item.date == date;
            })
        }
        this.setState({openList: true, choosenDateList: filtered, dateTitle: date})
    }
    render() {
        return (
            <div id="hall-room-per-date">
                <section className={`container-room-per-date ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/totens.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center" >
                            {['12/11/2020', '13/11/2020', '14/11/2020'].map((date, index) => {
                                return <div className={`date date-${index}`}>
                                    <a 
                                        href="javascript:;" 
                                        onClick={() => this.chooseDate(date)} 
                                        title={date}>
                                    </a>
                                </div>
                            })}
                        </div>
                    </div>
		        </section>
                <Modal
                    id="modal"
                    className={'modal-hall-principal'}
                    open={this.state.openVideo}
                    onClose={() => this.setState({ openVideo: false })}
                >
                    <div className={"modal-content"}>
                        {this.state.lectureItem !== null ? 
                        <React.Fragment>
                            <iframe src={`https://player.vimeo.com/video/${this.state.lectureItem.link.split('/').pop()}`} width="600" height="338"  frame-border={0} allow="autoplay; fullscreen" allow-fullscreen></iframe>
                        </React.Fragment> : null}
                    </div>
                </Modal>
                <Modal
                    id="modal"
                    className={'modal-room-per-date'}
                    open={this.state.openList}
                    onClose={() => this.setState({ openList: false })}
                >
                    <div className={"modal-content"}>
                        <p className={'modal-title'}>Palestras do dia {this.state.dateTitle}</p>
                        {this.state.choosenDateList.length > 0 ? 
                            <React.Fragment>
                                <div className={'list-item header'}>
                                    <p className={'item-theme'}>Tema da palestra</p>
                                    <p className={'item-hour'}>Horário início/término</p>
                                    <div className={'link-video'} ></div> 
                                </div>
                                {this.state.choosenDateList.map((lectureItem, index) => {
                                    return <div key={index} className={'list-item'}>
                                        <p className={'item-theme'}>{lectureItem.theme}</p>
                                        <p className={'item-hour'}>{lectureItem.hour}</p>
                                        <p className={'link-video'}>
                                            {lectureItem.link !== undefined ?
                                            <a href={'javascript:;'} onClick={() => this.setState({openVideo: true, lectureItem})} title="Assistir palestra"><PlayArrowIcon fontSize={'small'} />Assistir</a> 
                                            //<a href={lectureItem.link} target="_blank" title="Assistir palestra"><PlayArrowIcon fontSize={'small'} />Assistir</a>
                                            :
                                            <p>Em breve</p>
                                            }
                                        </p>
                                    </div>
                                })}
                            </React.Fragment> 
                        : <div className="empty-list">
                            <SentimentDissatisfiedIcon fontSize="large"/>
                            <p>As palestras desse dia ainda não estão disponíveis</p>
                        </div>}
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(RoomsPerDatePage));