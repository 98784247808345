
import ApiService from "../api/api.service";
import Configs from "../../Utils/configs.env";
import { PhoneUtil } from "../../Utils/phone.util";
import { TimeUtils } from "../../Utils/time";


export default class ClientsService {

    constructor(
        private _apiService = new ApiService()
    ) {}

    getClients(data?: any) {
        return this._apiService.get(Configs.ENDPOINTS.CLIENTS, data).then((responseData) => {
            responseData.data.rows.map((item) => {
                item['dddFullPhone'] = PhoneUtil.parseFullString(item.ddd, item.phone, item.ddi);
                item['dddPhone'] = PhoneUtil.parseString(item.ddd, item.phone);
                item['createdAt'] = TimeUtils.timestampToMomentTamplate(item.createdAt);
            })
            return responseData.data;
        })
    }
    getClient(id: number) {
        return this._apiService.get(Configs.ENDPOINTS.CLIENTS+'/'+id).then((responseData) => {
            responseData.data['dddFullPhone'] = PhoneUtil.parseFullString(responseData.data.ddd, responseData.data.phone, responseData.data.ddi);
            responseData.data['dddPhone'] = PhoneUtil.parseString(responseData.data.ddd, responseData.data.phone);
            responseData.data['createdAt'] = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            return responseData.data;
        })
    }

    me() {
        return this._apiService.get(Configs.ENDPOINTS.SITE.CLIENTS + '/me').then((responseData) => {
            responseData.data['dddFullPhone'] = PhoneUtil.parseFullString(responseData.data.ddd, responseData.data.phone, responseData.data.ddi);
            responseData.data['dddPhone'] = PhoneUtil.parseString(responseData.data.ddd, responseData.data.phone);
            responseData.data['createdAt'] = TimeUtils.timestampToMomentTamplate(responseData.data.createdAt);
            return responseData.data;
        })
    }

    setClient(data: any, editing: boolean) {
        const method = editing ? 'put' : 'post';


        return this._apiService[method](Configs.ENDPOINTS.CLIENTS + (editing ? `/${data.id}` : ''), data).then((responseData) => {
            return responseData;
        })
    }

    removeClients(clientIdArray: any) {
        return this._apiService.del(Configs.ENDPOINTS.CLIENTS, {ids: clientIdArray}).then((responseData) => {
            return responseData;
        })
    }

    sendAuthToken(data) {
        return this._apiService.post(Configs.ENDPOINTS.AUTH.CLIENT.SEND_AUTH_TOKEN, data).then((responseData) => {
            return responseData.data;
        })
    }

    logout() {
        return this._apiService.get(Configs.ENDPOINTS.AUTH.CLIENT.LOGOUT)
        .then((response) => {
            return response.data;
        });
    };

    uploadXLS(data) {
        let formData = new FormData();
        formData.append('excelFile', data.excelFile);
        return this._apiService.post(Configs.ENDPOINTS.CMS.CLIENTS + '/upload', formData, true).then((responseData) => {
            return responseData;
        })
    }
    // checkIfAuthenticated() {
    //     return this._apiService.get(Configs.ENDPOINTS.AUTH.IS_AUTHENTICATED)
    //         .then(response => {
    //             return response.data;
    //         })
    // };
}