export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";

export const showToast = (toast: any) => ({
  toast,
  type: SHOW_TOAST
});

export const hideToast = () => ({
  type: HIDE_TOAST
});

export const showLoader = () => ({
  type: SHOW_LOADER
});

export const hideLoader = () => ({
  type: HIDE_LOADER
});

export const showDialog = (dialog: any) => ({
  dialog,
  type: SHOW_DIALOG
});

export const hideDialog = () => ({
  type: HIDE_DIALOG
});
