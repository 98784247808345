import React from 'react';
import {
    withRouter
} from "react-router-dom";

import RoomService from "../../../../../Services/room/room.service";

// import Congresso from '../../../../../assets/images/platform/congresso.jpg';
import './congresso.component.scss';
import { showLoader, hideLoader } from "../../../../../Redux/actions/layout.actions";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";

interface CongressoPageProps {
    match: any;
    history: any;
    appText: any;
    onShowLoader: any;
    onHideLoader: any;
}
interface CongressoPageState {
    animating: boolean;
    openVideo: boolean;
    rooms: any[];
}

class CongressoPage extends React.Component<CongressoPageProps, CongressoPageState> {
    roomService: RoomService;
    orientationChangeListener;

    constructor(props) {
        super(props);
        this.roomService = new RoomService();
        this.state = {
            animating: false,
            rooms: [],
            openVideo: false
        }
    }

    componentDidMount() {
        this.fetchRooms();
    }

    fetchRooms() {
        this.roomService.getRooms({eventId: this.props.match.params.slug.split('-')[0], offset: 0, limit: 5}, 'SITE').then((responseData) => {
            let rooms = [...this.state.rooms];
            for (var index = 0; index < responseData.rows.length; index++) {
                rooms.push({number: index + 1, ...responseData.rows[index]});
            }
            this.setState({
                rooms
            })
            setTimeout(() => {
                this.setState({
                    animating: false
                })
            }, 1000);
        });
    }

    goToStand(standId) {
        this.props.history.push(`/plataforma/${this.props.match.params.slug}/estande/${standId}`);
    }

    componentWillUnmount() {
        this.setState((state) => ({
            animating: true
        }));
    }
    

    render() {
        return (
            <div id="hall-congresso-1">
                <section className={`container-hall-stands ${this.state.animating ? 'fade-in' : ''}`}
                    style={{backgroundImage: `url(https://d2t970vtndlymz.cloudfront.net/navigations/congresso.jpg)`}}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            {this.state.rooms.map((room) => {
                                return <div className={`door door-${room.number}`}>
                                    <a href={`salas/${room.id}`} title={room.name}></a>
                                </div>
                            })}
                            <div onClick={() => this.setState({openVideo: true})} className={`painel left-painel`}>
                            </div>
                            <div className={`painel right-painel`}>
                                <a href="2"></a>
                            </div>
                        </div>
                    </div>
		        </section>
                <Modal
                    id="modal"
                    className={'modal-hall-principal'}
                    open={this.state.openVideo}
                    onClose={() => this.setState({ openVideo: false })}
                >
                    <div className={"modal-content"}>
                        <React.Fragment>
                            <iframe src="https://player.vimeo.com/video/476305935" width="640" height="360"  frame-border={0} allow="autoplay; fullscreen" allow-fullscreen></iframe>
                        </React.Fragment>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const dispatchToProps = (dispatch) => {
    return {
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
    }
}

export default connect(mapStateToProps, dispatchToProps)(withRouter(CongressoPage));