import UserService from "../../Services/user/user.service";
import { LanguageEnum } from "../reducers/root.reducer";

import i18n from '../../Utils/i18n';
import { showLoader, hideLoader } from "./layout.actions";

export const SET_SCREEN_DIMENSIONS = "SET_SCREEN_DIMENSIONS";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const setScreenDimensions = (height: number, width: number) => ({
  height,
  type: SET_SCREEN_DIMENSIONS,
  width
});

export const setLanguage = (language?) => {
  return (dispatch, getState) => {
    if(language !== undefined) {
      dispatch(showLoader());
    }
    return new UserService().getUserLanguage(language || getState().root.language).then((response) => {
      dispatch(hideLoader());
      return dispatch({
        type: SET_LANGUAGE,
        payload: {language: language || getState().root.language, response}
        })
      })
      .catch(() => {
        dispatch(hideLoader());
        return dispatch({
          type: SET_LANGUAGE,
          payload: {language: LanguageEnum.PT, response: i18n.PT}
        })
      })
  }
}