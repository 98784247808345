import * as React from 'react'
import {
  withRouter
} from "react-router-dom";

import i18n from '../../../Utils/i18n';

import '../../../assets/styles/objects/table.styles.scss'

import MaterialTable from "material-table";
import CategoryService from "../../../Services/category/category.service";
import { Grid, IconButton, Modal, Card, CardContent, CardActions, Button, CardHeader, TextField } from "@material-ui/core";

import CategoryIcon from '@material-ui/icons/Category';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../Redux/actions/layout.actions";

// import ResultTableActions from '../../../Components/UI/TableActions/table-actions.component'

interface CategoriesPageProps {
    appText: any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface CategoriesPageState {
    rows: any[],
    openModal: boolean,
    categoryForm: {[key: string]: any};
    submiting: boolean;
    formError: string;
    editing: boolean;
    limit: number;
    offset: number;
    count: number;
    query: {
        pageSize: number,
        page: number,
        search: string,
        orderBy: {
            field: string,
            orderDirection: 'asc' | 'desc'
        } | undefined
    }
    // order: string;
}

class CategoriesPage extends React.Component<CategoriesPageProps, CategoriesPageState> {
    _categoryService: CategoryService;
    tableRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this._categoryService = new CategoryService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            rows: [],
            openModal: false,
            query: {
                pageSize: 20,
                page: 0,
                search: '',
                orderBy : undefined
            },
            categoryForm: {
                id: {
                    name: 'id',
                    type: 'hidden',
                    value: '',
                    required: true,
                    hidden: true,
                },
                name: {
                    label: i18n.PT.LABEL_CATEGORY_NAME,
                    name: 'name',
                    type: 'text',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_CATEGORY_PLACEHOLDER,
                },
                description: {
                    label: i18n.PT.LABEL_CATEGORY_DESCRIPTION,
                    name: 'description',
                    type: 'textarea',
                    value: '',
                    placeholder: i18n.PT.FORM_CATEGORY_DESCRIPTION_PLACEHOLDER,
                }
            },
            submiting: false,
            formError: '',
            editing: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
        this._removeSelected = this._removeSelected.bind(this);
    }

    fetchCategories(query?) {
        if(query !== undefined){
            this.setState({
                query
            })
        } else {
            query = this.state.query;
        }
        const data: {limit: number, offset: number, search: string, order?: string} = {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            search: query.search
        }
        if(query.orderBy) {
            data.order = query.orderBy.field + ':' + query.orderDirection
        }
        return this._categoryService.getCategories(data).then((response) => {
            this.setState({rows: response.rows, count: response.count});
            return {
                data: response.rows,
                page: query.page,
                totalCount: response.count
            }
        })
    }

    handleChange(element: string, value: any) {
        this.setState((getState: CategoriesPageState) => {
            return {
                categoryForm: {
                    ...getState.categoryForm,
                    [element]: { ...getState.categoryForm[element], value }
                }
            }
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        let data: {id?: number, name: string, description: string} = {
            name: this.state.categoryForm.name.value,
            description: this.state.categoryForm.description.value
        }
        if(this.state.editing) {
            data.id = this.state.categoryForm.id.value
        }
        this.props.onShowLoader();
        this._categoryService.setCategory(data, this.state.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.setState({
                openModal: false
            });
            if (this.tableRef.current) {
                this.tableRef.current.onQueryChange();
            }
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState({
                    formError: this.props.appText[error.msg]
                })
            }
        })

    }

    _removeSelected(categories: any[]) {
        this.props.onShowDialog({
            handlePositive: () => {
                const categoryIds = categories.map((category) => category.id)
                this._categoryService.removeCategories(categoryIds).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    if (this.tableRef.current) {
                        this.tableRef.current.onQueryChange();
                    }
                })
                .catch((error) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'warning',
                        message: this.props.appText[error.msg]
                    });
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o(s) evento(s) selecionado(s)"?`
        })
    }

    render () {
        const { openModal, categoryForm, formError, editing } = this.state;
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            columns={[
                                { title: "#", field: "id", headerStyle: {maxWidth: '20px', minWidth: '20px'}, cellStyle: {width: '5%'}},
                                { title: "Nome", field: "name", headerStyle: {width: '20%'} },
                                { title: "Descrição", field: "description", headerStyle: {width: '10%'} }
                            ]}
                            options={{
                                sorting: true,
                                search: true,
                                searchFieldVariant: 'outlined',
                                pageSize: this.state.limit,
                                initialPage: this.state.offset,
                                paginationType: 'stepped',
                                pageSizeOptions: [10,20,50,100],
                                selection: true
                            }}
                            localization={{
                                'toolbar': {
                                    nRowsSelected: '{0} categoria(s) selecionadas',
                                    searchPlaceholder: 'Nome da categoria'
                                }
                            }}
                            style={{
                                paddingTop: 8,
                            }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Adicionar Categoria',
                                    isFreeAction: true,
                                    onClick: () => {
                                        let newForm = {...this.state.categoryForm};
                                        for (var key in newForm) {
                                            if (newForm.hasOwnProperty(key)) {
                                                newForm[key].value = '';
                                                
                                            }
                                        }
                                        this.setState({
                                            categoryForm: newForm,
                                            openModal: true,
                                            editing: false,
                                            formError: ''
                                        })
                                    }
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Remover categorias selecionadas',
                                    onClick: (evt, data: any[]) => this._removeSelected(data)
                                }
                            ]}
                            onRowClick={(event, rowData) => {
                                let newForm = {...this.state.categoryForm};
                                if(rowData) {
                                    for (var key in newForm) {
                                        if (newForm.hasOwnProperty(key)) {
                                            newForm[key].value = rowData[key];
                                            
                                        }
                                    }
                                    this.setState({
                                        categoryForm: newForm,
                                        openModal: true,
                                        editing: true,
                                        formError: ''
                                    })
                                }
                            }}
                            data={this.fetchCategories}
                            title={<p className={'title-table'}><CategoryIcon fontSize={'large'}/>Categorias</p>}
                        />
                    </Grid>
                </Grid>
                <Modal
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    open={openModal}
                    onClose={() => this.setState({
                            openModal: false
                        })
                    }
                    aria-labelledby="adicionar categoria"
                    aria-describedby="adicionar categoria"
                    >
                    <Card style={{minWidth: '25%', maxHeight: '70%', overflowY: 'scroll'}} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={editing ? "Editando Categoria" :"Adicionar Categoria"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <form onSubmit={this.handleSubmit} noValidate>
                            <CardContent>
                                {formError !== '' ? <Alert severity="error">{formError}</Alert> : null}
                                <Grid container>
                                    <Grid item xs={12}>
                                            <TextField
                                                id={categoryForm.name.name}
                                                label={categoryForm.name.label}
                                                required={categoryForm.name.required}
                                                type={categoryForm.name.type}
                                                value={categoryForm.name.value}
                                                placeholder={categoryForm.name.placeholder}
                                                fullWidth
                                                error={!!formError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('name', event.target.value)}
                                                variant="outlined"
                                            />
                                            <TextField
                                                id={categoryForm.description.name}
                                                label={categoryForm.description.label}
                                                required={categoryForm.description.required}
                                                type={categoryForm.description.type}
                                                value={categoryForm.description.value}
                                                placeholder={categoryForm.description.placeholder}
                                                fullWidth
                                                error={!!formError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => this.handleChange('description', event.target.value)}
                                                variant="outlined"
                                            />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <div style={{padding: '0px 16px 16px'}}>
                                    <Button  size="large" type="submit" variant="contained" color="primary">
                                        { editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE }
                                    </Button>
                                </div>
                            </CardActions>
                        </form>
                    </Card>
                </Modal>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowToast: (data) => dispatch(showToast(data)),
    onShowDialog: (data) => dispatch(showDialog(data)),
    onHideDialog: () => dispatch(hideDialog()),
    onShowLoader: () => dispatch(showLoader()),
    onHideLoader: () => dispatch(hideLoader())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CategoriesPage));