import * as React from 'react'
import {
  withRouter
} from "react-router-dom";

import '../../../assets/styles/objects/table.styles.scss'

import MaterialTable from "material-table";
import { Grid, IconButton, Modal, Card, CardContent, CardHeader } from "@material-ui/core";

import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import LogService from "../../../Services/log/log.service";

// import ResultTableActions from '../../../Components/UI/TableActions/table-actions.component'

interface LogsPageState {
    rows: any[],
    openModal: boolean,
    limit: number;
    offset: number;
    count: number;
    logData: any;
    query: {
        pageSize: number,
        page: number,
        search: string,
        orderBy: {
            field: string,
            orderDirection: 'asc' | 'desc'
        } | undefined
    }
    // order: string;
}

class LogsPage extends React.Component<any, LogsPageState> {
    _logService: LogService;
    tableRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this._logService = new LogService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            rows: [],
            logData: undefined,
            openModal: false,
            query: {
                pageSize: 20,
                page: 0,
                search: '',
                orderBy : undefined
            }
        }

        this.fetchLogs = this.fetchLogs.bind(this);
    }

    fetchLogs(query?) {
        if(query !== undefined){
            this.setState({
                query
            })
        } else {
            query = this.state.query;
        }
        const data: {limit: number, offset: number, search: string, order?: string} = {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            search: query.search
        }
        if(query.orderBy) {
            data.order = query.orderBy.field + ':' + query.orderDirection
        }
        return this._logService.getLogs(data).then((response) => {
            this.setState({rows: response.rows, count: response.count});
            return {
                data: response.rows,
                page: query.page,
                totalCount: response.count
            }
        })
    }


    render () {
        const { openModal, logData } = this.state;
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            columns={[
                                { title: "nº protocolo", field: "id", headerStyle: {maxWidth: '20px', minWidth: '20px'}, cellStyle: {width: '5%'}},
                                { title: "Nome do Usuário", field: "username", headerStyle: {width: '20%'} },
                                { title: "email", field: "email", headerStyle: {width: '10%'} },
                                { title: "IP", field: "ip", headerStyle: {width: '10%'} },
                                { title: "descrição", field: "description", headerStyle: {width: '50%'} },
                                { title: "data do registro", field: "createdAt", headerStyle: {width: '10%'} }
                            ]}
                            options={{
                                sorting: true,
                                search: true,
                                searchFieldVariant: 'outlined',
                                pageSize: this.state.limit,
                                initialPage: this.state.offset,
                                paginationType: 'stepped',
                                pageSizeOptions: [10,20,50,100]
                            }}
                            localization={{
                                'toolbar': {
                                    nRowsSelected: '{0} log(s) selecionadas',
                                    searchPlaceholder: 'Nome do usuário'
                                }
                            }}
                            style={{
                                paddingTop: 8,
                            }}
                            onRowClick={(event, rowData) => {
                                if(rowData) {
                                    this.setState({
                                        logData: rowData,
                                        openModal: true
                                    })
                                }
                            }}
                            data={this.fetchLogs}
                            title={<p className={'title-table'}><AssignmentIcon fontSize={'large'}/>Logs</p>}
                        />
                    </Grid>
                </Grid>
                <Modal
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    open={openModal}
                    onClose={() => this.setState({
                            openModal: false
                        })
                    }
                    aria-labelledby="detalhes do log"
                    aria-describedby="detalhes do log"
                    >
                    <Card style={{minWidth: '25%', maxWidth: '50%', maxHeight: '70%', overflow: 'auto'}} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={"Detalhes do Registro"}
                        />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    {logData ? Object.keys(logData).map((key, index) => {
                                        return <div key={index} style={{marginBottom: 16}}>
                                            <p style={{fontSize: 14, fontWeight: 'bold', maxWidth: '100%', wordWrap: 'break-word'}}>{key}:</p>
                                            <p style={{border: '1px solid',backgroundColor: '#e3e3e3',  padding: 8, maxWidth: '100%', wordWrap: 'break-word'}}>{JSON.stringify(logData[key]).replace(/\\"/g, '"')}</p>
                                        </div>
                                    }) : null}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Modal>
            </React.Fragment>
        );
    }
};

export default withRouter(LogsPage);