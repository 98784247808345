import CouponService from "../../Services/coupon/coupon.service";
import CheckoutService from "../../Services/checkout/checkout.service";

export const ADD_CARD_ITEM = "ADD_CARD_ITEM";
export const REMOVE_CARD_ITEM = "REMOVE_CARD_ITEM";
export const SET_COUPON = "SET_COUPON";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const FINISH_CARD_ITEM = "FINISH_CARD_ITEM";
export const PAYPAL_CHECKOUT = "PAYPAL_CHECKOUT";
export const PAYPAL_APPROVED = "PAYPAL_APPROVED";

export const addCartItem = (eventId, addId, addType) => ({
  payload: {eventId, addId, addType},
  type: ADD_CARD_ITEM
});

export const removeCartItem = (eventId, addId, addType) => ({
  payload: {eventId, addId, addType},
  type: REMOVE_CARD_ITEM
});

export const validateCoupon = (code, eventId) => {
  return (dispatch) => {
    return new CouponService().validateCoupon({code, eventId})
      .then((responseData) => {
        dispatch({
          payload: {eventId, data: responseData.data},
          type: SET_COUPON
        })
        return responseData.data;
      })
      .catch((error) => {throw error});
  }
};

export const removeCupon = (eventId) => ({
  payload: {eventId},
  type: REMOVE_COUPON
});

export const finishCheckout = (eventId, data) => {
  return (dispatch) => {
    return new CheckoutService().finishCheckout(data)
      .then((responseData) => {
        dispatch({
          payload: eventId,
          type: FINISH_CARD_ITEM
        })
        return responseData;
      })
      .catch((error) => {throw error});
  }
};

export const finishPayPalCheckout = (eventId, data) => {
  return (dispatch) => {
    return new CheckoutService().finishCheckoutWithPayPal(data)
      .then((responseData) => {
        dispatch({
          payload: eventId,
          type: PAYPAL_CHECKOUT
        })
        return responseData;
      })
      .catch((error) => {throw error});
  }
};

export const approvePayPalCheckout = (eventId, data) => {
  return (dispatch) => {
    return new CheckoutService().approveCheckoutWithPayPal(data)
      .then((responseData) => {
        dispatch({
          payload: eventId,
          type: PAYPAL_APPROVED
        })
        return responseData;
      })
      .catch((error) => {throw error});
  }
};
