import React, {Component} from 'react';
import {
  withRouter
} from "react-router-dom";
import InputMask from "react-input-mask";

import i18n from '../../../Utils/i18n';

import '../../../assets/styles/objects/table.styles.scss'

import MaterialTable from "material-table";
import ClientsService from "../../../Services/clients/clients.service";
import { Grid, IconButton, Modal, Card, CardContent, CardActions, Button, CardHeader, FormControl, TextField } from "@material-ui/core";

import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import { fetchRegister } from "../../../Redux/actions/auth.actions";
import { STATES, CITIES } from "../../../Utils/cities-BR";
import { DDIs } from "../../../Utils/ddi";
import { PhoneUtil } from "../../../Utils/phone.util";
import { Alert, Autocomplete } from "@material-ui/lab";
import { showToast, showDialog, hideDialog, showLoader, hideLoader } from "../../../Redux/actions/layout.actions";
import './clients-page.component.scss';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

// import ResultTableActions from '../../../Components/UI/TableActions/table-actions.component'

interface ClientsPageProps {
    appText: any;
    onFetchRegister: (data: any) => any;
    onShowToast: (data: any) => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
}

interface ClientsPageState {
    rows: any[],
    openModal: boolean,
    registerForm: {[key: string]: any};
    formXLSError: string;
    submiting: boolean;
    formError: string;
    cities: string[];
    editing: boolean;
    limit: number;
    offset: number;
    count: number;
    openXLSModal: boolean;
    xlsFile: any;
    query: {
        pageSize: number,
        page: number,
        search: string,
        orderBy: {
            field: string,
            orderDirection: 'asc' | 'desc'
        } | undefined
    }
    // order: string;
}

class ClientsPage extends Component<ClientsPageProps, ClientsPageState> {
    _clientsService: ClientsService;
    tableRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this._clientsService = new ClientsService();
        this.state = {
            limit: 20,
            offset: 0,
            count: 0,
            rows: [],
            cities: [],
            openModal: false,
            openXLSModal: false,
            xlsFile: undefined,
            query: {
                pageSize: 20,
                page: 0,
                search: '',
                orderBy : undefined
            },
            registerForm: {
                id: {
                    name: 'id',
                    type: 'hidden',
                    value: '',
                    required: true,
                    hidden: true,
                },
                name: {
                    label: i18n.PT.LABEL_FULLNAME,
                    name: 'nome',
                    type: 'text',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_NAME_PLACEHOLDER,
                },
                email: {
                    label: i18n.PT.LABEL_EMAIL,
                    name: 'email',
                    type: 'text',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_EMAIL_PLACEHOLDER,
                },
                ddi: {
                    label: 'DDI',
                    name: 'ddi',
                    type: 'select',
                    value: '',
                    required: false,
                    data: DDIs,
                    placeholder: 'Selecione uma opção',
                },
                dddPhone: {
                    label: 'Telefone',
                    name: 'dddPhone',
                    type: 'text',
                    value: '',
                    mask: '(99) 9 9999-9999',
                    required: false,
                    placeholder: i18n.PT.FORM_EMAIL_PLACEHOLDER,
                },
                // cpf: {
                //     label: 'CPF',
                //     name: 'cpf',
                //     type: 'text',
                //     value: '',
                //     mask: '999.999.999-99',
                //     required: false,
                //     placeholder: i18n.PT.FORM_CPF_PLACEHOLDER,
                // },
                postalCode: {
                    label: 'CEP',
                    name: 'postalCode',
                    type: 'text',
                    value: '',
                    mask: '99999-999',
                    required: false,
                    placeholder: i18n.PT.FORM_CEP_PLACEHOLDER,
                },
                address: {
                    label: i18n.PT.LABEL_ADDRESS,
                    name: 'address',
                    type: 'text',
                    value: '',
                    required: false, 
                    placeholder: i18n.PT.FORM_ADDRESS_PLACEHOLDER,
                },
                state: {
                    label: 'Estado',
                    name: 'state',
                    type: 'select',
                    value: '',
                    required: false,
                    data: STATES,
                    placeholder: 'Selecione uma opção',
                    onChange: (stateIndex) => {
                        this.setState({
                            cities: CITIES[stateIndex]
                        })
                    }
                },
                city: {
                    label: 'Cidade',
                    name: 'city',
                    type: 'select',
                    value: '',
                    required: false,
                    data: [],
                    placeholder: 'Selecione uma opção',
                },
                password: {
                    label: 'Senha',
                    name: 'password',
                    type: 'password',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_PASSWORD_PLACEHOLDER,
                },
                passwordConfirm: {
                    label: 'Confirmar Senha',
                    name: 'passwordConfirm',
                    type: 'password',
                    value: '',
                    required: true,
                    placeholder: i18n.PT.FORM_CONFIRM_PASSWORD_PLACEHOLDER,
                }
            },
            submiting: false,
            formError: '',
            formXLSError: '',
            editing: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchClients = this.fetchClients.bind(this);
        this.uploadXLS = this.uploadXLS.bind(this);
        this.finishUpload = this.finishUpload.bind(this);
    }

    fetchClients(query?) {
        if(query !== undefined){
            this.setState({
                query
            })
        } else {
            query = this.state.query;
        }
        const data: {limit: number, offset: number, search: string, order?: string} = {
            limit: query.pageSize,
            offset: query.page * query.pageSize,
            search: query.search
        }
        if(query.orderBy) {
            data.order = query.orderBy.field + ':' + query.orderDirection
        }
        return this._clientsService.getClients(data).then((response) => {
            this.setState({rows: response.rows, count: response.count});
            return {
                data: response.rows,
                page: query.page,
                totalCount: response.count
            }
        })
    }

    handleChange(element: string, value: any) {
        this.setState((getState: ClientsPageState) => {
            return {
                registerForm: {
                    ...getState.registerForm,
                    [element]: { ...getState.registerForm[element], value }
                }
            }
        });
    }

    handleSubmit(event: any) {
        event.preventDefault();
        const data = {
            id: this.state.registerForm.id.value,
            name: this.state.registerForm.name.value,
            email: this.state.registerForm.email.value,
            // cpf: this.state.registerForm.cpf.value,
            ddi: ''+parseInt(this.state.registerForm.ddi.value.fone),
            postalCode: this.state.registerForm.postalCode.value,
            address: this.state.registerForm.address.value,
            state: this.state.registerForm.state.value ? this.state.registerForm.state.value[0] : undefined,
            city: this.state.registerForm.city.value,
            ...PhoneUtil.parsePhone(this.state.registerForm.dddPhone.value)
        }
        this.props.onShowLoader();
        this._clientsService.setClient(data, this.state.editing).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
            this.setState({
                openModal: false
            });
            if (this.tableRef.current) {
                this.tableRef.current.onQueryChange();
            }
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState({
                    formError: this.props.appText[error.msg]
                })
            }
        })

    }

    uploadXLS(event) {
        this.setState({xlsFile: event.target.files[0], formXLSError: ''});
        this.props.onShowLoader();
        this._clientsService.uploadXLS({excelFile: event.target.files[0]}).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState({
                    formXLSError: this.props.appText[error.msg]
                })
            }
        })
    }
    
    finishUpload() {
        this.setState({
            openXLSModal: false,
            formXLSError: '',
            xlsFile: undefined
        });
        if (this.tableRef.current) {
            this.tableRef.current.onQueryChange();
        }
    }

    _removeSelected(clients: any[]) {
        this.props.onShowDialog({
            handlePositive: () => {
                const clientIds = clients.map((client) => client.id)
                this._clientsService.removeClients(clientIds).then((response) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'success',
                        message: response.msg
                    });
                    this.props.onHideDialog();
                    if (this.tableRef.current) {
                        this.tableRef.current.onQueryChange();
                    }
                })
                .catch((error) => {
                    this.props.onShowToast({
                        show: true,
                        type: 'warning',
                        message: this.props.appText[error.msg]
                    });
                })
            },
            textHeader: 'Aviso',
            textBody: `Deseja realmente excluir o(s) cliente(s) selecionado(s)"?`
        })
    }

    render () {
        const { openModal, openXLSModal, registerForm, formError, formXLSError, editing } = this.state;
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            columns={[
                                { title: "#", field: "id", headerStyle: {width: '5px'}, cellStyle: {width: '5px'}},
                                { title: "Nome", field: "name", headerStyle: {width: '20%'} },
                                { title: "Email", field: "email", headerStyle: {width: '10%'} },
                                { title: "Telefone", field: "dddFullPhone", headerStyle: {width: '10%'} },
                                { title: "Telefone", field: "dddPhone", headerStyle: {width: '10%'}, hidden: true },
                                { title: "Endereço", field: "address", headerStyle: {width: '20%'}},
                                { title: "CEP", field: "postalCode", headerStyle: {width: '10%'} },
                                { title: "ddi", field: "ddi", headerStyle: {width: '10%'}, hidden: true },
                                { title: "Cidade", field: "city", headerStyle: {width: '10%'} },
                                { title: "Estado", field: "state", headerStyle: {width: '5%'} },
                                { title: "Criado em", field: "createdAt", headerStyle: {width: '5%'} }
                            ]}
                            options={{
                                sorting: true,
                                search: true,
                                searchFieldVariant: 'outlined',
                                pageSize: this.state.limit,
                                initialPage: this.state.offset,
                                paginationType: 'stepped',
                                pageSizeOptions: [10,20,50,100],
                                selection: true
                            }}
                            localization={{
                                'toolbar': {
                                    nRowsSelected: '{0} cliente(s) selecionado(s)',
                                    searchPlaceholder: 'Nome do cliente'
                                }
                            }}
                            style={{
                                paddingTop: 8,
                            }}
                            actions={[
                                {
                                    icon: 'add',
                                    tooltip: 'Adicionar Usuário',
                                    isFreeAction: true,
                                    onClick: () => {
                                        let newForm = {...this.state.registerForm};
                                        for (var key in newForm) {
                                            if (newForm.hasOwnProperty(key)) {
                                                newForm[key].value = '';
                                                
                                            }
                                        }
                                        this.setState({
                                            registerForm: newForm,
                                            openModal: true,
                                            editing: false
                                        })
                                    }
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Remover Clientes selecionadas',
                                    onClick: (evt, data: any[]) => this._removeSelected(data)
                                },
                                {
                                    icon: () => <NoteAddIcon/>,
                                    tooltip: 'Importar Planilha',
                                    isFreeAction: true,
                                    onClick: () => {
                                        this.setState({
                                            openXLSModal: true
                                        })
                                    }
                                },
                            ]}
                            onRowClick={(event, rowData) => {
                                let newForm = {...this.state.registerForm};
                                if(rowData) {
                                    for (var key in newForm) {
                                        if (newForm.hasOwnProperty(key) && key !== 'state' && key !== 'ddi') {
                                            newForm[key].value = rowData[key];
                                        }
                                        if(key == 'state') {
                                            newForm[key].value = STATES.filter((item) => item[0] == rowData[key])[0];
                                        }
                                        if(key == 'ddi') {
                                            newForm[key].value = DDIs.filter((item) => parseInt(item.fone) == rowData[key])[0];
                                        }
                                    }
                                    let cities: any[] = [];
                                    if(newForm.state.value != '') {
                                        cities = CITIES[newForm.state.data.indexOf(newForm.state.value)];
                                    }
                                    this.setState({
                                        registerForm: newForm,
                                        cities,
                                        openModal: true,
                                        editing: true,
                                        formError: ''
                                    })
                                }
                            }}
                            data={this.fetchClients}
                            title={<p className={'title-table'}><GroupIcon fontSize={'large'}/>Clientes</p>}
                        />
                    </Grid>
                </Grid>
                <Modal
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    open={openModal}
                    onClose={() => this.setState({
                            openModal: false
                        })
                    }
                    aria-labelledby="adicionar clientes"
                    aria-describedby="adicionar clientes"
                    >
                    <Card style={{minWidth: '60%', maxHeight: '70%', overflowY: 'scroll'}} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => this.setState({
                                        openModal: false
                                    })
                                }
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={editing ? "Editando Cliente" :"Adicionar Cliente"}
                            subheader="preencha os dados necessários (campos marcados com * são obrigatórios) "
                        />
                        <CardContent>
                            {formError !== '' ? <Alert severity="error">{formError}</Alert> : null}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} >
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <TextField
                                            id={registerForm.name.name}
                                            label={registerForm.name.label}
                                            required={registerForm.name.required}
                                            type={registerForm.name.type}
                                            value={registerForm.name.value}
                                            placeholder={registerForm.name.placeholder}
                                            fullWidth
                                            error={!!formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('name', event.target.value)}
                                            variant="outlined"
                                        />
                                        <TextField
                                            id={registerForm.email.name}
                                            label={registerForm.email.label}
                                            required={registerForm.email.required}
                                            type={registerForm.email.type}
                                            value={registerForm.email.value}
                                            placeholder={registerForm.email.placeholder}
                                            fullWidth
                                            error={!!formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('email', event.target.value)}
                                            variant="outlined"
                                        />
                                        {/* <InputMask
                                            mask={registerForm.cpf.mask}
                                            id={registerForm.cpf.name}
                                            label={registerForm.cpf.label}
                                            required={registerForm.cpf.required}
                                            type={registerForm.cpf.type}
                                            value={registerForm.cpf.value}
                                            fullWidth
                                            onChange={(event) => this.handleChange('cpf', event.target.value)}
                                            placeholder={registerForm.cpf.placeholder} >
                                            <TextField
                                                fullWidth
                                                error={!!formError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </InputMask> */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={5} >
                                                <FormControl variant="outlined" margin={'normal'} fullWidth>
                                                    <Autocomplete
                                                        options={registerForm.ddi.data}
                                                        value={registerForm.ddi.value}
                                                        openOnFocus
                                                        fullWidth
                                                        placeholder={'teste'}
                                                        onChange={(event: any, newValue: any | null) => {
                                                            this.handleChange('ddi', newValue);
                                                        }}
                                                        getOptionSelected={(option: any, value: any) => option.codigo === value.codigo}
                                                        getOptionLabel={(option) => option.codigo ? `(+${parseInt(option.fone)}) ${option.nome}` : ''}
                                                        renderInput={(params) => 
                                                            <TextField 
                                                                {...params}
                                                                name={registerForm.ddi.name}
                                                                id={registerForm.ddi.name}
                                                                required={registerForm.ddi.required}
                                                                fullWidth
                                                                label="ddi"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    fullWidth: true,
                                                                    autoComplete:"new-password"
                                                                }}
                                                                />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={7} >
                                                <InputMask
                                                    {...registerForm.dddPhone}
                                                    id={registerForm.dddPhone.name}
                                                    fullWidth
                                                    onChange={(event) => this.handleChange('dddPhone', event.target.value)}
                                                    placeholder={registerForm.dddPhone.placeholder} >
                                                    <TextField
                                                        error={!!formError}
                                                        margin="normal"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </InputMask>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                                {/* <Divider orientation="vertical" flexItem variant="middle"/> */}
                                <Grid item xs={12} sm={6}>
                                    <form onSubmit={this.handleSubmit} noValidate>
                                        <InputMask
                                            mask={registerForm.postalCode.mask}
                                            id={registerForm.postalCode.name}
                                            label={registerForm.postalCode.label}
                                            fullWidth
                                            required={registerForm.postalCode.required}
                                            type={registerForm.postalCode.type}
                                            value={registerForm.postalCode.value}
                                            onChange={(event) => this.handleChange('postalCode', event.target.value)}
                                            placeholder={registerForm.postalCode.placeholder} >
                                            <TextField
                                                error={!!formError}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </InputMask>
                                        <TextField
                                            id={registerForm.address.name}
                                            label={registerForm.address.label}
                                            required={registerForm.address.required}
                                            type={registerForm.address.type}
                                            value={registerForm.address.value}
                                            placeholder={registerForm.address.placeholder}
                                            fullWidth
                                            error={!!formError}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => this.handleChange('address', event.target.value)}
                                            variant="outlined"
                                        />
                                        <FormControl variant="outlined" margin={'normal'} className={'full-width'}>
                                            <Autocomplete
                                                options={registerForm.state.data}
                                                value={registerForm.state.value}
                                                openOnFocus
                                                fullWidth
                                                onChange={(event: any, newValue: any | null) => {
                                                    this.handleChange('state', newValue);
                                                    registerForm.state.onChange(registerForm.state.data.indexOf(newValue));
                                                }}
                                                getOptionSelected={(option: any, value: any) => option[0] === value[0]}
                                                getOptionLabel={(option) => option[1]}
                                                renderInput={(params) => 
                                                    <TextField 
                                                        {...params}
                                                        name={registerForm.state.name}
                                                        id={registerForm.state.name}
                                                        required={registerForm.state.required}
                                                        label="Estado" 
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:"new-password"
                                                        }}
                                                        />
                                                }
                                            />
                                        </FormControl>
                                        <FormControl variant="outlined" margin={'normal'} className={'full-width'}>
                                            <Autocomplete
                                                options={this.state.cities}
                                                value={registerForm.city.value}
                                                openOnFocus
                                                fullWidth
                                                onChange={(event: any, newValue: any | null) => {
                                                    this.handleChange('city', newValue);
                                                }}
                                                disabled={registerForm.state.value == undefined}
                                                getOptionSelected={(option: any, value: any) => option === value}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => 
                                                    <TextField 
                                                        {...params}
                                                        name={registerForm.city.name}
                                                        id={registerForm.city.name}
                                                        required={registerForm.city.required}
                                                        label="Cidade" 
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete:"new-password"
                                                        }}
                                                        />
                                                }
                                            />
                                        </FormControl>
                                    </form>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div style={{padding: '0px 16px 16px'}}>
                                <Button  size="large" onClick={this.handleSubmit} variant="contained" color="primary">
                                    { editing ? i18n.PT.LABEL_UPDATE : i18n.PT.LABEL_SAVE }
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Modal>
                <Modal
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    open={openXLSModal}
                    className={'xls-modal'}
                    onClose={() => this.setState({
                            openXLSModal: false,
                            formXLSError: '',
                            xlsFile: undefined
                        })
                    }
                    aria-labelledby="importar Planilha"
                    aria-describedby="importar Planilha"
                    >
                    <Card style={{width: '50%', maxHeight: '70%', overflowY: 'scroll'}} variant="outlined">
                        <CardHeader
                            action={
                            <IconButton 
                                onClick={() => {
                                    if (this.tableRef.current && this.state.xlsFile !== undefined) {
                                        this.tableRef.current.onQueryChange();
                                    }
                                    this.setState({
                                        openXLSModal: false,
                                        formXLSError: '',
                                        xlsFile: undefined
                                    });
                                }}
                                aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                            }
                            title={"Importar Planilha"}
                            subheader="Faça upload de uma planilha para fazer um cadastro de clientes em massa"
                        />
                        <CardContent>
                            <Grid container spacing={2} justify={'center'} alignContent={'center'}>
                                <Grid item xs={10}>
                                    <TextField
                                        id={'xls-file'}
                                        placeholder={'Faça upload do arquivo .xls/.xlsx no botão ao lado'}
                                        fullWidth
                                        value={this.state.xlsFile ? this.state.xlsFile.name : ''}
                                        error={!!formXLSError}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={2} className={'flex-center'}>
                                    {this.state.xlsFile ? <Button
                                        variant="contained"
                                        color="default"
                                        size={'large'}
                                        component="label"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.setState({xlsFile: undefined, formXLSError: ''})}}
                                    >
                                        Limpar
                                    </Button> : null}
                                    {this.state.xlsFile ? null : <Button
                                        variant="contained"
                                        color="primary"
                                        size={'large'}
                                        component="label"
                                    >
                                        Upload
                                            <input
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            style={{ display: 'none' }}
                                            onChange={this.uploadXLS}
                                            type="file"
                                        />
                                    </Button>}
                                </Grid>
                            </Grid>
                            {formXLSError !== '' ? <Alert className={'xls-error'} severity="error">{formXLSError}</Alert> : null}
                        </CardContent>
                        <CardActions>
                            <div style={{padding: '0px 16px 16px'}}>
                                <Button  size="large" onClick={this.finishUpload} variant="contained" color="primary">
                                    Concluir
                                </Button>
                            </div>
                        </CardActions>
                    </Card>
                </Modal>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    const { root } = state;
    const appText = root.appText;
    return { appText };
}

const mapDispatchToProps = (dispatch) => {
  return { 
    onFetchRegister: (data) => dispatch(fetchRegister(data, 'CMS', 'client')),
    onShowToast: (data) => dispatch(showToast(data)),
    onShowDialog: (data) => dispatch(showDialog(data)),
    onHideDialog: () => dispatch(hideDialog()),
    onShowLoader: () => dispatch(showLoader()),
    onHideLoader: () => dispatch(hideLoader())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ClientsPage));