import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";
import { Alert } from "@material-ui/lab";

import '../events/pages/basic-info-page.component.scss';
import '../events/events-page.component.scss';
import './language-page.component.scss';
import {
    Grid,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@material-ui/core";

import { showToast, showLoader, hideLoader, showDialog, hideDialog } from "../../../Redux/actions/layout.actions";

import { DialogInterface } from "../../../Redux/reducers/layout.reducer";
import UserService from "../../../Services/user/user.service";

interface LanguagePageProps {
    match: any;
    history: any;
    currentUser: any;
    appText: any;
    onShowToast: (data: any) => any;
    onShowLoader: () => any;
    onHideLoader: () => any;
    onShowDialog: (data: any) => any;
    onHideDialog: () => any;
}

interface LanguagePageState {
    submiting: boolean;
    jsonPTFile: any,
    jsonENFile: any,
    jsonALFile: any,
    formXLSError: string;
}

class LanguagePage extends Component<LanguagePageProps, LanguagePageState> {
    private _userService: UserService;

    constructor(props) {
        super(props);
        this._userService = new UserService();
        this.state = {
            submiting: false,
            jsonPTFile: undefined,
            jsonENFile: undefined,
            jsonALFile: undefined,
            formXLSError: ''
            
        }
        this.uploadJson = this.uploadJson.bind(this);
    }

    /* tslint:disable */
    handleChange(element: string, value: any) {
        this.setState((state: any) => {
            return {
                ...state,
                eventForm: {
                    ...state.eventForm,
                    formError: false,
                    [element]: {
                        ...state.eventForm[element],
                        value
                    }
                }
            }
        })
    }

    _handleFileChange(fileField: string, element: string, value: any) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            this.setState((state: any) => {
                return {
                    ...state,
                    [fileField]: 0,
                    eventForm: {
                        ...state.eventForm,
                        formError: false,
                        [element]: {
                            ...state.eventForm[element],
                            renderValue: e.target.result,
                            value: value
                        }
                    }
                }
            })
        }
        reader.readAsDataURL(value);
    }

    uploadJson(event, language) {
        this.setState({
            formXLSError: ''
        });
        this.props.onShowLoader();
        this._userService.setUserLanguage(language, event.target.files[0]).then((response) => {
            this.props.onHideLoader();
            this.props.onShowToast({
                show: true,
                type: 'success',
                message: response.msg
            });
        })
        .catch((error) => {
            this.props.onHideLoader();
            if (error.status >= 400) {
                this.setState({
                    formXLSError: this.props.appText[error.msg]
                })
            }
        })
    }

    render() {
        const { formXLSError } = this.state;
        return (
            <div style={{marginTop: 16, padding: 16}}>
                <div className={'language-page'}>
                    <div style={{marginBottom: 32}}>
                        <Accordion
                            TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                            expanded={true}>
                            <AccordionSummary
                                classes={{expanded: 'AccordionSelected'}}
                                aria-controls="basic-content"
                                id="basic-header"
                            >
                                <p>Configurar Idiomas do Site</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {formXLSError !== '' ? <Alert className={'xls-error'} severity="error">{formXLSError}</Alert> : null}
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="upload-button-wrapper">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size={'large'}
                                            component="label"
                                            className="upload-button"
                                        >
                                            Atualizar textos em Português 🇧🇷
                                                <input
                                                accept="*.json"
                                                style={{ display: 'none' }}
                                                onChange={(event) => this.uploadJson(event, 'PT')}
                                                type="file"
                                            />
                                        </Button>
                                        <a className="download-link" href="https://d2t970vtndlymz.cloudfront.net/i18n/PT.json" target="_blank">Visualizar textos atuais em português</a>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="upload-button-wrapper">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size={'large'}
                                            component="label"
                                            className="upload-button"
                                        >
                                            Atualizar textos em Inglês 🇺🇸
                                                <input
                                                accept="*.json"
                                                style={{ display: 'none' }}
                                                onChange={(event) => this.uploadJson(event, 'EN')}
                                                type="file"
                                            />
                                        </Button>
                                        <a className="download-link" href="https://d2t970vtndlymz.cloudfront.net/i18n/EN.json" target="_blank">Visualizar textos atuais em inglês</a>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="upload-button-wrapper">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size={'large'}
                                            component="label"
                                            className="upload-button"
                                        >
                                            Atualizar textos em alemão 🇩🇪
                                                <input
                                                accept="*.json"
                                                style={{ display: 'none' }}
                                                onChange={(event) => this.uploadJson(event, 'AL')}
                                                type="file"
                                            />
                                        </Button>
                                        <a className="download-link" href="https://d2t970vtndlymz.cloudfront.net/i18n/AL.json" target="_blank">Visualizar textos atuais em alemão</a>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { currentUser } = state.auth;
    const { appText } = state.root;
    return { currentUser, appText };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowToast: (data) => dispatch(showToast(data)),
        onShowLoader: () => dispatch(showLoader()),
        onHideLoader: () => dispatch(hideLoader()),
        onHideDialog: () => dispatch(hideDialog()),
        onShowDialog: (data: DialogInterface) => dispatch(showDialog(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LanguagePage));